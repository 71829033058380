import gql from 'graphql-tag';

export const pagesQuery = gql`
  query {
    pages {
      edges {
        node {
          id
          title
          slug
          content
          pk
        }
      }
    }
  }
`;

export const pageQuery = gql`
  query page($slug: String!) {
    page(slug: $slug) {
      id
      title
      slug
      content
      pk
    }
  }
`;
