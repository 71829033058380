<ng-container *ngIf="!contactFormSent">
  <div class="modalGuts">
    <h1 class="modalGuts__h1">Contact Us</h1>
    <form class="contactForm" (submit)="submitContactForm()">
      <div class="contactForm__row">
        <input
          type="text"
          class="input contactForm__input contactForm__input--half"
          name="name"
          [(ngModel)]="name"
          placeholder="Name*"
          required
        />
        <input
          type="email"
          class="input contactForm__input contactForm__input--half"
          name="email"
          [(ngModel)]="email"
          placeholder="Email*"
          required
        />
      </div>
      <div class="contactForm__row">
        <input
          type="tel"
          class="input contactForm__input contactForm__input--half"
          name="phone"
          mask="000-000-0000"
          [(ngModel)]="phone"
          placeholder="Phone*"
          required
        />
        <input
          type="text"
          class="input contactForm__input contactForm__input--half"
          name="company"
          [(ngModel)]="company"
          placeholder="Company"
        />
      </div>
      <input
        type="text"
        class="input contactForm__input"
        name="subject"
        [(ngModel)]="subject"
        placeholder="Subject*"
        required
      />
      <textarea
        class="input input--textarea contactForm__input"
        [ngClass]="{'input--error': !!error}"
        name="message"
        [(ngModel)]="message"
        placeholder="Message*"
        required
      ></textarea>
      <p class="error" *ngIf="error">{{error}}</p>
      <div class="contactForm__row">
        <label class="contactForm__check">
          <input
            type="checkbox"
            name="subscribeToNewsletter"
            [checked]="subscribeToNewsletter"
            (change)="subscribeToNewsletter = !subscribeToNewsletter"
          />
          Sign me up for the Beehive Newsletter
        </label>
        <button type="submit" class="button contactForm__button" [disabled]="submitDisabled()">Send</button>
      </div>
    </form>
  </div>
</ng-container>

<ng-container *ngIf="contactFormSent">
  <div class="modalGuts">
    <h1 class="modalGuts__h1 modalGuts__h1--thanks">Thank you for<br />reaching out!</h1>
    <p class="modalGuts__p">We'll get back to you shortly.</p>
    <button type="button" class="button contactForm__closeButton" (click)="modal.close(null)">Close</button>
  </div>
</ng-container>
