import gql from 'graphql-tag';

/**
 * Queries
 **/

export const mentorsQuery = gql`
  query mentors {
    mentors {
      id
      order
      name
      image {
        url
      }
    }
  }
`;

/**
 * Mutations
 **/

export const createAcceleratorApplicationMutation = gql`
  mutation createAcceleratorApplication($application: AcceleratorApplicationInput!) {
    createAcceleratorApplication(application: $application) {
      application {
        id
      }
    }
  }
`;
