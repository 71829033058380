import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../modules/modals';

@Component({
  selector: 'app-add-profile-help',
  templateUrl: './add-profile-help.modal.html',
  styleUrls: ['./add-profile-help.modal.scss'],
})
export class AddProfileHelpModalComponent implements OnInit, OnDestroy {
  public data = null;
  public dataDefaults = {};

  constructor(public modal: ModalService) {}

  public ngOnInit() {
    if (!this.data) {
      return;
    }
  }

  public ngOnDestroy() {}
}
