<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/deal', deal?.slug]">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal</span>
    </a>
    <h1 class="backWrapper__header">Invest in {{ deal?.name }}</h1>
  </div>
  <div class="centerPage">
    <div class="steps">
      <a class="steps__step steps__step--1 steps__step--active"></a>
      <div class="steps__line steps__line--1"></div>
      <a class="steps__step steps__step--2 steps__step--disabled"></a>
      <div class="steps__line steps__line--2"></div>
      <a class="steps__step steps__step--3 steps__step--disabled"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--4 steps__step--disabled"></a>
      <div class="steps__line steps__line--4"></div>
      <a class="steps__step steps__step--5 steps__step--disabled"></a>
    </div>
    <ng-container *ngIf="!generatingDocs">
      <label>Amount</label>
      <input
        currencyMask
        [options]="{
          align: 'left',
          allowNegative: false,
          inputMode: 'NATURAL',
          precision: 0
        }"
        class="input centerPage__input centerPage__input--wLabel"
        placeholder="{{ deal?.minimumInvestment | currency }} Min."
        [(ngModel)]="investAmount"
      />
      <p class="hint">Minimum Investment {{ deal?.minimumInvestment | currency }}</p>
    </ng-container>
    <button type="button" [disabled]="pledgeDisabled()" (click)="pledgeAmount()" class="button centerPage__button">
      <span *ngIf="!myInvestment">Pledge Amount</span>
      <span *ngIf="myInvestment">Update Amount</span>
    </button>
  </div>
</div>
