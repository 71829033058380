import { Component, ViewChild, ViewContainerRef, ComponentRef } from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal-outlet',
  templateUrl: './modal-outlet.component.html',
  styleUrls: ['./modal-outlet.component.scss'],
})
export class ModalOutletComponent {
  @ViewChild('content', { read: ViewContainerRef, static: true })
  public content;

  public active: ComponentRef<any>;

  public visible = false;
  public options: any = {};

  constructor(public modals: ModalService) {
    this.modals.setOutlet(this);
  }

  public close() {
    if (!this.options.closeDisabled) {
      this.modals.close(null);
    }
  }
}
