<div class="modalGuts">
  <h1>Investor Status</h1>
  <p class="status__instructions">To see available deals, you need to be an investor or qualified purchaser.</p>
  <form (submit)="updateAccount()">
    <div class="status__option">
      <label class="status__radio">
        <input type="radio" name="investorType" [value]="'accredited_investor'" [(ngModel)]="investorType" /> I'm an
        accredited investor.
      </label>
      <button type="button" class="iconButton" (click)="accreditedQuestionOpen = !accreditedQuestionOpen">
        <i class="fa fa-question-circle"></i>
      </button>
    </div>
    <div class="status__details" [ngClass]="{ 'status__details--open' : accreditedQuestionOpen }">
      <p class="status__p">An accredited investor, in the context of a natural person, includes anyone who:</p>
      <ul class="status__list">
        <li class="status__p">
          has earned income that exceeded $200,000 (or $300,000 together with a spouse) in each of the prior two years,
          and reasonably expects the same for the current year, OR
        </li>
        <li class="status__p">
          has a net worth over $1 million, either alone or together with a spouse (excluding the value of the person’s
          primary residence).
        </li>
      </ul>
      <p class="status__p">
        There are other categories of accredited investors, including the following, which may be relevant to you:
      </p>
      <ul class="status__list">
        <li class="status__p">
          any trust, with total assets in excess of $5 million, not formed specifically to purchase the subject
          securities, whose purchase is directed by a sophisticated person, or
        </li>
        <li class="status__p">any entity in which all of the equity owners are accredited investors.</li>
      </ul>
      <p>Source: sec.gov</p>
    </div>
    <div class="status__option">
      <label class="status__radio">
        <input type="radio" name="investorType" [value]="'qualified_purchaser'" [(ngModel)]="investorType" /> I'm a
        qualified purchaser.
      </label>
      <button type="button" class="iconButton" (click)="purchaserQuestionOpen = !purchaserQuestionOpen">
        <i class="fa fa-question-circle"></i>
      </button>
    </div>
    <div class="status__details" [ngClass]="{ 'status__details--open' : purchaserQuestionOpen }">
      <p class="status__p">
        To paraphrase the requirements under Section 2(a)(51) of the Investment Company Act, a “qualified purchaser”
        means:
      </p>
      <ul class="status__list">
        <li class="status__p">a person with not less than $5 million in investments</li>
        <li class="status__p">a company with not less than $5 million in investments owned by close family members</li>
        <li class="status__p">a trust, not formed for the investment, with not less than $5 million in investments</li>
        <li class="status__p">an investment manager with not less than $25 million under management</li>
        <li class="status__p">a company with not less than $25 million of investments</li>
        <li class="status__p">
          a company (regardless of the amount of such company’s Investments) beneficially owned exclusively by Qualified
          Purchasers
        </li>
      </ul>
      <p>Source: compliancebuilding.com</p>
    </div>
    <div class="status__option">
      <label class="status__radio">
        <input type="radio" name="investorType" [value]="null" [(ngModel)]="investorType" /> I'm none of the above.
      </label>
    </div>
    <button type="submit" class="button modalGuts__button">Save</button>
  </form>
</div>
