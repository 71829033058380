<div class="ssContainer">
  <h1>Settings</h1>

  <div class="spinnerContainer" *ngIf="loadingProfile">
    <div class="ssSpinner"></div>
  </div>
  <ng-container *ngIf="!loadingProfile">
    <h2 class="settings__h2">Profile</h2>
    <div class="settings__inputRow">
      <div class="inputContainer">
        <label class="settings__label" for="firstName">First name</label>
        <input
          class="input"
          type="text"
          name="firstName"
          [(ngModel)]="firstName"
          (ngModelChange)="accountFieldChange()"
          maxlength="20"
        />
      </div>
      <div class="inputContainer">
        <label class="settings__label" for="lastName">Last name</label>
        <input
          class="input"
          type="text"
          name="lastName"
          [(ngModel)]="lastName"
          (ngModelChange)="accountFieldChange()"
          maxlength="20"
        />
      </div>
    </div>
    <div class="settings__inputRow">
      <div class="inputContainer">
        <label class="settings__label" for="phoneNumber">Phone number</label>
        <input
          class="input"
          type="text"
          name="phoneNumber"
          mask="000-000-0000"
          [showMaskTyped]="true"
          [(ngModel)]="phoneNumber"
          (ngModelChange)="accountFieldChange()"
          maxlength="20"
        />
      </div>
    </div>
    <div class="updateAccountErrors">
      <p class="error" *ngFor="let error of accountChangeErrors">{{ error.message }}</p>
    </div>
    <!-- <h2 class="settings__h2">Investment Profiles</h2>
    <div class="profiles">
      <p *ngIf="personalProfiles.length < 1 && entityProfiles.length < 1">
        <i class="table__exclaim fa fa-exclamation-triangle"></i>
        <span class="instructions profiles__instructions"
          >You must add an investment profile before you can begin investing.</span
        >
      </p>
      <div class="profiles__list">
        <div class="profiles__item" *ngIf="personalProfiles.length < 1">
          <button type="button" class="buttonText" (click)="openPersonalProfileModal()">
            <i class="buttonText__icon fa fa-plus"></i>
            Add Individual
          </button>
        </div>
        <div class="profiles__item" *ngFor="let profile of personalProfiles">
          <p>
            {{ profile.legalName
            }}<span *ngFor="let source of profile.fundingSources"
              ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
            >
          </p>
          <button class="iconButton profiles__button" (click)="profileMenuOpen[profile.id] = true">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <app-popover
            [options]="profileMenuOptions"
            [visible]="profileMenuOpen[profile.id]"
            (visibleChange)="profileMenuOpen[profile.id] = $event"
          >
            <a (click)="profileMenuOpen[profile.id] = false; editProfile(profile)" class="tooltip__link"
              >Edit Profile</a
            >
            <a
              *ngIf="environment.dwollaEnabled"
              (click)="profileMenuOpen[profile.id] = false; addFundingSource(profile)"
              class="tooltip__link"
              >Add Funding Source</a
            >
            <a (click)="profileMenuOpen[profile.id] = false" class="tooltip__link" (click)="deactivateProfile(profile)"
              >Deactivate Profile</a
            >
          </app-popover>
        </div>
        <div class="profiles__item" *ngFor="let profile of entityProfiles">
          <p>
            {{ profile.legalName
            }}<span *ngFor="let source of profile.fundingSources"
              ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
            >
          </p>
          <button class="iconButton profiles__button" (click)="profileMenuOpen[profile.id] = true">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <app-popover
            [options]="profileMenuOptions"
            [visible]="profileMenuOpen[profile.id]"
            (visibleChange)="profileMenuOpen[profile.id] = $event"
          >
            <a (click)="profileMenuOpen[profile.id] = false; editProfile(profile)" class="tooltip__link"
              >Edit Profile</a
            >
            <a
              *ngIf="environment.dwollaEnabled"
              (click)="profileMenuOpen[profile.id] = false; addFundingSource(profile)"
              class="tooltip__link"
              >Add Funding Source</a
            >
            <a (click)="profileMenuOpen[profile.id] = false" class="tooltip__link" (click)="deactivateProfile(profile)"
              >Deactivate Profile</a
            >
          </app-popover>
        </div>
        <div class="profiles__item">
          <button type="button" class="buttonText" (click)="openEntityProfileModal()">
            <i class="buttonText__icon fa fa-plus"></i>
            Add Entity
          </button>
        </div>
      </div>
    </div>
    <div class="profiles" *ngIf="deactivatedProfiles.length > 0">
      <h5 class="profiles__header">Deactivated Profiles</h5>
      <div class="profiles__list">
        <div class="profiles__item profiles__item--deactivated" *ngFor="let profile of deactivatedProfiles">
          <p>
            {{ profile.legalName
            }}<span *ngFor="let source of profile.fundingSources"
              ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
            >
          </p>
          <button class="iconButton profiles__button" (click)="profileMenuOpen[profile.id] = true">
            <i class="fa fa-ellipsis-v"></i>
          </button>
          <app-popover
            [options]="profileMenuOptions"
            [visible]="profileMenuOpen[profile.id]"
            (visibleChange)="profileMenuOpen[profile.id] = $event"
          >
            <a (click)="profileMenuOpen[profile.id] = false; editProfile(profile)" class="tooltip__link"
              >Edit Profile</a
            >
            <a (click)="profileMenuOpen[profile.id] = false" class="tooltip__link" (click)="activateProfile(profile)"
              >Reactivate Profile</a
            >
          </app-popover>
        </div>
      </div>
    </div> -->
    <h2 class="settings__h2">Change Password</h2>
    <button type="button" class="buttonText" (click)="openChangePasswordModal()" style="margin-bottom: 64px">
      <i class="buttonText__icon fa fa-key"></i>
      Change Password
    </button>
  </ng-container>
</div>
