<div class="ssContainer">
  <h1>Investor List</h1>
  <div class="ssContainer">
    <div class="spinnerContainer" *ngIf="!userLoaded || !investorsLoaded">
      <div class="ssSpinner"></div>
    </div>
  </div>
  <div class="tableWrap" *ngIf="userLoaded && investorsLoaded">
    <div class="tableWrap__inner">
      <table class="dealList__table">
        <thead>
          <tr class="tr--noBorder">
            <th></th>
            <th class="td--borderLeft"></th>
            <th colspan="4" class="td--borderLeft td--borderRight">Syndicate</th>
            <th colspan="4" class="td--borderLeft td--borderRight">Sidecar</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <!--TODO: The table headers are sortable. CSS class th--sortAZ shows the down arrow; class th--sortZA shows the up arrow. When a user clicks a table header, remove the th--sortAZ and th--sortZA classes from all other table headers in that table, and add th--sortAZ class to the clicked table header. If a user clicks a table header with class th--sortAZ, remove the class and replace it with th--sortZA.-->
            <th class="td--left th--sortAZ">Investor</th>
            <th class="td--center td--borderLeft">Fund 1</th>
            <th class="td--center">In Process</th>
            <th class="td--center">Done</th>
            <th class="td--center">Invested</th>
            <th class="td--center td--borderRight">Avg</th>
            <th class="td--center">In Process</th>
            <th class="td--center">Done</th>
            <th class="td--center">Invested</th>
            <th class="td--center td--borderRight">Avg</th>
            <th class="td--center">Total</th>
            <th class="td--left" style="width: 80px">Last Action</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let investor of investors">
            <td class="td--left">
              <a [routerLink]="['/reports/investor', investor.pk]">{{ investor.firstName }} {{ investor.lastName }}</a>
            </td>
            <td class="td--center td--borderLeft">
              {{ investor.investorMasterData.sidecar.totalAmountInvested | formatCurrency }}
            </td>
            <td class="td--center">{{ investor.investorMasterData.sidecar.inProcessInvestments }}</td>
            <td class="td--center">
              {{ investor.investorMasterData.sidecar.completedInvestments }}/{{
                investor.investorMasterData.sidecar.inProcessInvestments +
                  investor.investorMasterData.sidecar.completedInvestments
              }}
            </td>
            <td class="td--center">{{ investor.investorMasterData.sidecar.totalAmountInvested | formatCurrency }}</td>
            <td class="td--center td--borderRight">
              {{ investor.investorMasterData.sidecar.averageInvestment | formatCurrency }}
            </td>

            <td class="td--center">{{ investor.investorMasterData.sidecar.inProcessInvestments }}</td>
            <td class="td--center">
              {{ investor.investorMasterData.sidecar.completedInvestments }}/{{
                investor.investorMasterData.sidecar.inProcessInvestments +
                  investor.investorMasterData.sidecar.completedInvestments
              }}
            </td>
            <td class="td--center">{{ investor.investorMasterData.sidecar.totalAmountInvested | formatCurrency }}</td>
            <td class="td--center td--borderRight">
              {{ investor.investorMasterData.sidecar.averageInvestment | formatCurrency }}
            </td>
            <td class="td--center">
              <a class="table__number" (click)="numberTipOpen = !numberTipOpen">{{
                investor.investorMasterData.totalInvested | formatCurrency
              }}</a>
              <div class="tooltip" [ngClass]="{ 'tooltip--open': numberTipOpen }">
                {{ investor.investorMasterData.totalInvested | currency }}
              </div>
            </td>
            <td class="td--left">
              <div *ngIf="investor.investorMasterData.latestActivity">
                <span>{{ investor.investorMasterData.latestActivity.created | toNow }}</span
                ><br /><span *ngIf="investor.investorMasterData.latestActivity.investment.deal">{{
                  investor.investorMasterData.latestActivity.investment.deal.name
                }}</span>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
</div>
