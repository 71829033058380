import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { GQLService } from '../../services';
import * as investQueries from '../../queries/invest-queries';

@Component({
  selector: 'app-funding-source',
  templateUrl: './funding-source.modal.html',
  styleUrls: ['./funding-source.modal.scss'],
})
export class FundingSourceModalComponent implements OnInit, OnDestroy {
  public data = null;
  public dataDefaults = {};

  public accounts = [];
  public accountsDropdownOpen = false;
  public accountsDropdownOptions = {
    width: '100%',
  };
  public formSubmitting = false;

  constructor(private modal: ModalService, private toast: ToastService, private gql: GQLService) {}

  public ngOnInit() {}

  public ngOnDestroy() {}

  public selectAccount(account) {
    let idx = this.accounts.indexOf(account);
    if (idx > -1) {
      this.accounts.splice(idx, 1);
    } else {
      this.accounts.push(account);
    }
  }

  public isSelected(account) {
    let idx = this.accounts.indexOf(account);
    return idx > -1;
  }

  public createAllAccounts() {
    let promises = this.accounts.map((account) => {
      return new Promise((resolve, reject) => {
        let name = `${this.data.metadata.institution.name} …${account.mask}`;
        this.gql
          .mutation(investQueries.createFundingSourceMutation, {
            accountId: account.id,
            name,
            profile: this.data.profileId,
            publicToken: this.data.public_token,
          })
          .then((mutation) => resolve(mutation))
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
            reject(errs);
          });
      });
    });
    return Promise.all(promises);
  }

  public create() {
    this.formSubmitting = true;
    this.createAllAccounts().then(() => {
      this.formSubmitting = false;
      this.modal.close(null);
    });
  }
}
