<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/deal', deal?.slug]">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal</span>
    </a>
    <h1 class="backWrapper__header">Invest in {{ deal?.name }}</h1>
  </div>
  <!--/.backWrapper-->
  <div class="centerPage">
    <div class="steps">
      <a class="steps__step steps__step--1 steps__step--done"></a>
      <div class="steps__line steps__line--1"></div>
      <a class="steps__step steps__step--2 steps__step--done"></a>
      <div class="steps__line steps__line--2"></div>
      <a class="steps__step steps__step--3 steps__step--done"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--4 steps__step--done"></a>
      <div class="steps__line steps__line--4"></div>
      <a class="steps__step steps__step--5 steps__step--active"></a>
    </div>
    <!--/.steps-->
    <ng-container *ngIf="clickedInvest">
      <div class="spinnerContainer">
        <div class="ssSpinner" style="margin-bottom: 1rem"></div>
        <p>Intitiating Transfer...</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!clickedInvest">
      <!-- <label>Payment Method</label>
      <div class="selectContainer">
        <select class="input" [(ngModel)]="paymentMethod" (ngModelChange)="setPaymentMethod()">
          <option value="" disabled hidden selected>Select method</option>
          <option [value]="'ach'" [disabled]="achDisabled()">ACH</option>
          <option [value]="'wire'">Wire</option>
        </select>
      </div> -->
      <!-- <div class="instructions__notice" *ngIf="achDisabled()">
        <i class="table__exclaim fa fa-exclamation-triangle"></i>
        <p *ngIf="myInvestment.profile.profileType === 'person'">
          For individual investments over {{ transactionLimits.personal | currency }} we use wire transfer.
        </p>
        <p *ngIf="myInvestment.profile.profileType === 'entity'">
          For entity investments over {{ transactionLimits.entity | currency }} we use wire transfer.
        </p>
      </div> -->
    </ng-container>
    <ng-container *ngIf="!clickedInvest && isACH">
      <label>Funding Source</label>
      <div class="dropdown">
        <div class="dropdown__visible" (click)="sourceDropdownOpen = !sourceDropdownOpen">
          <p>
            <span class="capitalize">{{ selectedFundingSource?.name.toLowerCase() }}</span>
          </p>
        </div>
        <ul
          class="dropdown__menu"
          [ngClass]="{ 'dropdown__menu--open': sourceDropdownOpen }"
          (click)="sourceDropdownOpen = false"
        >
          <li class="dropdown__item" *ngFor="let source of fundingSources" (click)="selectedFundingSource = source">
            <span class="capitalize">{{ source.name.toLowerCase() }}</span>
            <!-- <i class="fa fa-pencil fa-fw" (click)="$event.stopPropagation();"></i> -->
          </li>
          <li class="dropdown__item dropdown__item--button" *ngIf="fundingSources.length < 2">
            <button class="buttonText buttonText--noHover" type="button" (click)="addFundingSource()">
              <i class="buttonText__icon fa fa-plus"></i> Add Funding Source
            </button>
          </li>
        </ul>
      </div>
      <button
        type="button"
        class="button centerPage__button"
        (click)="initiateTransfer()"
        [disabled]="initiateTransferDisabled()"
      >
        Invest&ensp;<span>${{ myInvestment?.amount | number }}</span
        >&ensp;Now
      </button>
    </ng-container>
    <ng-container *ngIf="!clickedInvest && isWire">
      <p class="instructions">
        To complete your investment, please send a wire payment to the account listed below, making sure to include the
        custom reference number, which is specific to your investment.
      </p>
      <p class="instructions">
        We’ll send you a confirmation email, including copies of all documentation, once payment is received.
      </p>
      <button type="button" class="button centerPage__button" (click)="openWireInstructions()">Wire Information</button>
      <button type="button" class="button centerPage__button" [routerLink]="['/dashboard']">Done</button>
    </ng-container>
  </div>
  <!--/.centerPage-->
</div>
<!--/.ssContainer-->
