<div class="nav">
  <div class="ssContainer">
    <div class="nav__content">
      <div class="nav__left">
        <a [routerLink]="['/']"><img class="nav__logo" src="../../assets/img/logo-blue.svg" alt="Beehive VC logo" /></a>
        <a [routerLink]="['/']">
          <img class="nav__mobileLogo" src="../../assets/img/logo-mobile.svg" alt="Beehive VC logo"
        /></a>
      </div>

      <a class="nav__button" (click)="openMobileNav()">
        <i class="icon-menu"></i>
      </a>

      <div class="nav__right" [ngClass]="{ 'nav__right--mobileOpen': mobileNavOpen }">
        <div class="nav__mobileHeader">
          <a [routerLink]="['/']" (click)="closeMobileNav()"
            ><img class="nav__mobileLogo" src="../../assets/img/logo-mobile.svg" alt="Beehive VC logo"
          /></a>
          <a class="nav__button" (click)="closeMobileNav()">
            <i class="icon-close"></i>
          </a>
        </div>
        <ul class="nav__links">
          <li class="nav__linkWrap">
            <a [routerLink]="['/accelerator']" class="nav__link" (click)="closeMobileNav()">Accelerator</a>
          </li>
          <li class="nav__linkWrap">
            <a (click)="openContactModal(); closeMobileNav()" class="nav__link">Contact</a>
          </li>
          <li class="nav__linkWrap nav__linkWrap--dividerWrap">
            <div class="nav__divider"></div>
          </li>
          <li class="nav__linkWrap nav__linkWrap--noMobile" *ngIf="auth.isLoggedIn() && user?.isAdmin">
            <a class="nav__link" (click)="adminMenuOpen = !adminMenuOpen">Admin</a>
            <app-popover
              [options]="adminMenuOptions"
              [visible]="adminMenuOpen"
              (visibleChange)="adminMenuOpen = $event"
            >
              <div class="">
                <a [routerLink]="['/reports/deal-list']" (click)="adminMenuOpen = false" class="tooltip__link"
                  >Deal List</a
                >
                <a [routerLink]="['/reports/investor-list']" (click)="adminMenuOpen = false" class="tooltip__link"
                  >Investor List</a
                >
                <a [routerLink]="['/reports/investment-activity']" (click)="adminMenuOpen = false" class="tooltip__link"
                  >Investment Activity</a
                >
                <a
                  *ngIf="environment.name === 'prod'"
                  [routerLink]="['/reports/deal/blip-billboards']"
                  (click)="adminMenuOpen = false"
                  class="tooltip__link"
                  >Blip Deal Report</a
                >
                <a
                  *ngIf="environment.name !== 'prod'"
                  [routerLink]="['/reports/deal/blip']"
                  (click)="adminMenuOpen = false"
                  class="tooltip__link"
                  >Blip Deal Report</a
                >
              </div>
            </app-popover>
          </li>
          <ng-container *ngIf="auth.isLoggedIn() && user?.isAdmin">
            <li class="nav__linkWrap nav__linkWrap--mobile">
              <a [routerLink]="['/reports/deal-list']" class="nav__link" (click)="closeMobileNav()">Deal List</a>
            </li>
            <li class="nav__linkWrap nav__linkWrap--mobile">
              <a [routerLink]="['/reports/investor-list']" class="nav__link" (click)="closeMobileNav()"
                >Investor List</a
              >
            </li>
            <li class="nav__linkWrap nav__linkWrap--mobile">
              <a [routerLink]="['/reports/investment-activity']" class="nav__link" (click)="closeMobileNav()"
                >Investment Activity</a
              >
            </li>
            <li *ngIf="environment.name === 'prod'" class="nav__linkWrap nav__linkWrap--mobile">
              <a [routerLink]="['/reports/deal/blip-billboards']" class="nav__link" (click)="closeMobileNav()"
                >Blip Deal Report</a
              >
            </li>
            <li *ngIf="environment.name !== 'prod'" class="nav__linkWrap nav__linkWrap--mobile">
              <a [routerLink]="['/reports/deal/blip']" class="nav__link" (click)="closeMobileNav()">Blip Deal Report</a>
            </li>
          </ng-container>
          <li class="nav__linkWrap" *ngIf="auth.isLoggedIn()">
            <a [routerLink]="['/dashboard']" class="nav__link" (click)="closeMobileNav()">My Investments</a>
          </li>
          <ng-container *ngIf="!auth.isLoggedIn()">
            <li class="nav__linkWrap">
              <a [routerLink]="['/sign-up']" class="nav__link" (click)="closeMobileNav()">Sign&nbsp;Up</a>
            </li>
            <li class="nav__linkWrap">
              <a [routerLink]="['/login']" class="nav__link" (click)="closeMobileNav()">Login</a>
            </li>
          </ng-container>
          <ng-container *ngIf="auth.isLoggedIn()">
            <li class="nav__linkWrap nav__linkWrap--noMobile">
              <a (click)="userMenuOpen = !userMenuOpen" class="nav__link"
                ><i class="fa fa-user"></i> {{ user?.firstName }}</a
              >
              <app-popover [options]="userMenuOptions" [visible]="userMenuOpen" (visibleChange)="userMenuOpen = $event">
                <div class="">
                  <a [routerLink]="['/settings']" (click)="userMenuOpen = false" class="tooltip__link">Settings</a>
                  <a (click)="logout()" class="tooltip__link">Logout</a>
                </div>
              </app-popover>
            </li>
          </ng-container>
          <ng-container *ngIf="auth.isLoggedIn()">
            <li class="nav__linkWrap nav__linkWrap--mobile">
              <a
                [routerLink]="['/settings']"
                (click)="userMenuOpen = false"
                class="nav__link"
                (click)="closeMobileNav()"
                >Settings</a
              >
            </li>
            <li class="nav__linkWrap nav__linkWrap--mobile">
              <a (click)="logout()" class="nav__link" (click)="closeMobileNav()">Logout</a>
            </li>
          </ng-container>
        </ul>
      </div>
    </div>
  </div>
</div>
