<div class="ssContainer">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/reports/deal-list']">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal List</span>
    </a>
    <div class="backWrapper__header">
      <h1>Deal Report</h1>
    </div>
  </div>
  <div class="spinnerContainer" *ngIf="!userLoaded || !dealLoaded">
    <div class="ssSpinner"></div>
  </div>
  <ng-container *ngIf="userLoaded && dealLoaded && deal">
    <div class="logoHeader">
      <div class="logoHeader__logoContainer">
        <img src="{{ deal?.logo.url }}" class="table__logo" />
        <h3 class="logoHeader__company">{{ deal?.name }}</h3>
      </div>
    </div>
    <div class="dealStats">
      <div class="stat">
        <label>Status</label>
        <p class="capitalize">{{ deal.status }}</p>
      </div>
      <div class="stat">
        <label>Deadline</label>
        <p>{{ deadlineMoment.format('MMM D, YYYY') }} ({{ deal.deadline | toNow }})</p>
      </div>
      <div class="stat">
        <label>Allocation</label>
        <p>{{ deal.allocation | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Pledged</label>
        <p>{{ deal.pledgeStageTotal | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Legal</label>
        <p>{{ deal.legalStageTotal | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Finalize</label>
        <p>{{ deal.finalizeStageTotal | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Payment</label>
        <p>{{ deal.paymentStageTotal | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Funded</label>
        <p>{{ deal.fundedStageTotal | formatCurrency }}</p>
      </div>
      <div class="stat">
        <label>Left</label>
        <p>{{ deal.amountLeft | formatCurrency }}</p>
      </div>
    </div>
    <!-- <label class="resources__header">Investment Resources</label>
    <div class="resources">
      <a class="iconTextButton" *ngIf="deal.pitchDeck?.url" href="{{ deal.pitchDeck.url }}" target="_blank">
        <i class="fa fa-file fa-fw"></i>Pitch Deck
      </a>
      <ng-container *ngFor="let document of documents">
        <a
          class="iconTextButton"
          *ngIf="document.templateFile?.url"
          href="{{ document.templateFile.url }}"
          target="_blank"
        >
          <i class="fa fa-file fa-fw"></i>{{ document.name }}
        </a>
      </ng-container>
    </div> -->
  </ng-container>
</div>
<div class="tabPanel" *ngIf="userLoaded && dealLoaded && deal">
  <div class="tabPanel__container">
    <div class="tab" [ngClass]="{ 'tab--selected': selectedTab === 'pledged' }" (click)="selectedTab = 'pledged'">
      Pledged
    </div>
    <div class="tab" [ngClass]="{ 'tab--selected': selectedTab === 'legal' }" (click)="selectedTab = 'legal'">
      Legal
    </div>
    <div class="tab" [ngClass]="{ 'tab--selected': selectedTab === 'finalize' }" (click)="selectedTab = 'finalize'">
      Finalize
    </div>
    <div class="tab" [ngClass]="{ 'tab--selected': selectedTab === 'payment' }" (click)="selectedTab = 'payment'">
      Payment
    </div>
    <div class="tab" [ngClass]="{ 'tab--selected': selectedTab === 'funded' }" (click)="selectedTab = 'funded'">
      Funded
    </div>
  </div>
</div>
<div class="ssContainer" *ngIf="userLoaded && dealLoaded && deal">
  <p *ngIf="shownInvestments.length < 1" class="empty">There are no investments currently in this stage.</p>
  <!-- <button class="emailAll" *ngIf="shownInvestments.length > 0"><i class="fa fa-envelope fa-fw"></i> Email All</button> -->
  <div class="tableWrap" *ngIf="shownInvestments.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr>
            <th class="td--left">Inv ID.</th>
            <th class="td--left">Investor</th>
            <th class="td--left">Investment Profile</th>
            <th class="td--center">Amount</th>
            <th class="td--left">Last Activity</th>
            <!-- <th class="td--center">History</th> -->
            <th class="td--left"></th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let investment of shownInvestments">
            <td>{{ investmentShortId(investment) }}</td>
            <td>{{ investment.investor?.firstName }} {{ investment.investor?.lastName }}</td>
            <td>
              <p *ngIf="investment.profile">
                {{ investment.profile.legalName
                }}<span *ngIf="investment.fundingSource"><br />{{ investment.fundingSource.name }}</span>
              </p>
              <p *ngIf="!investment.profile">- -</p>
            </td>
            <td class="td--center">{{ investment.amount | formatCurrency }}</td>
            <td>{{ moment(investment.activities[0]?.created).format('MMM D, YYYY') }}</td>
            <!-- <td class="td--center">
              <a [routerLink]="['/reports/investment-detail']" class="badger">
                <i class="fa fa-history fa-fw"></i>
              </a>
            </td> -->
            <td>
              <a [routerLink]="['/reports/investment-detail']" class="buttonText">Manage</a>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
