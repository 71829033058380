<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/deal', deal?.slug]">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal</span>
    </a>
    <h1 class="backWrapper__header">Invest in {{ deal?.name }}</h1>
  </div>
  <!--/.backWrapper-->
  <div class="centerPage">
    <div class="steps" *ngIf="deal">
      <a class="steps__step steps__step--1 steps__step--done"></a>
      <div class="steps__line steps__line--1"></div>
      <a class="steps__step steps__step--2 steps__step--done"></a>
      <div class="steps__line steps__line--2"></div>
      <a class="steps__step steps__step--3 steps__step--active"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--4 steps__step--disabled"></a>
      <div class="steps__line steps__line--4"></div>
      <a class="steps__step steps__step--5 steps__step--disabled"></a>
    </div>
    <!--/.steps-->
    <p class="hint" style="margin-bottom: 1rem">Please click each of the documents below to sign.</p>
    <div class="spinnerContainer" *ngIf="loadingDocuments">
      <div class="ssSpinner"></div>
    </div>
    <ng-container *ngIf="!loadingDocuments">
      <div class="docs__row" *ngFor="let doc of documents">
        <a class="docs__link" [ngClass]="{ 'docs__link--disabled': isDownloading(doc) }" (click)="openDocument(doc)">
          <i class="docs__icon fa fa-file"></i>
          <span class="docs__text">{{ doc.template.name }}</span>
        </a>
        <i
          class="fas fa-circle-notch fa-spin"
          *ngIf="(doc.datetimeSubmitted && isDownloading(doc)) || loadingDocumentId === doc.pk"
        ></i>
        <i class="fa fa-check" *ngIf="doc.datetimeSubmitted && !isDownloading(doc)"></i>
        <h6 class="fineprint" *ngIf="!doc.datetimeSubmitted && loadingDocumentId !== doc.pk">Not&nbsp;Complete</h6>
      </div>
    </ng-container>
    <button type="button" class="button centerPage__button" (click)="clickNext()" [disabled]="!allDocumentsSigned">
      Next
    </button>
  </div>
  <!--/.centerPage-->
</div>
<!--/.ssContainer-->
