<div class="loadingBlur" *ngIf="clickedSubmit">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="modalGuts">
  <h1>Individual Profile</h1>
  <form>
    <div class="modalGuts__section">
      <div class="modalGuts__group modalGuts__group--half">
        <label>Your SSN*</label>
        <input
          type="text"
          class="input modalGuts__input"
          name="ssn"
          mask="000-00-0000"
          [showMaskTyped]="true"
          [(ngModel)]="ssn"
          *ngIf="!isEditing"
        />
        <input
          type="text"
          class="input modalGuts__input"
          name="ssn"
          [(ngModel)]="ssn"
          *ngIf="isEditing"
          [disabled]="true"
        />
      </div>
      <div class="modalGuts__group modalGuts__group--half">
        <label>Your Birth Date*</label>
        <input
          type="text"
          class="input modalGuts__input"
          name="birthdate"
          placeholder="mm/dd/yyyy"
          mask="M0/d0/0000"
          [leadZeroDateTime]="true"
          [showMaskTyped]="true"
          [(ngModel)]="birthdate"
          [disabled]="isEditing"
        />
      </div>
    </div>

    <div class="divider"></div>

    <div class="modalGuts__section">
      <div class="modalGuts__group modalGuts__group--half">
        <label>Mailing Address*</label>
        <input type="text" class="input modalGuts__input" name="address1" [(ngModel)]="address1" />
      </div>
      <div class="modalGuts__group modalGuts__group--half">
        <label>Apt/Suite</label>
        <input type="text" class="input modalGuts__input" name="address2" [(ngModel)]="address2" />
      </div>
      <div class="modalGuts__group modalGuts__group--half">
        <label>City*</label>
        <input type="text" class="input modalGuts__input" name="city" [(ngModel)]="city" />
      </div>
      <div class="modalGuts__group modalGuts__group--quarter">
        <label>State*</label>
        <div class="selectContainer">
          <select class="input modalGuts__input" name="state" [(ngModel)]="state">
            <option value="AL">AL</option>
            <option value="AK">AK</option>
            <option value="AR">AR</option>
            <option value="AZ">AZ</option>
            <option value="CA">CA</option>
            <option value="CO">CO</option>
            <option value="CT">CT</option>
            <option value="DC">DC</option>
            <option value="DE">DE</option>
            <option value="FL">FL</option>
            <option value="GA">GA</option>
            <option value="HI">HI</option>
            <option value="IA">IA</option>
            <option value="ID">ID</option>
            <option value="IL">IL</option>
            <option value="IN">IN</option>
            <option value="KS">KS</option>
            <option value="KY">KY</option>
            <option value="LA">LA</option>
            <option value="MA">MA</option>
            <option value="MD">MD</option>
            <option value="ME">ME</option>
            <option value="MI">MI</option>
            <option value="MN">MN</option>
            <option value="MO">MO</option>
            <option value="MS">MS</option>
            <option value="MT">MT</option>
            <option value="NC">NC</option>
            <option value="NE">NE</option>
            <option value="NH">NH</option>
            <option value="NJ">NJ</option>
            <option value="NM">NM</option>
            <option value="NV">NV</option>
            <option value="NY">NY</option>
            <option value="ND">ND</option>
            <option value="OH">OH</option>
            <option value="OK">OK</option>
            <option value="OR">OR</option>
            <option value="PA">PA</option>
            <option value="RI">RI</option>
            <option value="SC">SC</option>
            <option value="SD">SD</option>
            <option value="TN">TN</option>
            <option value="TX">TX</option>
            <option value="UT">UT</option>
            <option value="VT">VT</option>
            <option value="VA">VA</option>
            <option value="WA">WA</option>
            <option value="WI">WI</option>
            <option value="WV">WV</option>
            <option value="WY">WY</option>
          </select>
        </div>
      </div>
      <div class="modalGuts__group modalGuts__group--quarter">
        <label>ZIP*</label>
        <input type="text" class="input modalGuts__input" name="postalCode" [(ngModel)]="postalCode" />
      </div>
    </div>
    <button type="submit" class="button modalGuts__button" (click)="createOrUpdate()" [disabled]="formDisabled()">
      <span *ngIf="!isEditing">Next</span>
      <span *ngIf="isEditing">Save</span>
    </button>
  </form>
</div>
