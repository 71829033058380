<app-toast-outlet></app-toast-outlet>
<app-nav *ngIf="showNav"></app-nav>
<router-outlet></router-outlet>
<app-modal-outlet></app-modal-outlet>
<app-footer *ngIf="showFooter"></app-footer>
<div class="banner" *ngIf="showCTA">
  <div class="cta">
    <h4 class="cta__text" *ngIf="inProcessInvestments.length > 0 && banners.activeBanners.length > 0">
      You have {{ inProcessInvestments.length }} in-progress deal<span *ngIf="inProcessInvestments.length > 1">s</span>
      to finish and {{ banners.activeBanners.length }} new deal<span *ngIf="banners.activeBanners.length > 1">s</span>
      to review.
    </h4>
    <h4 class="cta__text" *ngIf="inProcessInvestments.length > 0 && banners.activeBanners.length === 0">
      You have {{ inProcessInvestments.length }} in-progress deal<span *ngIf="inProcessInvestments.length > 1">s</span>
      to finish.
    </h4>
    <h4 class="cta__text" *ngIf="inProcessInvestments.length === 0 && banners.activeBanners.length > 0">
      You have {{ banners.activeBanners.length }} new deal<span *ngIf="banners.activeBanners.length > 1">s</span> to
      review.
    </h4>
    <button class="button2 cta__button" type="button" [routerLink]="['/dashboard']">See Deals</button>
  </div>
</div>
