<footer class="footer">
  <div class="ssContainer">
    <div class="footer__inner">
      <p class="footer__copy">&copy;{{ date.format('YYYY') }}, Beehive Venture Partners, All Rights Reserved</p>
      <div class="footer__social">
        <a href="https://www.linkedin.com/company/beehivevp" target="_blank" class="footer__socialLink">
          <i class="icon-linkedin"></i>
        </a>
        <a href="https://twitter.com/BeehiveVP" target="_blank" class="footer__socialLink">
          <i class="icon-twitter"></i>
        </a>
        <a href="https://www.instagram.com/beehive.vp/" target="_blank" class="footer__socialLink">
          <i class="icon-instagram"></i>
        </a>
        <a href="https://www.facebook.com/BeehiveVP" target="_blank" class="footer__socialLink">
          <i class="icon-facebook"></i>
        </a>
      </div>
      <button class="buttonFooter" (click)="openContactModal()">Contact Us</button>
    </div>
    <!--/.footer__inner-->
  </div>
  <!--/.ssContainer-->
</footer>
