import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { BannerService } from '../../services';

@Component({
  selector: 'app-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
})
export class BannerComponent {
  public environment = environment;

  @Input()
  public deal = null;

  constructor(private router: Router, public banners: BannerService) {}

  public routeToDeal() {
    if (!this.deal.pk) {
      return;
    }
    this.router.navigate(['/deal', this.deal.slug]);
    this.banners.dismiss(this.deal.pk);
  }
}
