<div class="ssContainer">
  <h1>Reset Password</h1>
  <div class="centerPage">
    <p class="centerPage__instructions">Create a new password.</p>
    <form (submit)="updatePassword()">
      <input
        type="password"
        class="input centerPage__input"
        id="password1"
        name="password1"
        placeholder="Password"
        [(ngModel)]="password1"
      />
      <input
        type="password"
        class="input centerPage__input"
        id="password2"
        name="password2"
        placeholder="Password (Again)"
        [(ngModel)]="password2"
      />
      <h6 class="hint">Passwords must be at least 12 characters, with at least one number, at least one letter, and at least one symbol.</h6>
      <p class="error" *ngIf="error">{{ error }}</p>
      <button type="submit" class="button centerPage__button" [disabled]="updatePasswordDisabled()">
        Update Password
      </button>
    </form>
  </div>
</div>
