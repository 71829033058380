<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <h1>{{ deal?.name }}</h1>
  <div class="confidential" [ngClass]="{ 'confidential--open': showConfidential }">
    <div class="ssContainer confidential__container">
      <h4 class="confidential__header">Strictly&nbsp;Confidential:</h4>
      <p>If you disclose any deal information, you may be kicked out of the Beehive Syndicate.</p>
    </div>
    <button type="button" class="iconButton confidential__button" (click)="closeConfidential()">
      <i class="icon-close"></i>
    </button>
  </div>
  <!--/.confidential-->
  <div class="deal">
    <div class="deal__main">
      <div class="deal__video" *ngIf="hasVideo">
        <div class="deal__video__overlay" *ngIf="!videoPlaying" (click)="playVideo()">
          <i class="fa fa-play-circle fa-fw"></i>
        </div>
        <video *ngIf="hasVideoPreview" poster="{{ deal.videoPreview.url }}" controlslist="nodownload" #videoPlayer>
          <source src="{{ deal.video?.url }}" />
        </video>
        <video *ngIf="!hasVideoPreview" controlslist="nodownload" #videoPlayer>
          <source src="{{ deal.video?.url }}" />
        </video>
      </div>
      <p class="deal__p">
        <markdown ngPreserveWhitespaces>
          {{ deal?.introduction }}
        </markdown>
      </p>
      <ng-container *ngIf="deal?.moreInfo && !showMoreInfo">
        <a (click)="showMoreInfo = true">Read more <i class="fa fa-angle-down fa-fw"></i></a>
      </ng-container>
      <p class="deal__p" *ngIf="deal?.moreInfo && showMoreInfo">
        <markdown ngPreserveWhitespaces>
          {{ deal?.moreInfo }}
        </markdown>
      </p>
      <ul class="deal__files" *ngIf="documents.length > 0 || deal.pitchDeck?.url">
        <li class="deal__file" *ngIf="deal.pitchDeck?.url">
          <a class="docs__link" href="{{ deal.pitchDeck.url }}" target="_blank">
            <i class="docs__icon fa fa-file fa-fw"></i>
            <span class="docs__text">Pitch Deck</span>
          </a>
        </li>
        <li class="deal__file" *ngFor="let document of documents">
          <a
            class="docs__link"
            *ngIf="document.templateFile?.url"
            href="{{ document.templateFile.url }}"
            target="_blank"
          >
            <i class="docs__icon fa fa-file fa-fw"></i>
            <span class="docs__text">{{ document.name }}</span>
          </a>
        </li>
      </ul>
    </div>
    <!--/.deal__main-->

    <div>
      <!--sidebar of deal page-->
      <!-- <div class="deadline" *ngIf="deal">
        <p style="font-weight: bold; margin-bottom: 1rem">Limited Availability!</p>
        <p style="font-style: italic; font-size: 14px">
          Our investment allocation is almost full. To secure your spot, please finish your transaction ASAP!
        </p>
      </div> -->
      <div class="deadline" *ngIf="deal?.deadline && daysDiff(deal?.deadline) <= 14">
        <p style="font-weight: bold; margin-bottom: 1rem">This deal closes in {{ deal?.deadline | toNow }}.</p>
        <p style="font-style: italic; font-size: 14px">
          Please complete your docs and send your wire as soon as possible!
        </p>
      </div>
      <div class="deal__inner" *ngIf="deal">
        <img class="deal__logo" src="{{ deal.logo.url }}" />
        <div *ngIf="deal.amountAlreadyRaisedExternally">
          <p style="font-size: 12px; font-weight: bold; margin-bottom: 1rem">
            <span class="capitalize">{{ removeUnderscore(deal.round) }}</span> Round
          </p>
          <p class="roundSizeProgress__text">
            <strong>{{ deal.amountAlreadyRaisedExternally | formatCurrency }}</strong> of
            <strong>{{ deal.roundSize | formatCurrency }}</strong> already raised as
            <span style="text-transform: uppercase">{{ deal.vehicle }}</span>
          </p>
          <div class="roundSizeProgress">
            <div class="roundSizeProgress__fill" [ngStyle]="{ width: alreadyRaisedPercent() + '%' }"></div>
          </div>
        </div>
        <div class="deal__grid">
          <ng-container *ngIf="!deal.amountAlreadyRaisedExternally">
            <div>
              <label class="deal__label">Round</label>
              <p class="capitalize">{{ removeUnderscore(deal.round) }}</p>
            </div>
            <div>
              <label class="deal__label"
                >Raising as <span style="text-transform: uppercase">{{ deal.vehicle }}</span></label
              >
              <p>
                {{ deal.roundSize | formatCurrency }}
              </p>
            </div>
            <div class="deal__divider"></div>
          </ng-container>
          <div class="relative">
            <label class="deal__label">
              <span *ngIf="deal.vehicle === 'equity'">Pre-$ Valuation </span>
              <span *ngIf="deal.vehicle !== 'equity'">Cap </span>
              <i class="setupCostPopover__trigger fa fa-question-circle fa-fw" (click)="capTooltipOpen = true"></i>
            </label>
            <p>{{ deal.cap | formatCurrency }}</p>
            <app-popover
              [visible]="capTooltipOpen"
              (visibleChange)="capTooltipOpen = $event"
              [options]="setupCostTooltipOptions"
            >
              <div class="setupCostPopover">May be rounded / approximate</div>
            </app-popover>
          </div>
          <div>
            <label class="deal__label">Pro-rata Rights</label>
            <p *ngIf="deal.hasProRataRights">Included</p>
            <p *ngIf="!deal.hasProRataRights">Not Included</p>
          </div>
          <div class="deal__divider"></div>
          <div>
            <label class="deal__label">Allocation</label>
            <p>{{ deal.allocation | formatCurrency }}</p>
          </div>
          <div class="relative">
            <label class="deal__label"
              >Setup Cost
              <i class="setupCostPopover__trigger fa fa-question-circle fa-fw" (click)="setupCostTooltipOpen = true"></i
            ></label>
            <p>{{ deal.setupCost }}%</p>
            <app-popover
              [visible]="setupCostTooltipOpen"
              (visibleChange)="setupCostTooltipOpen = $event"
              [options]="setupCostTooltipOptions"
            >
              <div class="setupCostPopover">
                Aggregate company expenses and management fees will be {{ deal.setupCost }}%.
              </div>
            </app-popover>
          </div>
          <div class="deal__divider"></div>
          <div>
            <label class="deal__label">Carry</label>
            <p>{{ deal.carry }}%</p>
          </div>
          <div>
            <label class="deal__label">Min. Investment</label>
            <p>{{ deal.minimumInvestment | formatCurrency }}</p>
          </div>
          <ng-container *ngIf="deal.leadInvestor">
            <div class="deal__divider"></div>
            <div>
              <label class="deal__label">Lead Investor</label>
              <p>{{ deal.leadInvestor }}</p>
            </div>
          </ng-container>
        </div>
        <button
          type="button"
          class="button"
          style="width: 100%; margin-top: 2rem; padding: 0 2rem"
          [routerLink]="investButtonLink"
          *ngIf="!myInvestment || myInvestment.stage !== 'Transfer Pending'"
        >
          {{ investButtonText }}
        </button>
      </div>
      <!--/.deal__inner-->
      <div class="deal__inner meet" *ngFor="let meeting of meetings">
        <h4 class="meet__header">Meet &amp; Greet / Q&amp;A Event</h4>
        <h5 class="meet__h5">{{ moment(meeting.schedule).format('dddd, MMM D, hh:mm A') }}</h5>
        <div class="meet__ceo">
          {{ meeting.name }}
          <h6 class="meet__title">{{ meeting.role }}</h6>
        </div>
        <h5 class="meet__h5">
          <a href="{{ meeting.url }}" target="_blank">{{ meeting.url }}</a>
        </h5>
      </div>
      <!--/.deal__inner-->
    </div>
    <!--/ sidebar of deal page-->
  </div>
  <!--/.deal-->
</div>
<!--/.ssContainer-->

<div class="deal__bottom" *ngIf="!loadingDeal && !isPublished">
  <div class="cta" *ngIf="!hasVideo">
    <h4 class="cta__text"><i class="fa fa-exclamation-triangle"></i> This deal does not have a video.</h4>
  </div>
  <div class="cta" *ngIf="!hasDocumentTemplates && hasVideo">
    <h4 class="cta__text">
      <i class="fa fa-exclamation-triangle"></i> This deal needs document templates before it can be published.
    </h4>
  </div>
  <div class="cta" *ngIf="hasDocumentTemplates && hasVideo">
    <h4 class="cta__text">
      <i class="fa fa-exclamation-triangle"></i> Users will not see this deal until it has been published.
    </h4>
  </div>
</div>
<!--/.deal__bottom-->
