<div
  class="modal"
  [hidden]="!visible"
  [ngClass]="{ 'modal--noBackdrop': !options?.backdropDisabled }"
  (click)="close()"
>
  <div
    class="modal__content"
    [ngStyle]="{
      animation: options?.animation,
      width: options?.width,
      height: options?.height,
      position: options.position?.type ? options.position?.type : 'relative',
      top: options.position?.top ? options.position?.top : 'auto',
      bottom: options.position?.bottom ? options.position?.bottom : 'auto',
      left: options.position?.left ? options.position?.left : 'auto',
      right: options.position?.right ? options.position?.right : 'auto'
    }"
    (click)="$event.stopPropagation()"
  >
    <div class="modal__close" [hidden]="options?.closeDisabled" (click)="close()">
      <a class="modal__closeIcon icon-close"></a>
    </div>
    <ng-container #content></ng-container>
  </div>
</div>
