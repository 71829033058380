<div class="loadingBlur" *ngIf="loadingSigningLink || creatingProfileApplication">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div
  class="tooltip__scrim"
  [ngClass]="{ 'tooltip__scrim--open': profileDropdownOpen }"
  (click)="profileDropdownOpen = false"
></div>
<div class="ssContainer">
  <h1>Investment Dashboard</h1>

  <div class="spinnerContainer" *ngIf="loadingProfile">
    <div class="ssSpinner"></div>
  </div>

  <ng-container *ngIf="!loadingProfile && investmentProfiles.length < 1">
    <div class="investorStatusBanner">
      <h3 class="investorStatusBanner__header">How Would You Like to Invest?</h3>
      <p class="investorStatusBanner__text">
        Please add at least one investment profile
        <i class="fa fa-question-circle fa-fw" (click)="openInvestProfileHelp()"></i> in order to be able to see deals
        and invest.
      </p>
      <div class="investorStatusBanner__buttons">
        <button type="button" class="button2 investorStatusBanner__button" (click)="openPersonalProfileModal()">
          Invest as an Individual
        </button>
        <button type="button" class="button2 investorStatusBanner__button" (click)="openEntityProfileModal()">
          Invest via an Entity
        </button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="!loadingProfile && investmentProfiles.length > 0">
    <label>Investment Profile</label>
    <div class="dropdown">
      <div class="dropdown__visible" (click)="profileDropdownOpen = !profileDropdownOpen">
        <p>
          <span>{{ selectedProfile?.legalName }}</span>
        </p>
      </div>
      <ul
        class="dropdown__menu"
        [ngClass]="{ 'dropdown__menu--open': profileDropdownOpen }"
        (click)="profileDropdownOpen = false"
      >
        <li class="dropdown__item" *ngFor="let profile of investmentProfiles" (click)="selectedProfile = profile">
          <span
            >{{ profile.legalName
            }}<span
              *ngIf="
                profile.latestApplication &&
                !profile.latestApplication.isRejected &&
                !profile.latestApplication.isAccepted
              "
            >
              (Unverified)</span
            ></span
          ><span *ngIf="profile.latestApplication && profile.latestApplication.isRejected">(Rejected)</span
          ><span *ngIf="!profile.latestApplication"><i class="table__exclaim fa fa-exclamation-triangle"></i></span>
          <!-- <i class="fa fa-pencil fa-fw" (click)="$event.stopPropagation(); editProfile(profile)"></i> -->
        </li>
        <li class="dropdown__item dropdown__item--button" *ngIf="personalProfiles.length < 1">
          <button class="buttonText buttonText--noHover" type="button" (click)="openPersonalProfileModal()">
            <i class="buttonText__icon fa fa-plus"></i> Add Individual Profile
          </button>
        </li>
        <li class="dropdown__item dropdown__item--button">
          <button class="buttonText buttonText--noHover" type="button" (click)="openEntityProfileModal()">
            <i class="buttonText__icon fa fa-plus"></i> Add Entity Profile
          </button>
        </li>
      </ul>
    </div>
    <div class="profileHeader">
      <div class="profileHeader__flex">
        <h2>{{ selectedProfileTypeHumanized }} profile</h2>
        <!-- <button
          class="buttonText"
          type="button"
          *ngIf="selectedProfileQuestionnaireComplete"
          [disabled]="downloadingQuestionnaire"
          (click)="downloadQuestionnaire(selectedProfile)"
        >
          <i class="buttonText__icon fa fa-download"></i> Download Questionnaire
        </button> -->
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showNoApplication">
    <div class="verifyBanner">
      <h3 class="verifyBanner__header">No Profile Application</h3>
      <h4 class="verifyBanner__text">
        This investor profile for {{ selectedProfile.legalName }} has no profile application.<br /><br />
        Please
        <a class="verifyBanner__link" (click)="applicationReapply(selectedProfile.id)">complete a questionnaire</a>
        or
        <a class="verifyBanner__link" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >schedule with us</a
        >
        if you need any assistance.
      </h4>
      <div class="verifyBanner__buttonsInline">
        <a class="button2 verifyBanner__button" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >Schedule</a
        >
        <a class="button2 verifyBanner__button" (click)="applicationReapply(selectedProfile.id)">Questionnaire</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showRejected">
    <div class="verifyBanner">
      <h3 class="verifyBanner__header">Rejected</h3>
      <h4 class="verifyBanner__text">
        This investor profile for {{ selectedProfile.legalName }} has been rejected.<br /><br />
        Reason: {{ selectedProfileRejectedReason }}<br /><br />
        Please
        <a class="verifyBanner__link" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >schedule with us</a
        >
        or
        <a class="verifyBanner__link" (click)="applicationReapply(selectedProfile.id)">reapply</a>
        to discuss how to remedy.
      </h4>
      <div class="verifyBanner__buttonsInline">
        <a class="button2 verifyBanner__button" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >Schedule</a
        >
        <a class="button2 verifyBanner__button" (click)="applicationReapply(selectedProfile.id)">Reapply</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showIncompleteQuestionnaire">
    <div class="verifyBanner">
      <h3 class="verifyBanner__header">Incomplete Questionnaire</h3>
      <h4 class="verifyBanner__text">
        This investor profile for {{ selectedProfile.legalName }} has an incomplete questionnaire.<br /><br />
        Please
        <a class="verifyBanner__link" (click)="openProfileQuestionnaire(selectedProfile.id)"
          >complete the questionnaire</a
        >
        or
        <a class="verifyBanner__link" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >schedule with us</a
        >
        if you need any assistance.
      </h4>
      <div class="verifyBanner__buttonsInline">
        <a class="button2 verifyBanner__button" href="https://www.calendar.com/lukegundy/15-onboarding" target="_blank"
          >Schedule</a
        >
        <a class="button2 verifyBanner__button" (click)="openProfileQuestionnaire(selectedProfile.id)">Questionnaire</a>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="showPendingVerification">
    <div class="verifyBanner">
      <h3 class="verifyBanner__header">Pending Verification</h3>
      <h4 class="verifyBanner__text">
        This investor profile for {{ selectedProfile.legalName }} is pending verification. Please allow 1-2 days for
        processing. Once your profile has been approved, you'll receive an email inviting you to explore new deals and
        invest through this new investor profile.
      </h4>
    </div>
  </ng-container>

  <!-- <ng-container *ngIf="!loadingProfile && !!selectedProfile">
    <a
      style="display: block; text-align: center; margin-bottom: 1.5rem"
      (click)="editInvestmentProfile(selectedProfile)"
      ><i class="fa fa-pencil fa-fw"></i> Edit this {{ selectedProfileTypeHumanized }} investment profile</a
    >
  </ng-container> -->

  <ng-container *ngIf="!loadingProfile && user?.isAdmin && !loadingDrafts && draftDeals.length > 0">
    <h4>Draft Deals</h4>
    <div class="tableWrap">
      <div class="tableWrap__inner">
        <table>
          <thead>
            <tr>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'deal' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'deal' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('deal')"
              >
                Deal
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'roundSize' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'roundSize' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('roundSize')"
              >
                Round Size
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'allocation' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'allocation' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('allocation')"
              >
                Allocation
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'minInvestment' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'minInvestment' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('minInvestment')"
              >
                Min. Investment
              </th>
              <!-- <th
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'deadline' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'deadline' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('deadline')"
              >
                Closes
              </th> -->
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let deal of draftDeals">
              <td>
                <a class="table__flex" [routerLink]="['/deal', deal.slug]">
                  <img src="{{ deal.logo.url }}" class="table__logo" />
                  <span class="table__link">{{ deal.name }}</span>
                </a>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.roundSize >= 10000"
                  (click)="openNumberTip('draftroundSize', deal, deal.roundSize)"
                  >{{ deal.roundSize | formatCurrency }}</a
                >
                <span *ngIf="deal.roundSize < 10000">{{ deal.roundSize | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['draftroundSize' + deal.id]"
                  (visibleChange)="numberTipOpen['draftroundSize' + deal.id] = $event"
                  ><div class="numberTooltip">{{ deal.roundSize | currency }}</div>
                </app-popover>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.allocation >= 10000"
                  (click)="openNumberTip('draftallocation', deal, deal.allocation)"
                  >{{ deal.allocation | formatCurrency }}</a
                >
                <span *ngIf="deal.allocation < 10000">{{ deal.allocation | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['draftallocation' + deal.id]"
                  (visibleChange)="numberTipOpen['draftallocation' + deal.id] = $event"
                  ><div class="numberTooltip">
                    {{ deal.allocation | currency }}
                  </div>
                </app-popover>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.minimumInvestment >= 10000"
                  (click)="openNumberTip('draftminimum', deal, deal.minimumInvestment)"
                  >{{ deal.minimumInvestment | formatCurrency }}</a
                >
                <span *ngIf="deal.minimumInvestment < 10000">{{ deal.minimumInvestment | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['draftminimum' + deal.id]"
                  (visibleChange)="numberTipOpen['draftminimum' + deal.id] = $event"
                  ><div class="numberTooltip">
                    {{ deal.minimumInvestment | currency }}
                  </div>
                </app-popover>
              </td>
              <!-- <td class="td--center">{{ moment(deal.deadline).format('MM/DD/YY') }}</td> -->
              <td>
                <a [routerLink]="['/deal', deal.slug]" class="buttonText">View Deal</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </ng-container>

  <ng-container
    *ngIf="
      !loadingProfile &&
      !selectedProfileRejectedReason &&
      selectedProfileQuestionnaireComplete &&
      selectedProfileVerified
    "
  >
    <h4>Available</h4>
    <div class="spinnerContainer" *ngIf="loadingAvailable">
      <div class="ssSpinner"></div>
    </div>
    <div *ngIf="!loadingAvailable && availableDeals.length === 0">
      <p class="empty">You have no available deals.</p>
    </div>
    <div class="tableWrap" *ngIf="!loadingAvailable && availableDeals.length > 0">
      <div class="tableWrap__inner">
        <table>
          <thead>
            <tr>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': availableSort.field === 'deal' && availableSort.direction === 'dsc',
                  'th--sortZA': availableSort.field === 'deal' && availableSort.direction === 'asc'
                }"
                (click)="availableHeaderClick('deal')"
              >
                Deal
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': availableSort.field === 'stage' && availableSort.direction === 'dsc',
                  'th--sortZA': availableSort.field === 'stage' && availableSort.direction === 'asc'
                }"
                (click)="availableHeaderClick('stage')"
              >
                Stage
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': availableSort.field === 'roundSize' && availableSort.direction === 'dsc',
                  'th--sortZA': availableSort.field === 'roundSize' && availableSort.direction === 'asc'
                }"
                (click)="availableHeaderClick('roundSize')"
              >
                Round Size
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': availableSort.field === 'allocation' && availableSort.direction === 'dsc',
                  'th--sortZA': availableSort.field === 'allocation' && availableSort.direction === 'asc'
                }"
                (click)="availableHeaderClick('allocation')"
              >
                Allocation
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': draftSort.field === 'minInvestment' && draftSort.direction === 'dsc',
                  'th--sortZA': draftSort.field === 'minInvestment' && draftSort.direction === 'asc'
                }"
                (click)="draftHeaderClick('minInvestment')"
              >
                Min. Investment
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': availableSort.field === 'deadline' && availableSort.direction === 'dsc',
                  'th--sortZA': availableSort.field === 'deadline' && availableSort.direction === 'asc'
                }"
                (click)="availableHeaderClick('deadline')"
              >
                Closes
              </th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let deal of availableDeals" [ngClass]="{ 'tr--highlight': shouldHighlightDeal(deal.pk) }">
              <td>
                <a class="table__flex" [routerLink]="['/deal', deal.slug]">
                  <img src="{{ deal.logo.url }}" class="table__logo" />
                  <span class="table__link">{{ deal.name }}</span>
                </a>
              </td>
              <td class="td--center capitalize">{{ removeUnderscore(deal.round) }}</td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.roundSize >= 10000"
                  (click)="openNumberTip('roundSize', deal, deal.roundSize)"
                  >{{ deal.roundSize | formatCurrency }}</a
                >
                <span *ngIf="deal.roundSize < 10000">{{ deal.roundSize | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['roundSize' + deal.id]"
                  (visibleChange)="numberTipOpen['roundSize' + deal.id] = $event"
                  ><div class="numberTooltip">{{ deal.roundSize | currency }}</div>
                </app-popover>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.allocation >= 10000"
                  (click)="openNumberTip('allocation', deal, deal.allocation)"
                  >{{ deal.allocation | formatCurrency }}</a
                >
                <span *ngIf="deal.allocation < 10000">{{ deal.allocation | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['allocation' + deal.id]"
                  (visibleChange)="numberTipOpen['allocation' + deal.id] = $event"
                  ><div class="numberTooltip">
                    {{ deal.allocation | currency }}
                  </div>
                </app-popover>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="deal.minimumInvestment >= 10000"
                  (click)="openNumberTip('draftminimum', deal, deal.minimumInvestment)"
                  >{{ deal.minimumInvestment | formatCurrency }}</a
                >
                <span *ngIf="deal.minimumInvestment < 10000">{{ deal.minimumInvestment | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['draftminimum' + deal.id]"
                  (visibleChange)="numberTipOpen['draftminimum' + deal.id] = $event"
                  ><div class="numberTooltip">
                    {{ deal.minimumInvestment | currency }}
                  </div>
                </app-popover>
              </td>
              <td class="td--center" [ngClass]="{ redText: deal.deadline && daysDiff(deal.deadline) <= 14 }">
                {{ moment(deal.deadline) | toNow }}
              </td>
              <!-- <td class="td--center">{{ moment(deal.deadline).format('MM/DD/YY') }}</td> -->
              <td>
                <a [routerLink]="['/deal', deal.slug]" class="buttonText">View Deal</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.tableWrap__inner-->
    </div>
    <!--/.tableWrap-->
  </ng-container>

  <ng-container
    *ngIf="
      !loadingProfile &&
      !selectedProfileRejectedReason &&
      selectedProfileQuestionnaireComplete &&
      selectedProfileVerified
    "
  >
    <h4>In Progress</h4>
    <div class="spinnerContainer" *ngIf="loadingInProcess">
      <div class="ssSpinner"></div>
    </div>
    <div *ngIf="!loadingInProcess && inProcessInvestments.length === 0">
      <p class="empty">You have no deals in process.</p>
    </div>
    <div class="tableWrap" *ngIf="!loadingInProcess && inProcessInvestments.length > 0">
      <div class="tableWrap__inner">
        <table>
          <thead>
            <tr>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': inProcessSort.field === 'deal' && inProcessSort.direction === 'dsc',
                  'th--sortZA': inProcessSort.field === 'deal' && inProcessSort.direction === 'asc'
                }"
                (click)="inProcessHeaderClick('deal')"
              >
                Deal
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': inProcessSort.field === 'purchasePrice' && inProcessSort.direction === 'dsc',
                  'th--sortZA': inProcessSort.field === 'purchasePrice' && inProcessSort.direction === 'asc'
                }"
                (click)="inProcessHeaderClick('purchasePrice')"
              >
                Pledged
              </th>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': inProcessSort.field === 'profile' && inProcessSort.direction === 'dsc',
                  'th--sortZA': inProcessSort.field === 'profile' && inProcessSort.direction === 'asc'
                }"
                (click)="inProcessHeaderClick('profile')"
              >
                Investor
              </th>
              <th>Docs</th>
              <th
                [ngClass]="{
                  'th--sortAZ': inProcessSort.field === 'deadline' && inProcessSort.direction === 'dsc',
                  'th--sortZA': inProcessSort.field === 'deadline' && inProcessSort.direction === 'asc'
                }"
                (click)="inProcessHeaderClick('deadline')"
              >
                Closes
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': inProcessSort.field === 'stage' && inProcessSort.direction === 'dsc',
                  'th--sortZA': inProcessSort.field === 'stage' && inProcessSort.direction === 'asc'
                }"
                (click)="inProcessHeaderClick('stage')"
              >
                Status
              </th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let investment of inProcessInvestments">
              <td>
                <a class="table__flex" [routerLink]="['/deal', investment.deal.slug]">
                  <img src="{{ investment.deal.logo.url }}" class="table__logo" />
                  <span class="table__link">{{ investment.deal.name }}</span>
                </a>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="investment.purchasePrice >= 10000"
                  (click)="openNumberTip('inprocesspurchasePrice', investment, investment.purchasePrice)"
                  >{{ investment.purchasePrice | formatCurrency }}</a
                >
                <span *ngIf="investment.purchasePrice < 10000">{{ investment.purchasePrice | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['inprocesspurchasePrice' + investment.id]"
                  (visibleChange)="numberTipOpen['inprocesspurchasePrice' + investment.id] = $event"
                  ><div class="numberTooltip">
                    {{ investment.purchasePrice | currency }}
                  </div>
                </app-popover>
              </td>
              <td>
                <p *ngIf="investment.profile">
                  {{ investment.profile.legalName }}
                </p>
                <p *ngIf="!investment.profile">- -</p>
              </td>
              <td class="td--center">
                <ng-container
                  *ngIf="investment.stage === 'Initiate Transfer' || investment.stage === 'Transfer Pending'"
                >
                  <button type="button" class="iconButton table__iconButton" (click)="clickDownloadDocs(investment)">
                    <i class="fa fa-download"></i>
                  </button>
                </ng-container>
                <ng-container
                  *ngIf="investment.stage !== 'Initiate Transfer' && investment.stage !== 'Transfer Pending'"
                  >- -
                </ng-container>
              </td>
              <td
                class="td--center"
                [ngClass]="{ redText: investment.deal.deadline && daysDiff(investment.deal.deadline) <= 14 }"
              >
                {{ moment(investment.deal.deadline) | toNow }}
              </td>
              <!-- <td class="td--center">{{ moment(investment.deal.deadline).format('MM/DD/YY') }}</td> -->
              <td class="td--center">
                <ng-container *ngIf="investment.stage !== 'Transfer Pending'">
                  <a [routerLink]="routerLinkForStage(investment)">{{ investment.stage }}</a>
                  <i class="table__exclaim fa fa-exclamation-triangle"></i>
                </ng-container>
                <ng-container *ngIf="investment.stage === 'Transfer Pending' && investment.paymentType === 'wire'">
                  <!-- <a (click)="openWireInstructions(investment)">{{ investment.stage }}</a> -->
                  <a [routerLink]="routerLinkForStage(investment)">{{ investment.stage }}</a>
                  <i class="table__exclaim fa fa-exclamation-triangle"></i>
                </ng-container>
                <ng-container *ngIf="investment.stage === 'Transfer Pending' && investment.paymentType !== 'wire'">
                  {{ investment.stage }}
                </ng-container>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.tableWrap__inner-->
    </div>
    <!--/.tableWrap-->
  </ng-container>

  <ng-container
    *ngIf="
      !loadingProfile &&
      !selectedProfileRejectedReason &&
      selectedProfileQuestionnaireComplete &&
      selectedProfileVerified
    "
  >
    <h4>Completed</h4>
    <div class="spinnerContainer" *ngIf="loadingCompleted">
      <div class="ssSpinner"></div>
    </div>
    <div *ngIf="!loadingCompleted && completedInvestments.length === 0">
      <p class="empty">You have no completed deals.</p>
    </div>
    <div class="tableWrap" *ngIf="!loadingCompleted && completedInvestments.length > 0">
      <div class="tableWrap__inner">
        <table>
          <thead>
            <tr>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': completedSort.field === 'deal' && completedSort.direction === 'dsc',
                  'th--sortZA': completedSort.field === 'deal' && completedSort.direction === 'asc'
                }"
                (click)="completedHeaderClick('deal')"
              >
                Deal
              </th>
              <th
                [ngClass]="{
                  'th--sortAZ': completedSort.field === 'purchasePrice' && completedSort.direction === 'dsc',
                  'th--sortZA': completedSort.field === 'purchasePrice' && completedSort.direction === 'asc'
                }"
                (click)="completedHeaderClick('purchasePrice')"
              >
                Amount
              </th>
              <th
                class="td--left"
                [ngClass]="{
                  'th--sortAZ': completedSort.field === 'profile' && completedSort.direction === 'dsc',
                  'th--sortZA': completedSort.field === 'profile' && completedSort.direction === 'asc'
                }"
                (click)="completedHeaderClick('profile')"
              >
                Investor
              </th>
              <th>Docs</th>
              <!-- <th
                [ngClass]="{
                  'th--sortAZ': completedSort.field === 'status' && completedSort.direction === 'dsc',
                  'th--sortZA': completedSort.field === 'status' && completedSort.direction === 'asc'
                }"
                (click)="completedHeaderClick('status')"
              >
                Status
              </th>
              <th></th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let investment of completedInvestments">
              <td>
                <a class="table__flex" [routerLink]="['/deal', investment.deal.slug]">
                  <img src="{{ investment.deal.logo.url }}" class="table__logo" />
                  <span class="table__link">{{ investment.deal.name }}</span>
                </a>
              </td>
              <td class="td--center">
                <a
                  class="table__number"
                  *ngIf="investment.purchasePrice >= 10000"
                  (click)="openNumberTip('completepurchasePrice', investment, investment.purchasePrice)"
                  >{{ investment.purchasePrice | formatCurrency }}</a
                >
                <span *ngIf="investment.purchasePrice < 10000">{{ investment.purchasePrice | formatCurrency }}</span>
                <app-popover
                  [visible]="numberTipOpen['completepurchasePrice' + investment.id]"
                  (visibleChange)="numberTipOpen['completepurchasePrice' + investment.id] = $event"
                  ><div class="numberTooltip">
                    {{ investment.purchasePrice | currency }}
                  </div>
                </app-popover>
              </td>
              <td>
                <p *ngIf="investment.profile">
                  {{ investment.profile.legalName }}
                </p>
                <p *ngIf="!investment.profile">- -</p>
              </td>
              <td class="td--center">
                <button type="button" class="iconButton table__iconButton" (click)="clickDownloadDocs(investment)">
                  <i class="fa fa-download"></i>
                </button>
              </td>
              <!-- <td class="td--center">
                <span class="capitalize">{{ investment.deal.status }}</span>
              </td>
              <td style="width: 200px">
                <p class="my__fineprint fineprint" *ngIf="investment.deal.status === 'open'">
                  This deal is still open. You can’t edit this investment, but you could make an addition investment
                  using a different investment profile.
                </p>
              </td> -->
            </tr>
          </tbody>
        </table>
      </div>
      <!--/.tableWrap__inner-->
    </div>
    <!--/.tableWrap-->
  </ng-container>
</div>
<!--/.ssContainer-->
