import gql from 'graphql-tag';

/**
 * Queries
 **/

export const usersQuery = gql`
  query users($before: String, $after: String, $first: Int, $last: Int, $isInvestor: Boolean) {
    users(before: $before, after: $after, first: $first, last: $last, isInvestor: $isInvestor) {
      pageInfo
      edges {
        node {
          id
          pk
          email
          firstName
          lastName
          image {
            name
            url
          }
          preferredName
          gender
          phone
          isAdmin
          dateJoined
          archived
          verified
        }
        cursor
      }
    }
  }
`;

export const userQuery = gql`
  query user($id: ID!) {
    user {
      id
      pk
      email
      firstName
      lastName
      image {
        name
        url
      }
      preferredName
      gender
      phone
      isAdmin
      dateJoined
      archived
      verified
    }
  }
`;

export const meQuery = gql`
  query {
    me {
      id
      pk
      email
      firstName
      lastName
      image {
        name
        url
      }
      preferredName
      gender
      phone
      isAdmin
      dateJoined
      archived
      verified
    }
  }
`;

/**
 * Mutations
 **/

export const registerMutation = gql`
  mutation register(
    $email: String!
    $firstName: String!
    $lastName: String!
    $phone: String!
    $password1: String!
    $password2: String!
  ) {
    register(
      email: $email
      firstName: $firstName
      lastName: $lastName
      phone: $phone
      password1: $password1
      password2: $password2
    ) {
      success
      errors
    }
  }
`;

export const verifyAccountMutation = gql`
  mutation verifyAccount($token: String!) {
    verifyAccount(token: $token) {
      success
      errors
    }
  }
`;

export const resendActivationEmailMutation = gql`
  mutation resendActivationEmail($email: String!) {
    resendActivationEmail(email: $email) {
      success
      errors
    }
  }
`;

export const sendPasswordResetEmailMutation = gql`
  mutation sendPasswordResetEmail($email: String!) {
    sendPasswordResetEmail(email: $email) {
      success
      errors
    }
  }
`;

export const passwordResetMutation = gql`
  mutation passwordReset($token: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordReset(token: $token, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
    }
  }
`;

export const passwordChangeMutation = gql`
  mutation passwordChange($oldPassword: String!, $newPassword1: String!, $newPassword2: String!) {
    passwordChange(oldPassword: $oldPassword, newPassword1: $newPassword1, newPassword2: $newPassword2) {
      success
      errors
      refreshToken
      token
    }
  }
`;

export const updateAccountMutation = gql`
  mutation updateAccount($firstName: String, $lastName: String, $phone: String) {
    updateAccount(firstName: $firstName, lastName: $lastName, phone: $phone) {
      success
    }
  }
`;

export const deleteAccountMutation = gql`
  mutation deleteAccount($password: String!) {
    deleteAccount(password: $password) {
      success
      errors
    }
  }
`;

export const tokenAuthMutation = gql`
  mutation tokenAuth($email: String!, $password: String!) {
    tokenAuth(email: $email, password: $password) {
      token
      success
      errors
      user {
        id
        pk
        email
        firstName
        lastName
        image {
          name
          url
        }
        preferredName
        gender
        phone
        isAdmin
        dateJoined
        archived
        verified
      }
      unarchiving
      refreshToken
    }
  }
`;

export const verifyTokenMutation = gql`
  mutation verifyToken($token: String!) {
    verifyToken(token: $token) {
      payload
      success
      errors
    }
  }
`;

export const refreshTokenMutation = gql`
  mutation refreshToken($refreshToken: String!) {
    refreshToken(refreshToken: $refreshToken) {
      token
      payload
      success
      errors
      refreshToken
    }
  }
`;

export const revokeTokenMutation = gql`
  mutation revokeToken($refreshToken: String!) {
    revokeToken(refreshToken: $refreshToken) {
      revoked
      success
      errors
    }
  }
`;
