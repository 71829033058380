import { Component, OnInit, OnDestroy } from '@angular/core';
import gql from 'graphql-tag';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AuthService, GQLService } from '../../services';

export const contactUsMutation = gql`
  mutation contactUs(
    $company: String
    $email: Email!
    $message: String!
    $name: String!
    $phone: String!
    $subject: String!
    $subscribeToNewsletter: Boolean
  ) {
    contactUs(
      company: $company
      email: $email
      message: $message
      name: $name
      phone: $phone
      subject: $subject
      subscribeToNewsletter: $subscribeToNewsletter
    ) {
      success
      errors
    }
  }
`;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.modal.html',
  styleUrls: ['./contact.modal.scss'],
})
export class ContactModalComponent implements OnInit, OnDestroy {
  public user = null;

  public name = '';
  public email = '';
  public phone = '';
  public company = '';
  public subject = '';
  public message = '';
  public subscribeToNewsletter = false;

  public submittingForm = false;
  public error = '';
  public contactFormSent = false;

  private authSub = null;

  public constructor(
    public modal: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gqlService: GQLService
  ) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      if (this.user) {
        this.name = `${this.user.firstName} ${this.user.lastName}`;
        this.email = this.user.email;
        this.phone = this.user.phone;
      }
    });
  }

  public ngOnDestroy() {}

  public submitDisabled() {
    let nameIsSpaces = this.name.trim().length === 0;
    let emailIsSpaces = this.email.trim().length === 0;
    let phoneIsSpaces = this.phone.trim().length === 0;
    let subjectIsSpaces = this.subject.trim().length === 0;
    let messageIsSpaces = this.message.trim().length === 0;
    return (
      this.submittingForm ||
      !this.name ||
      !this.email ||
      !this.phone ||
      !this.subject ||
      !this.message ||
      nameIsSpaces ||
      emailIsSpaces ||
      phoneIsSpaces ||
      subjectIsSpaces ||
      messageIsSpaces
    );
  }

  public submitContactForm() {
    this.submittingForm = true;
    let data: any = {
      name: this.name,
      email: this.email,
      phone: this.phone,
      subject: this.subject,
      message: this.message,
    };
    if (this.company && this.company.trim().length > 0) {
      data.company = this.company;
    }
    this.gqlService
      .mutation(contactUsMutation, data)
      .then(() => {
        this.submittingForm = false;
        this.contactFormSent = true;
      })
      .catch((errs: any) => {
        this.submittingForm = false;
        this.toast.gqlErrors(errs);
      });
  }
}
