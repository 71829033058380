import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { AuthService, GQLService } from '../../../services';
import * as adminQueries from '../../../queries/admin-queries';

@Component({
  selector: 'app-deal-detail',
  templateUrl: './deal-detail.component.html',
  styleUrls: ['./deal-detail.component.scss'],
})
export class DealDetailComponent implements OnDestroy, OnInit {
  public environment = environment;
  public moment = moment;

  public user = null;
  public userLoaded = false;
  public dealSlug = null;
  public deal = null;
  public dealLoaded = false;
  public documents = [];

  public selectedTab = 'pledged';

  private authSub = null;
  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.dealSlug = params.slug;
      this.userLoaded = false;
      this.authSub = this.auth.user$.subscribe(
        (user) => {
          if (!user) {
            return;
          }
          this.user = user;
          if (!this.user.isAdmin) {
            this.router.navigate(['/']);
          }
          this.userLoaded = true;
          this.gql
            .query(adminQueries.dealQuery, { slug: this.dealSlug })
            .then((query: any) => {
              this.dealLoaded = true;
              this.deal = query.data.deal;
              this.documents = this.deal.documentTemplates.edges.map((obj: any) => {
                return obj.node;
              });
            })
            .catch(() => {
              this.router.navigate(['/']);
            });
        },
        () => {
          this.router.navigate(['/']);
        },
        () => {}
      );
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public investmentShortId(investment) {
    if (!investment || !investment.pk) {
      return '';
    }
    return investment.pk.substring(0, 8);
  }

  public get deadlineMoment() {
    return moment(this.deal.dealine);
  }

  public get shownInvestments() {
    switch (this.selectedTab) {
      case 'pledged':
        return this.deal.pledgedInvestments.edges.map((obj: any) => {
          return obj.node;
        });
      case 'legal':
        return this.deal.legalInvestments.edges.map((obj: any) => {
          return obj.node;
        });
      case 'finalize':
        return this.deal.finalizeInvestments.edges.map((obj: any) => {
          return obj.node;
        });
      case 'payment':
        return this.deal.paymentInvestments.edges.map((obj: any) => {
          return obj.node;
        });
      case 'funded':
        return this.deal.fundedInvestments.edges.map((obj: any) => {
          return obj.node;
        });
      default:
        return this.deal.pledgedInvestments.edges.map((obj: any) => {
          return obj.node;
        });
    }
  }
}
