<div class="modalGuts">
  <h1 class="modalGuts__h1">Change Password</h1>
  <form class="form" (submit)="changePassword()">
    <input
      class="input"
      type="password"
      id="oldPassword"
      name="oldPassword"
      placeholder="Current Password"
      [(ngModel)]="oldPassword"
    />
    <input
      class="input"
      type="password"
      id="newPassword1"
      name="newPassword1"
      placeholder="New Password"
      [(ngModel)]="newPassword1"
    />
    <input
      class="input"
      type="password"
      id="newPassword2"
      name="newPassword2"
      placeholder="New Password (Again)"
      [(ngModel)]="newPassword2"
    />
    <h6 class="hint">Passwords must be at least 12 characters, with at least one number, at least one letter, and at least one symbol.</h6>
    <p class="error" *ngIf="error">{{ error }}</p>
    <button class="button" type="submit" [disabled]="changePasswordDisabled()">Change Password</button>
  </form>
</div>
