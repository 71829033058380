import { Component, OnInit } from '@angular/core';
import { ModalService } from '../../modules/modals';

@Component({
  selector: 'app-thanks',
  templateUrl: './thanks.modal.html',
  styleUrls: ['./thanks.modal.scss'],
})
export class ThanksModalComponent implements OnInit {
  public data = null;
  public dataDefaults = {};

  public constructor(public modal: ModalService) {}

  public ngOnInit() {
    if (!this.data || !this.data.deal) {
      return;
    }
  }
}
