<div class="banner">
  <h3>New Deal!</h3>
  <div class="banner__deal">
    <img src="{{ deal?.logo.url }}" class="banner__logo" />
    <h4>{{ deal?.name }}</h4>
  </div>
  <button class="button banner__button" (click)="routeToDeal()">View&nbsp;Deal</button>
  <button class="iconButton banner__close" (click)="banners.dismiss(deal.pk)">
    <i class="icon-close"></i>
  </button>
</div>
