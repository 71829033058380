<div
  class="popover"
  [hidden]="!visible"
  [ngStyle]="{
    animation: options.animation,
    background: options.background,
    border: options.border,
    'border-radius': options.borderRadius,
    'font-family': options.fontFamily,
    top: options.relativeTop,
    left: options.relativeLeft,
    bottom: options.relativeBottom,
    right: options.relativeRight,
    width: options.width,
    'z-index': options.zIndex
  }"
>
  <div class="popover__content">
    <ng-content></ng-content>
  </div>
</div>
<div class="popover__overlay" *ngIf="visible" (click)="close()"></div>
