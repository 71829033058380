<div class="ssContainer">
  <h1>Forgot Password?</h1>
  <div class="centerPage">
    <!-- Forgot password form -->
    <ng-container *ngIf="!showResetFinished">
      <form (submit)="sendLink()">
        <p class="centerPage__instructions">Don't worry, we'll send you a recovery link.</p>
        <input
          class="input centerPage__input"
          [ngClass]="{ 'input--error': !!error }"
          type="email"
          id="email"
          name="email"
          placeholder="Email"
          [(ngModel)]="email"
        />
        <p class="error" *ngIf="error">{{ error }}</p>
        <button type="submit" class="button centerPage__button" [disabled]="!email">Send Recovery Link</button>
      </form>
      <a [routerLink]="['/login']" class="centerPage__link buttonText">Back to Login</a>
    </ng-container>
    <!-- Shows after completing form -->
    <ng-container *ngIf="showResetFinished">
      <p class="centerPage__instructions">Please check your email for a password reset link.</p>
    </ng-container>
  </div>
</div>
