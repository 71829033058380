import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-forgot-password',
  templateUrl: 'forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent {
  public email = '';

  public error = '';
  public showResetFinished = false;

  constructor(private router: Router, private auth: AuthService) {}

  public backToLogin() {
    this.router.navigate(['/login']);
  }

  public sendLink() {
    this.error = '';
    this.auth
      .sendPasswordResetEmail(this.email)
      .then(() => {
        this.showResetFinished = true;
      })
      .catch((errors) => {
        if (errors.length > 0) {
          this.error = errors[0].message;
        }
      });
  }
}
