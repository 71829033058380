import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { AuthService, GQLService } from '../../../services';
import * as adminQueries from '../../../queries/admin-queries';

@Component({
  selector: 'app-investor-list',
  templateUrl: './investor-list.component.html',
  styleUrls: ['./investor-list.component.scss'],
})
export class InvestorListComponent implements OnDestroy, OnInit {
  public user = null;
  public userLoaded = false;
  public investors = [];
  public investorsLoaded = false;
  public numberTipOpen = false;

  private authSub = null;

  constructor(
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.userLoaded = false;
    this.authSub = this.auth.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      if (!this.user.isAdmin) {
        this.router.navigate(['/']);
      }
      this.userLoaded = true;
      this.gql
        .query(adminQueries.investorsQuery)
        .then((query: any) => {
          this.investors = query.data.users.edges.map((obj) => obj.node);
          this.investorsLoaded = true;
          console.log(this.investors);
        })
        .catch((errs: any) => {
          this.toast.gqlErrors(errs);
        })
        .finally(() => {});
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
