import gql from 'graphql-tag';

/**
 * Queries
 **/

export const transactionLimitsQuery = gql`
  query {
    transactionLimits {
      personal
      entity
    }
  }
`;

/**
 * Mutations
 **/

export const pledgeAmountMutation = gql`
  mutation pledgeAmount($deal: ID!, $amount: Decimal!) {
    pledgeAmount(deal: $deal, amount: $amount) {
      investment {
        pk
        investor {
          id
          pk
          email
        }
        deal {
          id
          pk
          slug
        }
        amount
        dealDocuments {
          edges {
            node {
              id
              template {
                name
              }
            }
          }
        }
      }
    }
  }
`;

export const selectInvestmentProfileMutation = gql`
  mutation selectInvestmentProfile($amount: Decimal!, $id: ID!, $profile: ID!) {
    selectInvestmentProfile(amount: $amount, id: $id, profile: $profile) {
      investment {
        pk
      }
    }
  }
`;

export const createFundingSourceMutation = gql`
  mutation createFundingSource($accountId: String!, $name: String!, $profile: ID!, $publicToken: String!) {
    createFundingSource(accountId: $accountId, name: $name, profile: $profile, publicToken: $publicToken) {
      success
    }
  }
`;

export const selectPaymentMethodMutation = gql`
  mutation selectPaymentMethod($id: ID!, $paymentType: String!) {
    selectPaymentMethod(id: $id, paymentType: $paymentType) {
      investment {
        pk
      }
    }
  }
`;

export const signDocumentMutation = gql`
  mutation signDocument($template: ID!, $investment: ID!) {
    signDocument(template: $template, investment: $investment) {
      document {
        id
        pk
        template {
          id
          name
        }
        investment {
          investor {
            pk
            email
          }
        }
        datetimeSubmitted
      }
    }
  }
`;

export const initiateAchTransferMutation = gql`
  mutation initiateAchTransfer($fundingSource: ID!, $id: ID!) {
    initiateAchTransfer(fundingSource: $fundingSource, id: $id) {
      investment {
        pk
      }
    }
  }
`;

export const sendWireInfoEmailMutation = gql`
  mutation sendWireInfoEmail($id: ID!) {
    emailWireInfo(id: $id) {
      success
    }
  }
`;

export const syncDealDocumentMutation = gql`
  mutation syncDealDocument($signnowId: ID!) {
    syncDealDocument(signnowId: $signnowId) {
      dealDocument {
        id
      }
    }
  }
`;
