import { Component, EventEmitter, HostBinding, HostListener, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html',
  styleUrls: ['./file-upload.component.scss'],
})
export class FileUploadComponent implements OnInit {
  public files: FileList | undefined;

  @HostBinding('class.fileOver') fileOver: boolean;
  @Input() label: string = 'Upload File';
  @Output() fileChange = new EventEmitter<FileList>();

  constructor() {}

  ngOnInit(): void {}

  @HostListener('dragover', ['$event']) onDragOver(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = true;
  }

  @HostListener('dragleave', ['$event']) onDragLeave(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
  }

  @HostListener('drop', ['$event']) onDrop(event: any) {
    event.preventDefault();
    event.stopPropagation();
    this.fileOver = false;
    this.files = event.dataTransfer.files;
    if (this.files.length > 0) {
      this.fileChange.emit(this.files);
    }
  }

  public onFileChange(event: any) {
    this.files = event.target.files;
    if (this.files.length > 0) {
      this.fileChange.emit(this.files);
    }
  }
}
