import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../../modules/toast';
import { AuthService, GQLService } from '../../../services';
import { AppConfig } from '../../../app.config';
import * as adminQueries from '../../../queries/admin-queries';

@Component({
  selector: 'app-investor-detail',
  templateUrl: './investor-detail.component.html',
  styleUrls: ['./investor-detail.component.scss'],
})
export class InvestorDetailComponent implements OnDestroy, OnInit {
  public environment = environment;
  public moment = moment;

  public user = null;
  public userLoaded = false;
  public investor = null;
  public investorLoaded = false;
  public numberTipOpen = false;

  private authSub = null;
  private routerSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.routerSub = this.route.params.subscribe((params) => {
      this.userLoaded = false;
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        if (!this.user.isAdmin) {
          this.router.navigate(['/']);
        }
        this.userLoaded = true;
        this.gql
          .query(adminQueries.investorQuery, {
            id: params.id,
          })
          .then((query: any) => {
            this.investor = query.data.user;
            this.investorLoaded = true;
            console.log(this.investor);
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          })
          .finally(() => {});
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
  }

  public get personalProfiles() {
    return this.investor.investmentProfiles.filter((profile) => {
      return profile.profileType === 'person' && profile.isActive;
    });
  }

  public get entityProfiles() {
    return this.investor.investmentProfiles.filter((profile) => {
      return profile.profileType === 'entity' && profile.isActive;
    });
  }

  public get deactivatedProfiles() {
    return this.investor.investmentProfiles.filter((profile) => {
      return !profile.isActive;
    });
  }

  public clickDownloadDocs(investment: any) {
    investment.node.dealDocuments.edges.forEach((document: any) => {
      window.open(AppConfig.documentDownloadUrl(document.node.signnowId), document.node.signnowId);
    });
  }
}
