import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Injectable()
export class GQLService {
  constructor(private apollo: Apollo) {}

  private formatMessage(message) {
    return message.replace(/\['/g, '').replace(/\']/g, '').replace(/\["/g, '').replace(/"\]/g, '');
  }

  private errorsArray(e: any) {
    // Errors can be either networkError or graphQLErrors and can be returned three different ways:
    // 1. e.networkError.error.errors (ARRAY)
    // 2. e.networkError.error.errors (OBJECT WHERE KEY VALUES ARE ARRAYS)
    // 3. e.graphQLErrors (ARRAY)
    // This function removes the need to check for each of those on every request and returns a flat list of errors.
    let errors = [];
    if (e.networkError) {
      if (e.networkError && !e.networkError.error) {
        errors.push(e.networkError);
      } else if (e.networkError.error && !e.networkError.error.errors) {
        errors.push(e.networkError);
      } else if (e.networkError.error && e.networkError.error.errors) {
        let networkErrors = e.networkError.error.errors;
        if (Array.isArray(networkErrors)) {
          networkErrors.forEach((error: any) => {
            if (error.message) {
              error.message = this.formatMessage(error.message);
            }
            errors.push(error);
          });
        } else {
          Object.keys(networkErrors).forEach((key) => {
            if (networkErrors[key] && networkErrors[key].length > 0) {
              networkErrors[key].forEach((error: any) => {
                if (error.message) {
                  error.message = this.formatMessage(error.message);
                }
                errors.push(error);
              });
            }
          });
        }
      }
    }
    if (e.graphQLErrors) {
      let gqlErrors = e.graphQLErrors;
      if (Array.isArray(gqlErrors)) {
        gqlErrors.forEach((error: any) => {
          if (error.message) {
            error.message = this.formatMessage(error.message);
          }
          errors.push(error);
        });
      }
    }
    return errors;
  }

  public query(query: any, variables = {}, cache = false) {
    return new Promise((resolve, reject) => {
      let options: any = {
        query,
        variables,
      };
      if (!cache) {
        options.fetchPolicy = 'no-cache';
      }
      let querySub = this.apollo.query(options).subscribe(
        (data: any) => resolve(data),
        (err: any) => reject(this.errorsArray(err)),
        () => querySub.unsubscribe()
      );
    });
  }

  public mutation(mutation: any, variables = {}) {
    return new Promise((resolve, reject) => {
      let options: any = {
        mutation,
        variables,
      };
      let querySub = this.apollo.mutate(options).subscribe(
        (data: any) => resolve(data),
        (err: any) => reject(this.errorsArray(err)),
        () => querySub.unsubscribe()
      );
    });
  }
}
