import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { AuthService, BannerService, GQLService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';
import * as investQueries from '../../../queries/invest-queries';
import * as profileQueries from '../../../queries/profile-queries';

@Component({
  selector: 'app-invest-details',
  templateUrl: './invest-details.component.html',
  styleUrls: ['./invest-details.component.scss'],
})
export class InvestDetailsComponent implements OnInit, OnDestroy {
  public user = null;
  public deal = null;
  public loadingDeal = true;
  public investAmount = 0;
  public pledgingAmount = false;

  private authSub = null;
  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private banners: BannerService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.gql
          .query(dealQueries.dealQuery, { slug: params.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            if (this.myInvestment) {
              if (this.myInvestment.stage === 'Provide Details') {
                this.router.navigate(['/invest', this.deal.slug, 'profile']);
              }
              if (this.myInvestment.stage === 'Sign Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'docs']);
              }
              if (this.myInvestment.stage === 'Finalize Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'finalize']);
              }
              if (this.myInvestment.stage === 'Initiate Transfer') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
              if (this.myInvestment.stage === 'Transfer Pending') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
            }
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          })
          .finally(() => {
            this.loadingDeal = false;
          });
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public get myInvestment() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }

  public pledgeDisabled() {
    return !this.deal || !this.investAmount || this.pledgingAmount;
  }

  public pledgeAmount() {
    this.pledgingAmount = true;
    this.gql
      .mutation(investQueries.pledgeAmountMutation, {
        deal: this.deal.pk,
        amount: this.investAmount,
      })
      .then(() => {
        this.gql
          .query(profileQueries.myInvestmentsQuery, {
            status: 'processing',
          })
          .then((query: any) => {
            let inProcessInvestments = query.data.me.investments.edges.map((obj: any) => {
              return obj.node;
            });
            this.banners.inProgressSubject$.next(inProcessInvestments);
          });
        this.router.navigate(['/invest', this.deal.slug, 'profile']);
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.pledgingAmount = false;
      });
  }
}
