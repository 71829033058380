import gql from 'graphql-tag';

export const dealsQuery = gql`
  query deals($before: String, $after: String, $first: Int, $last: Int, $dealType: String, $isPublished: Boolean) {
    deals(before: $before, after: $after, first: $first, last: $last, dealType: $dealType, isPublished: $isPublished) {
      edges {
        node {
          id
          pk
          slug
          created
          modified
          name
          logo {
            url
          }
          status
          minimumInvestment
          dealType
          deadline
          round
          roundSize
          vehicle
          cap
          hasProRataRights
          allocation
          carry
          setupCost
          publishDatetime
        }
      }
    }
  }
`;

export const availableDealsQuery = gql`
  query availableDeals(
    $before: String
    $after: String
    $first: Int
    $last: Int
    $dealType: String
    $isPublished: Boolean
  ) {
    availableDeals(
      before: $before
      after: $after
      first: $first
      last: $last
      dealType: $dealType
      isPublished: $isPublished
    ) {
      edges {
        node {
          id
          pk
          slug
          created
          modified
          name
          logo {
            name
            url
          }
          status
          minimumInvestment
          dealType
          deadline
          round
          roundSize
          vehicle
          cap
          hasProRataRights
          allocation
          carry
          setupCost
          publishDatetime
        }
      }
    }
  }
`;

export const dealQuery = gql`
  query deal($slug: String) {
    deal(slug: $slug) {
      id
      pk
      slug
      created
      modified
      name
      logo {
        name
        url
      }
      minimumInvestment
      maximumInvestment
      dealType
      deadline
      round
      roundSize
      vehicle
      leadInvestor
      amountAlreadyRaisedExternally
      cap
      hasProRataRights
      allocation
      carry
      setupCost
      introduction
      moreInfo
      video {
        name
        url
      }
      videoPreview {
        name
        url
      }
      pitchDeck {
        name
        url
      }
      publishDatetime
      unpublishDatetime
      amountLeft
      investments {
        edges {
          node {
            id
            pk
            created
            modified
            investor {
              id
              pk
              email
              firstName
              lastName
            }
            fundingSource {
              id
              name
            }
            profile {
              id
              legalName
              profileType
            }
            wireTransfer {
              id
              status
              referenceNumber
              wireDestination {
                bank {
                  name
                  routingNumber
                  swiftCode
                  phoneNumber
                }
                accountName
                accountNumber
              }
            }
            amount
            purchasePrice
            paymentType
            stage
            dealDocuments {
              edges {
                node {
                  id
                  pk
                  signnowId
                  template {
                    id
                    name
                    pk
                    signnowId
                  }
                  datetimeSubmitted
                }
              }
            }
          }
        }
      }
      meetAndGreetEvents {
        id
        name
        schedule
        role
        location
        url
      }
      documentTemplates {
        edges {
          node {
            id
            pk
            name
            templateFile {
              name
              url
            }
          }
        }
      }
    }
  }
`;

export const embeddedSigningLinksQuery = gql`
  query investment($id: ID!) {
    investment(id: $id) {
      dealDocuments {
        edges {
          node {
            id
            pk
            signnowId
            embeddedSigningLink
            template {
              id
              name
              pk
              signnowId
            }
            datetimeSubmitted
          }
        }
      }
    }
  }
`;

export const managerDealDocumentQuery = gql`
  query managerDealDocument($id: ID!) {
    managerDealDocument(id: $id) {
      id
      pk
      signnowId
      managerSigningLink
      template {
        id
        name
        pk
        signnowId
      }
      datetimeSubmitted
    }
  }
`;
