<div class="ssContainer" *ngIf="!userLoaded || !investorLoaded">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="userLoaded && investorLoaded">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/reports/investor-list']">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Investor List</span>
    </a>
    <h1>Investor Detail: {{ investor.firstName }} {{ investor.lastName }}</h1>
    <!-- TODO: Popovers for email and phone icons -->
    <div class="backWrapper__icons">
      <button class="iconButton">
        <i class="fa fa-envelope fa-fw"></i>
      </button>
      <button class="iconButton">
        <i class="fa fa-phone fa-fw"></i>
      </button>
    </div>
  </div>
  <!--/.backWrapper-->
  <!-- <p class="lastActivity">Last Activity: Signed Artifact docs. 12 days ago (June 22, 2020)</p> -->
  <div class="profiles" *ngIf="personalProfiles.length + entityProfiles.length > 0">
    <h5 class="profiles__header">Investment Profiles</h5>
    <div class="profiles__list">
      <div class="profiles__item" *ngFor="let profile of personalProfiles">
        <p>
          {{ profile.legalName
          }}<span *ngFor="let source of profile.fundingSources"
            ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
          >
        </p>
      </div>
      <div class="profiles__item" *ngFor="let profile of entityProfiles">
        <p>
          {{ profile.legalName
          }}<span *ngFor="let source of profile.fundingSources"
            ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
          >
        </p>
      </div>
    </div>
  </div>
  <div class="profiles" *ngIf="deactivatedProfiles.length > 0">
    <h5 class="profiles__header">Deactivated Profiles</h5>
    <div class="profiles__list">
      <div class="profiles__item profiles__item--deactivated" *ngFor="let profile of deactivatedProfiles">
        <p>
          {{ profile.legalName
          }}<span *ngFor="let source of profile.fundingSources"
            ><br /><span class="capitalize">{{ source.name.toLowerCase() }}</span></span
          >
        </p>
      </div>
    </div>
  </div>
  <h2>Investments In Process</h2>
  <div *ngIf="investor.inProcessInvestments.edges.length < 1">
    <p class="empty">Investor has no in process investments.</p>
  </div>
  <div class="tableWrap" *ngIf="investor.inProcessInvestments.edges.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr>
            <th class="td--left">Deal</th>
            <th class="td--right">Amount</th>
            <th class="td--left">Investment Profile</th>
            <th class="td--center">Docs</th>
            <!-- <th class="td--left th--sortAZ">Closes</th> -->
            <th class="td--left">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let investment of investor.inProcessInvestments.edges">
            <td class="td--left">
              <a class="table__flex">
                <img src="{{ investment.node.deal.logo.url }}" class="table__logo" />
                <span class="table__link">{{ investment.node.deal.name }}</span>
              </a>
            </td>
            <td class="td--right">{{ investment.node.amount | formatCurrency }}</td>
            <td class="td--left">
              <p *ngIf="investment.node.profile">
                {{ investment.node.profile.legalName }}
              </p>
              <p *ngIf="!investment.node.profile">- -</p>
            </td>
            <td class="td--center">
              <ng-container
                *ngIf="investment.node.stage === 'Initiate Transfer' || investment.node.stage === 'Transfer Pending'"
              >
                <button type="button" class="iconButton table__iconButton" (click)="clickDownloadDocs(investment)">
                  <i class="fa fa-download"></i>
                </button>
              </ng-container>
              <ng-container
                *ngIf="investment.node.stage !== 'Initiate Transfer' && investment.node.stage !== 'Transfer Pending'"
                >- -
              </ng-container>
            </td>
            <!-- <td class="td--left">{{ moment(investment.node.deal.deadline).format('MM/DD/YY') }}</td> -->
            <td class="td--left">{{ investment.node.stage }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
  <h2>Completed Investments</h2>
  <div *ngIf="investor.completedInvestments.edges.length < 1">
    <p class="empty">Investor has no completed investments.</p>
  </div>
  <div class="tableWrap" *ngIf="investor.completedInvestments.edges.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr>
            <th class="td--left">Deal</th>
            <th class="td--right">Amount</th>
            <th class="td--left">Investment Profile</th>
            <th class="td--center">Docs</th>
            <th class="td--left th--sortAZ">Status</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let investment of investor.completedInvestments">
            <td class="td--left">
              <a class="table__flex">
                <img src="{{ investment.node.deal.logo.url }}" class="table__logo" />
                <span class="table__link">{{ investment.node.deal.name }}</span>
              </a>
            </td>
            <td class="td--right">{{ investment.node.amount | formatCurrency }}</td>
            <td class="td--left">
              <p *ngIf="investment.node.profile">
                {{ investment.node.profile.legalName }}
              </p>
              <p *ngIf="!investment.node.profile">- -</p>
            </td>
            <td class="td--center">
              <button type="button" class="iconButton table__iconButton" (click)="clickDownloadDocs(investment)">
                <i class="fa fa-download"></i>
              </button>
            </td>
            <td class="td--left">
              <span class="capitalize">{{ investment.node.deal.status }}</span>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
  <h2>Investment Activities</h2>
  <div *ngIf="investor.investmentActivities.length < 1">
    <p class="empty">Investor has no investment activites.</p>
  </div>
  <div class="tableWrap" *ngIf="investor.investmentActivities.length > 0">
    <div class="tableWrap__inner">
      <table>
        <tbody>
          <tr *ngFor="let activity of investor.investmentActivities">
            <td>
              <a class="table__flex">
                <img src="{{ activity.investment.deal.logo.url }}" class="table__logo" />
                <span class="table__link">{{ activity.investment.deal.name }}</span>
              </a>
            </td>
            <td>{{ activity.message }}</td>
            <td>
              <p class="fineprint fineprint--right">
                {{ moment(activity.created).fromNow() }} ({{
                  moment(activity.created).format('MMM D, YYYY • hh:mm A')
                }})
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
</div>
