<div class="loadingBlur">
  <div class="spinnerContainer" *ngIf="!syncSuccess">
    <h3>Just a Moment...</h3>
    <p>Processing your document. Please wait.</p>
    <div class="ssSpinner"></div>
    <div class="spinnerContainer__buttons">
      <button class="button" [disabled]="!retryEnabled" (click)="syncApplicationDocument()">Retry</button>
      <button class="button" [disabled]="true">Next</button>
    </div>
  </div>
  <div class="spinnerContainer" *ngIf="syncSuccess">
    <h3>Success!</h3>
    <p>Document retrieved!</p>
    <i class="fa fa-check"></i>
    <div class="spinnerContainer__buttons">
      <button class="button" [disabled]="true">Retry</button>
      <button class="button" (click)="clickClose()">Next</button>
    </div>
  </div>
</div>
