import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'toNow' })
export class ToNowPipe implements PipeTransform {
  transform(value: any) {
    let m = moment(value);
    let diffHours = m.diff(moment(), 'hours');
    let diffMinutes = m.diff(moment(), 'minutes');
    let diffSeconds = m.diff(moment(), 'seconds');
    if (diffHours <= 72 && diffHours >= 25) {
      return diffHours + ' hours';
    }
    if (diffHours <= 24 && diffHours >= 1) {
      return diffHours + ' hours, ' + (diffMinutes - diffHours * 60) + ' minutes';
    }
    if (diffMinutes <= 60 && diffMinutes >= 1) {
      return diffMinutes + ' minutes';
    }
    if (diffSeconds <= 60 && diffSeconds >= 31) {
      return 'a minute';
    }
    if (diffSeconds <= 31 && diffSeconds >= 0) {
      return 'a few seconds';
    }
    return m.fromNow().replace(/in /g, '');
  }
}
