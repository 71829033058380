import { ElementRef, Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { ToastService } from '../../../modules/toast';
import { AuthService, BannerService, GQLService, LocalStorageService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';

@Component({
  selector: 'app-deal',
  templateUrl: './deal.component.html',
  styleUrls: ['./deal.component.scss'],
})
export class DealComponent implements OnInit, OnDestroy {
  public environment = environment;
  public moment = moment;

  public user = null;
  public deal = null;
  public loadingDeal = true;
  public documents = [];
  public meetings = [];
  public dismissConfidential = [];
  public showAction = true;

  public setupCostTooltipOpen = false;
  public capTooltipOpen = false;
  public setupCostTooltipOptions = {
    relativeLeft: '-140px',
    relativeTop: '1.5rem',
    width: '280px',
  };

  @ViewChild('videoPlayer')
  public videoPlayer: ElementRef;
  public videoPlaying = false;

  private authSub = null;
  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private banners: BannerService,
    private gql: GQLService,
    private localStorage: LocalStorageService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        if (this.localStorage.getObj('dismissConfidential')) {
          this.dismissConfidential = this.localStorage.getObj('dismissConfidential');
        }
        this.gql
          .query(dealQueries.dealQuery, { slug: params.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            this.banners.dismiss(this.deal.pk);
            if (this.deal.documentTemplates.edges.length > 0) {
              this.documents = this.deal.documentTemplates.edges.map((obj: any) => {
                return obj.node;
              });
            }
            this.meetings = this.deal.meetAndGreetEvents;
          })
          .catch((errs: any) => {
            this.router.navigate(['/error']);
            // this.toast.gqlErrors(errs);
          })
          .finally(() => {
            this.loadingDeal = false;
          });
      });
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public removeUnderscore(str: string) {
    return str.split('_').join(' ');
  }

  public closeConfidential() {
    this.dismissConfidential.push(this.deal.pk);
    this.localStorage.setObj('dismissConfidential', this.dismissConfidential);
  }

  public playVideo() {
    if (!this.videoPlayer) {
      return;
    }
    this.videoPlayer.nativeElement.play();
    this.videoPlayer.nativeElement.setAttribute('controls', 'controls');
    this.videoPlaying = true;
  }

  public alreadyRaisedPercent() {
    let size = this.deal.roundSize;
    let raised = this.deal.amountAlreadyRaisedExternally;
    let percent = (raised / size) * 100;
    if (percent >= 100) {
      return 100;
    }
    return Math.round(percent);
  }

  public daysDiff(date) {
    let now = moment();
    return Math.abs(now.diff(moment(date), 'days'));
  }

  public get myInvestment() {
    if (!this.deal) {
      return null;
    }
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }

  public get isPublished() {
    return this.deal && this.deal.publishDatetime;
  }

  public get hasVideo() {
    return this.deal && this.deal.video && this.deal.video.url;
  }

  public get hasVideoPreview() {
    return this.deal && this.deal.videoPreview && this.deal.videoPreview.url;
  }

  public get hasDocumentTemplates() {
    return this.documents.length > 0;
  }

  public get showConfidential() {
    if (!this.deal) {
      return false;
    }
    return !this.dismissConfidential.includes(this.deal.pk);
  }

  public get investButtonText() {
    if (this.myInvestment) {
      if (this.myInvestment.stage === 'Provide Details') {
        return 'Next Step: Provide Details';
      } else if (this.myInvestment.stage === 'Sign Docs') {
        return 'Next Step: Sign Docs';
      } else if (this.myInvestment.stage === 'Finalize Docs') {
        return 'Next Step: Finalize Docs';
      } else if (this.myInvestment.stage === 'Initiate Transfer') {
        return 'Next Step: Initiate Transfer';
      }
    }
    return 'Start Investment';
  }

  public get investButtonLink() {
    if (this.myInvestment) {
      if (this.myInvestment.stage === 'Provide Details') {
        return ['/invest', this.deal.slug, 'profile'];
      } else if (this.myInvestment.stage === 'Sign Docs') {
        return ['/invest', this.deal.slug, 'docs'];
      } else if (this.myInvestment.stage === 'Finalize Docs') {
        return ['/invest', this.deal.slug, 'finalize'];
      } else if (this.myInvestment.stage === 'Initiate Transfer') {
        return ['/invest', this.deal.slug, 'payment'];
      }
    }
    return ['/invest', this.deal.slug, 'details'];
  }
}
