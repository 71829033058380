import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from '../../modules/toast';
import { createAcceleratorApplicationMutation, mentorsQuery } from '../../queries/accelerator-queries';
import { GQLService } from '../../services';

const PHONE_REGEXP = RegExp(/^[0-9]{3}-[0-9]{3}-[0-9]{4}$/);
const URL_REGEXP = RegExp(
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/
);

@Component({
  selector: 'app-accelerator',
  templateUrl: './accelerator.component.html',
  styleUrls: ['./accelerator.component.scss', './accelerator.component.responsive.scss'],
})
export class AcceleratorComponent implements OnInit {
  public applyForm = new FormGroup({
    founderName: new FormControl('', Validators.required),
    founderEmail: new FormControl('', [Validators.required, Validators.email]),
    founderPhone: new FormControl('', [Validators.required, Validators.pattern(PHONE_REGEXP)]),
    companyName: new FormControl('', Validators.required),
    companyWebsite: new FormControl('', [Validators.pattern(URL_REGEXP)]),
    companyDescription: new FormControl('', Validators.required),
    currentTraction: new FormControl('', Validators.required),
    acceleratorExpectations: new FormControl('', Validators.required),
    linkedinProfile: new FormControl('', [Validators.pattern(URL_REGEXP)]),
    pitchDeck: new FormControl(null),
  });
  // public applyForm = new FormGroup({
  //   founderName: new FormControl('Test', Validators.required),
  //   founderEmail: new FormControl('test@test.com', [Validators.required, Validators.email]),
  //   founderPhone: new FormControl('801-555-5555', [Validators.required, Validators.pattern(PHONE_REGEXP)]),
  //   companyName: new FormControl('Test', Validators.required),
  //   companyWebsite: new FormControl('https://google.com', [Validators.pattern(URL_REGEXP)]),
  //   companyDescription: new FormControl('Test', Validators.required),
  //   currentTraction: new FormControl('Test', Validators.required),
  //   acceleratorExpectations: new FormControl('Test', Validators.required),
  //   linkedinProfile: new FormControl('https://linkedin.com', [Validators.pattern(URL_REGEXP)]),
  //   pitchDeck: new FormControl(null),
  // });

  public mentors = [];

  public constructor(private toast: ToastService, private gql: GQLService, private cd: ChangeDetectorRef) {}

  public ngOnInit() {
    this.gql.query(mentorsQuery).then((res: any) => {
      this.mentors = res.data.mentors;
    });
  }

  public scrollIntoView(element: HTMLElement) {
    element.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  public onFileChange(files: FileList) {
    const reader = new FileReader();

    if (files[0]) {
      const file = files[0];

      reader.readAsDataURL(file);

      reader.onload = () => {
        let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');
        if (encoded.length % 4 > 0) {
          encoded += '='.repeat(4 - (encoded.length % 4));
        }
        this.applyForm.patchValue({
          pitchDeck: {
            file: encoded,
            name: file.name,
            type: file.type,
          },
        });
        this.cd.markForCheck();
      };

      reader.onerror = (error) => {
        this.toast.error(error.toString());
      };
    }
  }

  public submitApplyForm() {
    this.gql
      .mutation(createAcceleratorApplicationMutation, {
        application: this.applyForm.value,
      })
      .then(() => {
        this.applyForm.reset();
        this.toast.success('Your application has been submitted!');
      })
      .catch((err) => {
        if (err && err[0]) {
          this.toast.error(err[0].message);
        }
      });
  }
}
