import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../modules/modals';
import { PopoverOptions } from '../../modules/popover';
import { AuthService } from '../../services';
import { ContactModalComponent } from '../contact/contact.modal';

@Component({
  selector: 'app-nav',
  templateUrl: './nav.component.html',
  styleUrls: ['./nav.component.scss'],
})
export class NavComponent implements OnDestroy, OnInit {
  public environment = environment;
  public user = null;
  public adminMenuOpen = false;
  public mobileNavOpen = false;
  public userMenuOpen = false;

  public adminMenuOptions: PopoverOptions = {
    relativeTop: '120%',
  };

  public userMenuOptions: PopoverOptions = {
    relativeTop: '120%',
    relativeRight: '0',
    width: '120px',
  };

  private authSub: Subscription = null;

  constructor(private router: Router, private modals: ModalService, public auth: AuthService) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe(
      (user) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
      () => {},
      () => {}
    );
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public openMobileNav() {
    this.mobileNavOpen = true;
    document.querySelector('body').classList.add('noscroll');
  }

  public closeMobileNav() {
    this.mobileNavOpen = false;
    document.querySelector('body').classList.remove('noscroll');
  }

  public logout() {
    this.userMenuOpen = false;
    this.auth.logout();
    this.router.navigate(['/login']);
  }

  public openContactModal() {
    this.modals
      .open(ContactModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        width: '900px',
      })
      .subscribe();
  }
}
