import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../modules/modals';
import { AuthService } from '../../services';

@Component({
  selector: 'app-change-password',
  templateUrl: './change-password.modal.html',
  styleUrls: ['./change-password.modal.scss'],
})
export class ChangePasswordModalComponent implements OnDestroy, OnInit {
  public data = null;
  public dataDefaults = {};

  public oldPassword = '';
  public newPassword1 = '';
  public newPassword2 = '';

  public error = '';

  constructor(private modal: ModalService, private auth: AuthService) {}

  public ngOnInit() {}

  public ngOnDestroy() {}

  public changePasswordDisabled() {
    return !this.oldPassword || !this.newPassword1 || !this.newPassword2;
  }

  public changePassword() {
    this.error = '';
    this.auth
      .passwordChange(this.oldPassword, this.newPassword1, this.newPassword2)
      .then((passwordChange: any) => {
        let response = passwordChange.data.passwordChange;
        this.auth.setAuthToken(response.token);
        this.auth.setRefreshToken(response.refreshToken);
        this.modal.close(null);
      })
      .catch((errors: any) => {
        if (errors.length > 0) {
          this.error = errors[0].message;
        }
      });
  }
}
