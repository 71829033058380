import { Component } from '@angular/core';
import { ModalService } from '../../modules/modals';
import { ContactModalComponent } from '../contact/contact.modal';
import * as moment from 'moment';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss'],
})
export class FooterComponent {
  public date = moment();

  public accelFootTipOpen = false;

  constructor(private modals: ModalService) {}

  public openContactModal() {
    this.modals
      .open(ContactModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        width: '900px',
      })
      .subscribe();
  }
}
