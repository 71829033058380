<!-- Hero -->
<div class="ssContainer">
  <div class="hero">
    <img class="hero__img" src="../../assets/img/accelerator-hero.png" alt="Investing in Utah" />
    <button class="button hero__button" type="button" (click)="scrollIntoView(apply)">Apply</button>
    <div class="hero__social">
      <a href="https://www.linkedin.com/company/beehivevp" target="_blank" class="hero__link">
        <i class="icon-linkedin"></i>
      </a>
      <a href="https://twitter.com/BeehiveVP" target="_blank" class="hero__link">
        <i class="icon-twitter"></i>
      </a>
      <a href="https://www.instagram.com/beehivevc" target="_blank" class="hero__link">
        <i class="icon-instagram"></i>
      </a>
      <a href="https://www.facebook.com/BeehiveVP" target="_blank" class="hero__link">
        <i class="icon-facebook"></i>
      </a>
    </div>
  </div>
</div>

<!-- Partnership -->

<div class="partnership section section--blue">
  <div class="section__content">
    <img class="partnership__img" src="../../assets/img/silicon-slopes-logo-white.svg" />
    <div>
      <h1 class="partnership__title">In Partnership with <br class="hideOnDesktop" />Silicon Slopes</h1>
      <div class="partnership__divider"></div>
      <p class="partnership__quote">
        “We're excited to work alongside Beehive Venture Partners to accelerate early-stage startups in our community,
        facilitating connections between Utah's most promising founders and legendary local mentors. The Beehive
        Accelerator will help thriving new Utah companies become full-fledged unicorns.” - Clint Betts, CEO & President,
        Silicon Slopes
      </p>
    </div>
  </div>
</div>

<!-- About -->

<div class="about section section--yellow">
  <div class="section__content">
    <h1 class="section__title">The Accelerator</h1>
    <div class="section__divider"></div>
    <p class="section__description">
      The Beehive Accelerator is Utah's premier startup accelerator, designed to help early-stage companies thrive. Our
      founder-first accelerator surrounds entrepreneurs with the capital, mentorship, resources, network, and community
      they need to be successful.
    </p>
    <div class="about__grid">
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-people.svg" />
          </div>
          <div class="about__item__title">10-Week Program</div>
        </div>
        <p class="about__item__description">
          Participate in a structured program of workshops, seminars, and networking events to develop the skills,
          knowledge, and relationships needed for growth.
        </p>
      </div>
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-thumb-up.svg" />
          </div>
          <div class="about__item__title">Community Access</div>
        </div>
        <p class="about__item__description">
          Network with and be accountable to like-minded founders and potential investors who can help you establish
          partnerships, find customers, and secure funding.
        </p>
      </div>
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-dollar.svg" />
          </div>
          <div class="about__item__title">Up to $500k Investment</div>
        </div>
        <p class="about__item__description">
          Gain $250k of investment at founder-friendly terms from Beehive Venture Partners and grant a ROFR for another
          $250k as part of a subsequent round.
        </p>
      </div>
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-globe.svg" />
          </div>
          <div class="about__item__title">Advisors & Mentors</div>
        </div>
        <p class="about__item__description">
          Receive mentorship and guidance through direct exposure to legendary entrepreneurs and industry experts who
          can help founders with specific growth needs.
        </p>
      </div>
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-compass.svg" />
          </div>
          <div class="about__item__title">Investor Access</div>
        </div>
        <p class="about__item__description">
          Connect directly with world-class investors from across the country and enjoy opportunities to pitch and demo
          your products in-person.
        </p>
      </div>
      <div class="about__item">
        <div class="about__item__header">
          <div class="about__icon">
            <img src="../../assets/img/accelerator-gift.svg" />
          </div>
          <div class="about__item__title">Pretty Sweet Perks</div>
        </div>
        <p class="about__item__description">
          Enjoy over $1M worth of other perks and credits useful for growing your company, plus a free t-shirt to
          shamelessly advertise the Beehive Accelerator to everyone you know.
        </p>
      </div>
    </div>
  </div>
</div>

<!-- Mentorship -->
<div class="mentorship section">
  <div class="section__content">
    <h1 class="section__title">Mentorship</h1>
    <div class="section__divider"></div>
    <p class="section__description">
      The Beehive Accelerator provides expert mentorship to early-stage startups. Our network of experienced Utah CEOs,
      executives, and entrepreneurs offers guidance on various aspects of the startup journey to help founders achieve
      their company goals.
    </p>
    <div class="mentorship__grid">
      <div class="mentorship__grid__item" *ngFor="let mentor of mentors">
        <img [src]="mentor.image.url" [alt]="mentor.name" />
      </div>
    </div>
  </div>
</div>

<!-- Timeline -->

<div class="timeline section section--blue">
  <div class="section__content">
    <h1 class="section__title">2023 Program Timeline</h1>
    <div class="section__divider"></div>
    <div class="timeline__item">
      <img class="timeline__item__hex" src="../../assets/img/hexagon.svg" />
      <div class="timeline__item__line"></div>
      <div>
        <div class="timeline__item__date">May 30th</div>
        <div class="timeline__item__title">Applications Open</div>
        <div class="timeline__item__description">
          We encourage all eligible startups to apply as early as possible to increase their chances of being selected
          for the program.
        </div>
      </div>
    </div>
    <div class="timeline__item">
      <img class="timeline__item__hex" src="../../assets/img/hexagon.svg" />
      <div class="timeline__item__line"></div>
      <div>
        <div class="timeline__item__date">July 31st</div>
        <div class="timeline__item__title">Applications Close</div>
        <div class="timeline__item__description"></div>
      </div>
    </div>
    <div class="timeline__item">
      <img class="timeline__item__hex" src="../../assets/img/hexagon.svg" />
      <div class="timeline__item__line"></div>
      <div>
        <div class="timeline__item__date">August 16th</div>
        <div class="timeline__item__title">Announce Cohort</div>
        <div class="timeline__item__description">
          After a rigorous review process, the top applicants will be notified and invited to participate.
        </div>
      </div>
    </div>
    <div class="timeline__item">
      <img class="timeline__item__hex" src="../../assets/img/hexagon.svg" />
      <div class="timeline__item__line"></div>
      <div>
        <div class="timeline__item__date">August 30th</div>
        <div class="timeline__item__title">Orientation Day</div>
        <div class="timeline__item__description">
          This is the first meeting (orientation day) of our 10-week program. We’ll meet weekly on Wednesday mornings
          and have lunch with mentors. Additional optional outings and venture dinners with mentors and VCs happen as
          needed (about once per week).
        </div>
      </div>
    </div>
    <div class="timeline__item">
      <img class="timeline__item__hex" src="../../assets/img/hexagon.svg" />
      <div>
        <div class="timeline__item__date">November 17th</div>
        <div class="timeline__item__title">Demo Day / Beehive Bash</div>
        <div class="timeline__item__description">
          Demo day, also known as The Beehive Bash, is the culmination of our accelerator program and a chance for our
          startups to showcase their progress and achievements. During this event, each startup will have an opportunity
          to pitch their business to a group of investors, entrepreneurs, and other attendees.
        </div>
      </div>
    </div>
  </div>
</div>

<!-- FAQ -->

<div class="faq section section--yellow">
  <div class="section__content">
    <h1 class="section__title">Frequently Asked Questions</h1>
    <a href="https://beehivevc.notion.site/FAQ-5084839f87a440a28d7c05189f8f2812" target="_blank">
      <button class="button2">Got questions? We’ve got answers</button>
    </a>
  </div>
</div>

<!-- Apply -->

<div class="apply section" #apply>
  <div class="section__content">
    <h1 class="section__title">Apply</h1>
    <div class="section__divider"></div>
    <p class="section__description">Applying is crazy easy and takes only a couple minutes, so do it now!</p>
    <form class="apply__form" [formGroup]="applyForm" (ngSubmit)="submitApplyForm()">
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Founder Name</label>
          <input class="input" type="text" [formControlName]="'founderName'" />
        </div>
        <div class="apply__form__control">
          <label>Founder Email</label>
          <input class="input" type="email" [formControlName]="'founderEmail'" />
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Founder Phone Nmber</label>
          <input class="input" type="phone" pattern="^[0-9]{3}-[0-9]{3}-[0-9]{4}$" [formControlName]="'founderPhone'" />
        </div>
        <div class="apply__form__control">
          <label>LinkedIn URL</label>
          <input class="input" type="url" [formControlName]="'linkedinProfile'" />
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Company Name</label>
          <input class="input" type="text" [formControlName]="'companyName'" />
        </div>
        <div class="apply__form__control">
          <label>Company Website</label>
          <input class="input" type="url" [formControlName]="'companyWebsite'" />
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Company Summary Paragraph</label>
          <textarea class="input input--textarea" [formControlName]="'companyDescription'"></textarea>
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Current / Upcoming Traction</label>
          <textarea class="input input--textarea" [formControlName]="'currentTraction'"></textarea>
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>What do you hope to get out of the Beehive Accelerator?</label>
          <textarea class="input input--textarea" [formControlName]="'acceleratorExpectations'"></textarea>
        </div>
      </div>
      <div class="apply__form__row">
        <div class="apply__form__control">
          <label>Pitch Deck</label>
          <app-file-upload label="Upload Deck Here" (fileChange)="onFileChange($event)"></app-file-upload>
        </div>
      </div>
      <div class="apply__form__row">
        <button class="button apply__form__submit" type="submit" [disabled]="applyForm.invalid">
          Submit Application
        </button>
      </div>
    </form>
  </div>
</div>
