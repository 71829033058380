import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AuthService, GQLService } from '../../services';
import * as profileQueries from '../../queries/profile-queries';

@Component({
  selector: 'app-entity-profile',
  templateUrl: './entity-profile.modal.html',
  styleUrls: ['./entity-profile.modal.scss'],
})
export class EntityProfileModalComponent implements OnDestroy, OnInit {
  public data = null;
  public dataDefaults = {};

  public user = null;
  public businessClassifications = [];

  public ssn = '';
  public birthdate = '';
  public legalName = '';
  public businessType = '';
  public industryClassification = null;
  public ein = '';
  public address1 = '';
  public address2 = '';
  public city = '';
  public state = '';
  public postalCode = '';

  public clickedSubmit = false;

  private authSub = null;

  constructor(
    private modal: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.user = user;
    });
    if (this.data && this.data.profile) {
      let getSSN = this.gql
        .query(profileQueries.investmentProfileSSNQuery, {
          id: this.data.profile.id,
        })
        .then((query: any) => {
          this.ssn = '***-**-' + query.data.investmentProfile.ssnLast4;
        })
        .catch((errs) => {
          this.toast.gqlErrors(errs);
        });
      this.legalName = this.data.profile.legalName;
      this.businessType = this.data.profile.businessType;
      this.ein = this.data.profile.ein;
      this.address1 = this.data.profile.address1;
      this.address2 = this.data.profile.address2;
      this.city = this.data.profile.city;
      this.state = this.data.profile.state;
      this.postalCode = this.data.profile.postalCode;
      this.birthdate = moment(this.data.profile.birthdate).format('MM/DD/YYYY');
    }
    this.gql
      .query(profileQueries.businessClassificationsQuery)
      .then((query: any) => {
        this.businessClassifications = query.data.businessClassifications;
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public get isEditing() {
    return !!this.data && !!this.data.profile;
  }

  public formDisabled() {
    if (this.clickedSubmit) {
      return true;
    }
    return (
      !this.ssn ||
      !this.address1 ||
      !this.birthdate ||
      !this.businessType ||
      !this.legalName ||
      !this.industryClassification ||
      !this.ein ||
      !this.state ||
      !this.city ||
      !this.postalCode
    );
  }

  public create() {
    console.log(this.industryClassification);
    this.clickedSubmit = true;
    let birthdateReal = moment(this.birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    this.gql
      .mutation(profileQueries.createEntityProfileMutation, {
        address1: this.address1,
        address2: this.address2,
        birthdate: birthdateReal,
        businessType: this.businessType,
        city: this.city,
        ein: this.ein,
        industryClassification: this.industryClassification.id,
        industryClassificationName: this.industryClassification.name,
        legalName: this.legalName,
        postalCode: this.postalCode,
        ssn: this.ssn,
        state: this.state,
      })
      .then((profileMutation: any) => {
        this.modal.close(profileMutation.data.createEntityProfile.profile);
        this.clickedSubmit = false;
      })
      .catch((errs) => {
        this.toast.gqlErrors(errs);
        this.clickedSubmit = false;
      });
  }

  public update() {
    this.clickedSubmit = true;
    let birthdateReal = moment(this.birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    this.gql
      .mutation(profileQueries.updateEntityProfileMutation, {
        address1: this.address1,
        address2: this.address2,
        businessType: this.businessType,
        city: this.city,
        ein: this.ein,
        id: this.data.profile.id,
        legalName: this.legalName,
        postalCode: this.postalCode,
        state: this.state,
      })
      .then((profileMutation: any) => {
        this.modal.close(profileMutation.data.updateEntityProfile.profile);
        this.clickedSubmit = false;
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
        this.clickedSubmit = false;
      });
  }

  public createOrUpdate() {
    if (this.isEditing) {
      this.update();
    } else {
      this.create();
    }
  }
}
