<div
  class="tooltip__scrim"
  [ngClass]="{ 'tooltip__scrim--open': profileDropdownOpen }"
  (click)="profileDropdownOpen = false"
></div>
<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/deal', deal?.slug]">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal</span>
    </a>
    <h1 class="backWrapper__header">Invest in {{ deal?.name }}</h1>
  </div>
  <div class="centerPage">
    <div class="steps">
      <a class="steps__step steps__step--1 steps__step--done"></a>
      <div class="steps__line steps__line--1"></div>
      <a class="steps__step steps__step--2 steps__step--active"></a>
      <div class="steps__line steps__line--2"></div>
      <a class="steps__step steps__step--3 steps__step--disabled"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--4 steps__step--disabled"></a>
      <div class="steps__line steps__line--4"></div>
      <a class="steps__step steps__step--5 steps__step--disabled"></a>
    </div>
    <ng-container *ngIf="generatingDocs">
      <div class="spinnerContainer">
        <div class="ssSpinner" style="margin-bottom: 1rem"></div>
        <p>Generating documents. This may take a few seconds.</p>
      </div>
    </ng-container>
    <ng-container *ngIf="!generatingDocs">
      <label>Investment Profile</label>
      <div class="dropdown">
        <div class="dropdown__visible" (click)="profileDropdownOpen = !profileDropdownOpen">
          <p>
            <span>{{ selectedInvestmentProfile?.legalName }}</span>
          </p>
        </div>
        <ul
          class="dropdown__menu"
          [ngClass]="{ 'dropdown__menu--open': profileDropdownOpen }"
          (click)="profileDropdownOpen = false"
        >
          <li
            class="dropdown__item"
            [ngClass]="{ 'dropdown__item--disabled': profileIsDisabled(profile) }"
            *ngFor="let profile of investmentProfiles"
            (click)="selectProfile(profile)"
          >
            <span
              >{{ profile.legalName
              }}<span *ngIf="!profile.latestApplication || !profile.latestApplication.isAccepted">
                (Unverified)</span
              ></span
            >
            <!-- <i class="fa fa-pencil fa-fw" (click)="$event.stopPropagation(); editProfile(profile)"></i> -->
          </li>
          <li class="dropdown__item dropdown__item--button" *ngIf="personalProfiles.length < 1">
            <button class="buttonText buttonText--noHover" type="button" (click)="openPersonalProfileModal()">
              <i class="buttonText__icon fa fa-plus"></i> Add Individual Profile
            </button>
          </li>
          <li class="dropdown__item dropdown__item--button">
            <button class="buttonText buttonText--noHover" type="button" (click)="openEntityProfileModal()">
              <i class="buttonText__icon fa fa-plus"></i> Add Entity Profile
            </button>
          </li>
        </ul>
      </div>
    </ng-container>
    <button type="button" [disabled]="generateDisabled()" (click)="generateDocs()" class="button centerPage__button">
      Generate Docs
    </button>
  </div>
</div>
