<div class="ssContainer">
  <div class="hero">
    <img class="hero__img" src="../../assets/img/hero.png" alt="The Beehive Accelerator" />
    <button type="button" class="button hero__button" (click)="openContactModal()">Contact Us</button>
    <div class="hero__social">
      <a href="https://www.linkedin.com/company/beehivevp" target="_blank" class="hero__link">
        <i class="icon-linkedin"></i>
      </a>
      <a href="https://twitter.com/BeehiveVP" target="_blank" class="hero__link">
        <i class="icon-twitter"></i>
      </a>
      <a href="https://www.instagram.com/beehivevc" target="_blank" class="hero__link">
        <i class="icon-instagram"></i>
      </a>
      <a href="https://www.facebook.com/BeehiveVP" target="_blank" class="hero__link">
        <i class="icon-facebook"></i>
      </a>
    </div>
  </div>
  <!--/.hero-->
</div>
<!--.ssContainer-->

<div class="blueBg">
  <div class="ssContainer">
    <div class="fund">
      <div class="fund__left">
        <p class="fund__tag">#WeAreBeehive</p>
        <h1 class="fund__head">
          <span class="fund__title">A Community</span>
        </h1>
        <h1 class="fund__head fund__head--2">
          <span class="fund__title">Centric Fund</span>
        </h1>
      </div>
      <div class="fund__right">
        <div class="fund__topic">
          <img class="fund__img" src="../../assets/img/fund-hive.png" alt="" />
          <div>
            <h2>01 - Community</h2>
            <p>We surround startup founders and teams with an experienced and diverse community of mentors.</p>
          </div>
        </div>
        <!--/.fund__topic-->
        <div class="fund__topic">
          <img class="fund__img" src="../../assets/img/fund-honey.png" alt="" />
          <div>
            <h2>02 - Capital</h2>
            <p>We invest in the entire Utah venture ecosystem with a particular focus on early stage and preseed.</p>
          </div>
        </div>
        <!--/.fund__topic-->
        <div class="fund__topic">
          <img class="fund__img" src="../../assets/img/fund-bees.png" alt="" />
          <div>
            <h2>03 - Collaboration</h2>
            <p>We work with best-in-class partners to help build teams, prove products, and enable growth.</p>
          </div>
        </div>
        <!--/.fund__topic-->
      </div>
      <!--/.fund__right-->
    </div>
    <!--/.fund-->
  </div>
  <!--/.ssContainer-->
</div>
<!--/.blueBg-->

<div class="homeSection community">
  <div class="ssContainer">
    <h1 class="header">
      <span class="header__inner">
        <span class="header__number">
          <span class="header__digits">01</span>
        </span>
        Community
      </span>
    </h1>
    <!--/.header-->
    <div class="community__contents">
      <div class="community__column">
        <div class="homeSection__topic">
          <h2>Angel Syndicate</h2>
          <p>
            Our syndicate allows for Utah’s best CEOs and executives to invest by making very small investments in a
            large number of startups at various stages.
          </p>
        </div>
        <!--/.homeSection__topic-->
        <div class="homeSection__topic">
          <h2>Support</h2>
          <p>
            Portfolio companies help each other out and have opportunities to participate in accelerator programs,
            events, and more.
          </p>
        </div>
        <!--/.homeSection__topic-->
        <div class="homeSection__topic">
          <h2>Engagement</h2>
          <p>
            We aim to facilitate engagement from every entrepreneur, investor, and startup enthusiast in Utah, giving
            them the tools and access they need to make the biggest positive impact possible.
          </p>
        </div>
        <!--/.homeSection__topic-->
      </div>
      <img src="../../assets/img/community-hive.svg" alt="Community" class="community__graphic" />
      <div class="community__column community__column--right">
        <div class="homeSection__topic">
          <h2>Mentorship</h2>
          <p>
            Portfolio companies are matched up with seasoned mentors and industry leaders who can guide them on their
            journey.
          </p>
        </div>
        <!--/.homeSection__topic-->
        <div class="homeSection__topic">
          <h2>Network Effects</h2>
          <p>
            Starting a company is easier when you have every founder, VC, and angel investor in Utah cheering you on,
            making introductions, trying your product, and giving feedback.
          </p>
          <p class="secondp">
            Our goal is to create a cohesive and robust network of entrepreneurs and investors who are committed to help
            each other out and improve Utah’s startup ecosystem.
          </p>
        </div>
        <!--/.homeSection__topic-->
      </div>
    </div>
    <!--/.community__contents-->

    <!-- Commenting out mentor photos for now
    <div class="community__mentors">
      <img src="../../assets/img/mentor-1.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-2.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-3.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-4.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-5.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-6.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-7.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-8.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-9.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-10.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-11.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-12.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-13.jpg" alt="Mentor" class="community__mentor" />
      <img src="../../assets/img/mentor-14.jpg" alt="Mentor" class="community__mentor" />
    </div>
    <!--/.community__mentors-->
  </div>
  <!--/.ssContainer-->
</div>
<!--/.community-->

<div class="homeSection capital">
  <div class="ssContainer">
    <h1 class="header">
      <span class="header__inner">
        <span class="header__number header__number--white">
          <span class="header__digits">02</span>
        </span>
        Capital
      </span>
    </h1>
    <!--/.header-->
    <div class="capital__inner">
      <div>
        <div class="homeSection__topic homeSection__topic--yellowBg">
          <h2>Seed Funding</h2>
          <p>
            We’re dead serious about investing in and helping companies at the very earliest stages. Yes, that means
            angel and pre-seed investing, including some back-of-the-napkin type deals. Basically, early conviction and
            expertise are met with early checks and real support.
          </p>
        </div>
        <!--/.homeSection__topic-->
        <div class="homeSection__topic homeSection__topic--yellowBg">
          <h2>Syndicate Investments</h2>
          <p>
            Our angel syndicate invests in venture deals in Utah, regardless of deal stages and sizes. Angels
            participate remotely and can diversify their portfolios by investing on a deal-by-deal basis, committing as
            little as 1K of funding to each of their chosen investments.
          </p>
        </div>
        <!--/.homeSection__topic-->
        <div class="homeSection__topic homeSection__topic--yellowBg">
          <h2>Secondary Liquidity</h2>
          <p>
            We provide liquidity to founders and early employees by acquiring or syndicating a small amount of
            personally-held shares. This probably isn’t Lambo money, but it might pay off some mortgages and student
            loans. Stay aligned, stay motivated!
          </p>
        </div>
        <!--/.homeSection__topic-->
      </div>
      <!--/.capital__left-->
      <img class="capital__graphic" src="../../assets/img/capital-honey.svg" alt="Capital" />
    </div>
    <!--/.capital__inner-->
  </div>
  <!--/.ssContainer-->
</div>
<!--/.capital-->

<div class="homeSection collaboration">
  <div class="ssContainer">
    <div class="collaboration__inner">
      <img class="collaboration__graphic" src="../../assets/img/collaboration-bees.svg" alt="Collaboration" />
      <div>
        <h1 class="header">
          <span class="header__inner header__inner--white">
            <span class="header__number">
              <span class="header__digits">03</span>
            </span>
            Collaboration
          </span>
        </h1>
        <!--/.header-->
        <div class="homeSection__topic homeSection__topic--blueBg">
          <h2>Our Partners</h2>
          <p>
            We will partner with Utah companies to work closely with our early-stage startups, guiding founders through
            every aspect of building a scalable company.
          </p>
          <div class="partners__partner partners__partner--last">
            <button type="button" (click)="openContactModal()" class="dashButton">Become&nbsp;a Partner</button>
          </div>
          <!-- <div class="partners">
            <img class="partners__partner" src="../../assets/img/partner-kiln.png" alt="Kiln" />
            <img class="partners__partner" src="../../assets/img/partner-advancedcfo.png" alt="Advanced CFO" />
            <img class="partners__partner" src="../../assets/img/partner-carta.png" alt="Carta" />
            <img class="partners__partner" src="../../assets/img/partner-helpside.png" alt="Helpside" />
            <img class="partners__partner" src="../../assets/img/partner-savvi.png" alt="Saavi" />
            <img class="partners__partner" src="../../assets/img/partner-creatably.png" alt="Creatably" />
            <img class="partners__partner" src="../../assets/img/partner-startstudio.png" alt="Start Studio" />
            <img class="partners__partner" src="../../assets/img/partner-vouch.png" alt="Vouch" />
          </div> -->
          <!--/.partners-->
        </div>
        <!--/.homeSection__topic-->
      </div>
      <!--/.collaboration__right-->
    </div>
    <!--/.collaboration__inner-->
  </div>
  <!--/.ssContainer-->
</div>
<!--/.collaboration-->

<!-- <div class="homeSection team">
  <div class="ssContainer">
    <h1 class="header">
      <span class="header__inner"> Team </span>
    </h1>
    <div class="team__inner">
      <div class="team__person">
        <img class="team__img" src="../../assets/img/team-luke.jpg" />
        <div class="homeSection__topic team__topic">
          <h2>Luke Gunderson</h2>
          <p>
            Luke is a passionate startup enthusiast who is well known for being obsessed with building and improving the
            Utah startup community. You probably know him from his work with Beehive Startups and Silicon Slopes. He’s
            also a podcasting and social media fiend. He loves sandwiches and spends way too much time on Twitter.
          </p>
        </div>
      </div>
      <div class="team__person">
        <img class="team__img" src="../../assets/img/team-jill.jpg" />
        <div class="homeSection__topic team__topic">
          <h2>Jill Dempsey</h2>
          <p>
            Jill is the venture finance “strategery” behind Beehive, having managed over $100M of investments in
            growth-stage companies across the U.S. and Europe. Jill is a focused investor with a career as CEO, CFO,
            COO, and Board Member and currently serves on multiple advisory boards for investment firms and non-profits.
          </p>
        </div>
      </div>
      <div class="team__person">
        <img class="team__img" src="../../assets/img/team-jordan.jpg" />
        <div class="homeSection__topic team__topic">
          <h2>Jordan Gunderson</h2>
          <p>
            Jordan is the technical / product cofounder you <i>wish</i> you had! He’s helped dozens of entrepreneurs
            take back-of-the-napkin ideas and built them into amazing products for Utah's hottest, most successful
            startups. He loves talking about new business ideas almost as much as he loves playing board games and
            jammin’ out to progressive rock.
          </p>
        </div>
      </div>
    </div>
  </div>
</div> -->

<div class="last">
  <div class="ssContainer last__container">
    <div class="last__inner">
      <div class="last__about">
        <img src="../../assets/img/logo-bee-blue.svg" alt="Bee" class="last__graphic" />
        <h2>About Us</h2>
        <p>
          Beehive Venture Partners is a community-centric venture capital firm and angel syndicate dedicated to funding,
          growing, and improving Utah’s tech ecosystem.
        </p>
        <p class="secondp">We are Utahns funding Utah. #WeAreBeehive</p>
      </div>
      <!--/.last__about-->
      <div class="last__involved">
        <h1 class="last__h1">Want to get involved?</h1>
        <button type="button" (click)="openContactModal()" class="last__button button2">Contact Us</button>
      </div>
      <!--/.last__involved-->
    </div>
    <!--/.last__inner-->
  </div>
  <!--/.ssContainer-->
</div>
<!--/.last-->
