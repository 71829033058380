import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { AuthService, GQLService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';

@Component({
  selector: 'app-manager-doc',
  templateUrl: './manager-doc.component.html',
  styleUrls: ['./manager-doc.component.scss'],
})
export class ManagerDocComponent implements OnInit, OnDestroy {
  public documentUrl = null;

  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private sanitizer: DomSanitizer,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      if (!this.auth.isAdmin) {
        this.router.navigate(['/error']);
        return;
      }
      this.gql
        .query(dealQueries.managerDealDocumentQuery, {
          id: params.id,
        })
        .then((query: any) => {
          let doc = query.data.managerDealDocument;
          this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(doc.managerSigningLink);
        });
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }
}
