import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../modules/modals';
import { ToastService } from '../../../modules/toast';
import { EntityProfileModalComponent, PersonalProfileModalComponent } from '../../../components';
import { AuthService, GQLService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';
import * as investQueries from '../../../queries/invest-queries';
import * as profileQueries from '../../../queries/profile-queries';

@Component({
  selector: 'app-invest-profile',
  templateUrl: './invest-profile.component.html',
  styleUrls: ['./invest-profile.component.scss'],
})
export class InvestProfileComponent implements OnInit, OnDestroy {
  public user = null;
  public deal = null;
  public loadingDeal = true;
  public investmentProfiles = [];
  public selectedInvestmentProfile = null;
  public profileDropdownOpen = false;
  public generatingDocs = false;

  private authSub = null;
  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modals: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.getInvestmentProfiles();
        this.gql
          .query(dealQueries.dealQuery, { slug: params.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            if (this.myInvestment) {
              if (this.myInvestment.stage === 'Sign Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'docs']);
              }
              if (this.myInvestment.stage === 'Finalize Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'finalize']);
              }
              if (this.myInvestment.stage === 'Initiate Transfer') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
              if (this.myInvestment.stage === 'Transfer Pending') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
            } else {
              this.router.navigate(['/invest', this.deal.slug, 'details']);
            }
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          })
          .finally(() => {
            this.loadingDeal = false;
          });
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public get myInvestment() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }

  public get usedProfiles() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.profile !== null;
    });
    return userInvestments.map((investment) => investment.node.profile.id);
  }

  public profileIsDisabled(profile) {
    return (
      this.usedProfiles.includes(profile.id) || !profile.latestApplication || !profile.latestApplication.isAccepted
    );
  }

  public selectProfile(profile) {
    if (this.profileIsDisabled(profile)) {
      return;
    }
    this.selectedInvestmentProfile = profile;
  }

  public get personalProfiles() {
    if (!this.investmentProfiles) {
      return null;
    }
    return this.investmentProfiles.filter((profile) => profile.profileType === 'person');
  }

  public getInvestmentProfiles(selectId = null) {
    this.gql
      .query(profileQueries.investmentProfilesQuery)
      .then((query: any) => {
        this.investmentProfiles = query.data.me.investmentProfiles.filter((profile: any) => {
          return profile.isActive;
        });
        if (selectId) {
          let select = this.investmentProfiles.find((profile) => profile.id === selectId);
          if (select) {
            this.selectProfile(select);
          }
        }
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public openPersonalProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(PersonalProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe(
        (updatedProfile) => {
          let selectId = updatedProfile ? updatedProfile.id : null;
          this.getInvestmentProfiles(selectId);
        },
        () => {},
        () => {}
      );
  }

  public openEntityProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(EntityProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe(
        (updatedProfile) => {
          let selectId = updatedProfile ? updatedProfile.id : null;
          this.getInvestmentProfiles(selectId);
        },
        () => {},
        () => {}
      );
  }

  public editProfile(profile: any) {
    this.profileDropdownOpen = false;
    if (profile.profileType === 'person') {
      this.openPersonalProfileModal(profile);
    } else if (profile.profileType === 'entity') {
      this.openEntityProfileModal(profile);
    }
  }

  public generateDisabled() {
    return !this.deal || !this.selectedInvestmentProfile || this.generatingDocs;
  }

  public generateDocs() {
    this.generatingDocs = true;
    this.gql
      .mutation(investQueries.selectInvestmentProfileMutation, {
        id: this.myInvestment.pk,
        amount: this.myInvestment.amount,
        profile: this.selectedInvestmentProfile.id,
      })
      .then((query: any) => {
        this.generatingDocs = false;
        this.router.navigate(['/invest', this.deal.slug, 'docs']);
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }
}
