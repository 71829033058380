import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { environment } from '../../../../environments/environment';
import { AuthService, GQLService } from '../../../services';
import * as adminQueries from '../../../queries/admin-queries';

@Component({
  selector: 'app-activity-list',
  templateUrl: './activity-list.component.html',
  styleUrls: ['./activity-list.component.scss'],
})
export class ActivityListComponent implements OnDestroy, OnInit {
  public environment = environment;
  public moment = moment;

  public user = null;
  public userLoaded = false;
  public investmentActivities = [];
  public investmentActivitiesLoaded = false;

  private authSub = null;

  constructor(private router: Router, private auth: AuthService, private gql: GQLService) {}

  public ngOnInit() {
    this.userLoaded = false;
    this.authSub = this.auth.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      if (!this.user.isAdmin) {
        this.router.navigate(['/']);
      }
      this.userLoaded = true;
      this.gql.query(adminQueries.investmentActivitiesQuery).then((query: any) => {
        this.investmentActivitiesLoaded = true;
        this.investmentActivities = query.data.investmentActivities;
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }
}
