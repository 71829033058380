import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject, interval } from 'rxjs';
import { debounce } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import {
  ChangePasswordModalComponent,
  EntityProfileModalComponent,
  FundingSourceModalComponent,
  PersonalProfileModalComponent,
} from '../../components';
import { AuthService, GQLService, PlaidService } from '../../services';
import * as profileQueries from '../../queries/profile-queries';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss'],
})
export class SettingsComponent implements OnDestroy, OnInit {
  public environment = environment;
  public user = null;
  public firstName = null;
  public lastName = null;
  public phoneNumber = null;
  public profiles = [];
  public loadingProfile = true;
  public profileMenuOpen = {};
  public profileMenuOptions = {
    relativeTop: '48px',
    relativeLeft: '0px',
  };
  public accountChangeSubject = new Subject<any>();
  public accountChangeErrors = [];

  private authSub = null;
  private plaidSuccessSub = null;

  constructor(
    private modals: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService,
    private plaid: PlaidService
  ) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe(
      (user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.firstName = this.user.firstName;
        this.lastName = this.user.lastName;
        this.phoneNumber = this.user.phone;
        this.getInvestmentProfiles();
      },
      () => {},
      () => {}
    );
    this.plaidSuccessSub = this.plaid.onSuccessSubject.subscribe(
      (success: any) => {
        let fundingModalSub = this.modals
          .open(FundingSourceModalComponent, {
            animation: 'scaleIn .6s',
            backdrop: true,
            closeDisabled: true,
            data: {
              profileId: success.profileId,
              public_token: success.public_token,
              metadata: success.metadata,
            },
            width: '700px',
          })
          .subscribe(
            () => {
              this.getInvestmentProfiles();
            },
            () => {},
            () => {
              fundingModalSub.unsubscribe();
            }
          );
      },
      () => {},
      () => {}
    );
    this.accountChangeSubject.pipe(debounce(() => interval(1000))).subscribe(
      () => {
        if (this.firstName.trim().length < 1 || this.lastName.trim().length < 1 || this.phoneNumber.length < 10) {
          return;
        }
        this.auth
          .updateAccount(this.firstName.trim(), this.lastName.trim(), this.phoneNumber)
          .then(() => {
            this.auth.getUser().subscribe();
            this.accountChangeErrors = [];
          })
          .catch((errs: any) => {
            this.accountChangeErrors = errs;
          });
      },
      () => {},
      () => {}
    );
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.plaidSuccessSub) {
      this.plaidSuccessSub.unsubscribe();
    }
  }

  public get personalProfiles() {
    return this.profiles.filter((profile) => {
      return profile.profileType === 'person' && profile.isActive;
    });
  }

  public get entityProfiles() {
    return this.profiles.filter((profile) => {
      return profile.profileType === 'entity' && profile.isActive;
    });
  }

  public get deactivatedProfiles() {
    return this.profiles.filter((profile) => {
      return !profile.isActive;
    });
  }

  public getInvestmentProfiles() {
    this.gql
      .query(profileQueries.investmentProfilesQuery)
      .then((query: any) => {
        this.profiles = query.data.me.investmentProfiles;
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.loadingProfile = false;
      });
  }

  public deactivateProfile(profile: any) {
    this.gql
      .mutation(profileQueries.deactivateInvestmentProfileMutation, {
        id: profile.id,
      })
      .then((deactivateQuery: any) => {
        this.getInvestmentProfiles();
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public activateProfile(profile: any) {
    this.gql
      .mutation(profileQueries.activateInvestmentProfileMutation, {
        id: profile.id,
      })
      .then((activateQuery: any) => {
        this.getInvestmentProfiles();
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public addFundingSource(profile: any) {
    this.plaid.open(profile.id);
  }

  public openChangePasswordModal() {
    this.modals
      .open(ChangePasswordModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        width: '700px',
      })
      .subscribe();
  }

  public openPersonalProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(PersonalProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe(() => {
        this.getInvestmentProfiles();
      });
  }

  public openEntityProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(EntityProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe(() => {
        this.getInvestmentProfiles();
      });
  }

  public editProfile(profile: any) {
    if (profile.profileType === 'person') {
      this.openPersonalProfileModal(profile);
    } else if (profile.profileType === 'entity') {
      this.openEntityProfileModal(profile);
    } else {
      this.openEntityProfileModal(profile);
    }
  }

  public accountFieldChange() {
    this.accountChangeSubject.next();
  }
}
