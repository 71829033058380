import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { environment } from '../../../../environments/environment';
import { ModalService } from '../../../modules/modals';
import { ToastService } from '../../../modules/toast';
import { DocumentModalComponent } from '../../../components';
import { AuthService, GQLService } from '../../../services';
import { AppConfig } from '../../../app.config';
import * as dealQueries from '../../../queries/deal-queries';

@Component({
  selector: 'app-invest-docs',
  templateUrl: './invest-docs.component.html',
  styleUrls: ['./invest-docs.component.scss'],
})
export class InvestDocsComponent implements OnInit, OnDestroy {
  public user = null;
  public deal = null;
  public loadingDeal = true;
  public investment = null;
  public documents = [];
  public loadingDocuments = true;
  public loadingDocumentId = null;
  public downloadingDocuments = [];

  private authSub = null;
  private paramsSub = null;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private modals: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.gql
          .query(dealQueries.dealQuery, { slug: params.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            if (this.myInvestment) {
              if (this.myInvestment.stage === 'Provide Details') {
                this.router.navigate(['/invest', this.deal.slug, 'profile']);
              }
              if (this.myInvestment.stage === 'Sign Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'docs']);
              }
              if (this.myInvestment.stage === 'Finalize Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'finalize']);
              }
              if (this.myInvestment.stage === 'Initiate Transfer') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
              if (this.myInvestment.stage === 'Transfer Pending') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
            } else {
              this.router.navigate(['/invest', this.deal.slug, 'details']);
            }
            this.loadingDocuments = true;
            this.gql
              .query(dealQueries.embeddedSigningLinksQuery, {
                id: this.myInvestment.pk,
              })
              .then((documentsQuery: any) => {
                this.documents = documentsQuery.data.investment.dealDocuments.edges.map((obj: any) => {
                  return obj.node;
                });
                this.loadingDocuments = false;
              })
              .catch((errs) => {
                this.toast.gqlErrors(errs);
              })
              .finally(() => {
                this.loadingDocuments = false;
              });
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          })
          .finally(() => {
            this.loadingDeal = false;
          });
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public get myInvestment() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }

  public isDownloading(doc: any) {
    return this.downloadingDocuments.indexOf(doc) > -1;
  }

  public downloadDocument(doc: any) {
    if (this.isDownloading(doc)) {
      return;
    }
    const url = AppConfig.documentDownloadUrl(doc.signnowId);
    let headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/pdf');
    let docName = '';
    if (environment.name !== 'prod') {
      docName = `[DEV] ${doc.template.name} [${this.user.firstName} ${this.user.lastName}].pdf`;
    } else {
      docName = `${doc.template.name} [${this.user.firstName} ${this.user.lastName}].pdf`;
    }
    this.downloadingDocuments.push(doc);
    this.http
      .get(url, {
        headers: headers,
        params: {},
        responseType: 'blob',
      })
      .subscribe(
        (res: any) => {
          let blob = new Blob([res], { type: 'application/pdf' });
          saveAs(blob, docName);
        },
        (err) => {
          this.toast.error('Error downloading ' + docName);
          let downloadingIdx = this.downloadingDocuments.indexOf(doc);
          this.downloadingDocuments.splice(downloadingIdx, 1);
        },
        () => {
          let downloadingIdx = this.downloadingDocuments.indexOf(doc);
          this.downloadingDocuments.splice(downloadingIdx, 1);
        }
      );
  }

  public openDocument(doc: any) {
    if (!!doc.datetimeSubmitted) {
      this.downloadDocument(doc);
      return;
    }
    this.modals
      .open(DocumentModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: true,
        data: {
          doc,
        },
      })
      .subscribe(() => {
        this.loadingDocumentId = doc.pk;
        this.gql
          .query(dealQueries.dealQuery, { slug: this.deal.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            this.gql
              .query(dealQueries.embeddedSigningLinksQuery, {
                id: this.myInvestment.pk,
              })
              .then((documentsQuery: any) => {
                this.loadingDocumentId = null;
                this.documents = documentsQuery.data.investment.dealDocuments.edges.map((obj: any) => {
                  return obj.node;
                });
              })
              .finally(() => {
                this.loadingDocumentId = null;
              });
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          });
      });
  }

  public clickNext() {
    this.router.navigate(['/invest', this.deal.slug, 'finalize']);
  }

  public get allDocumentsSigned() {
    return this.documents.length > 0 && this.documents.every((a) => !!a.datetimeSubmitted);
  }
}
