import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { environment } from '../../environments/environment';
import { ToastService } from '../modules/toast';
import { GQLService } from './gql.service';
import * as profileQueries from '../queries/profile-queries';

declare var Plaid: any;

@Injectable()
export class PlaidService {
  private instance = null;

  private profileId = '';

  public onSuccessSubject = new Subject<any>();

  constructor(private toast: ToastService, private gql: GQLService) {}

  public open(profile: string) {
    this.profileId = profile;
    let env = environment.name === 'prod' ? 'production' : 'sandbox';
    this.getPlaidLinkToken().then((token: any) => {
      this.instance = Plaid.create({
        token: token,
        env,
        onSuccess: (public_token: any, metadata: any) => {
          this.onSuccess(public_token, metadata);
        },
        onLoad: () => {
          this.onLoad();
        },
        onExit: (err: any, metadata: any) => {
          this.onExit(err, metadata);
        },
        onEvent: (eventName: any, metadata: any) => {
          this.onEvent(eventName, metadata);
        },
      });
      this.instance.open();
    });
  }

  public exit() {
    if (this.instance) {
      this.instance.exit();
    }
  }

  public destroy() {
    if (this.instance) {
      this.instance.destroy();
    }
  }

  private getPlaidLinkToken() {
    return new Promise((resolve, reject) => {
      this.gql
        .query(profileQueries.plaidLinkTokenQuery)
        .then((query: any) => resolve(query.data.me.plaidLinkToken))
        .catch((errs: any) => {
          this.toast.gqlErrors(errs);
          reject(errs);
        });
    });
  }

  private onSuccess(public_token: any, metadata: any) {
    this.onSuccessSubject.next({
      profileId: this.profileId,
      public_token,
      metadata,
    });
  }

  private onLoad() {}

  private onExit(err: any, metadata: any) {}

  private onEvent(eventName: any, metadata: any) {}
}
