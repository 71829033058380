<div class="modalGuts">
  <h1>Investment Options, Explained</h1>

  <p>
    Users may invest for as many <strong>entities</strong> as they own and / or for which they are otherwise authorized
    to invest.
  </p>
  <br />
  <p>
    <strong>Personal</strong> investment profiles, on the other hand, are limited (by default) to one per user. If you
    need this limit raised because you'd like to invest personally <strong>as well as</strong> for another individual
    for whom you are a custodian or nominee, please <a (click)="modal.close('contact')">contact us</a>.
  </p>

  <button class="button" (click)="modal.close(null)">OK, Got It</button>
</div>
