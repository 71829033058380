<div class="modalGuts">
  <h1>Wire Transfer</h1>
  <ng-container *ngIf="info">
    <div style="margin-bottom: 1rem">
      <strong class="header"><u>Investment Details</u></strong>
      <div class="field">
        <label>Wire Amount: </label>
        <p class="value">{{info.purchasePrice | formatCurrency}}</p>
      </div>
      <div class="field">
        <label>Reference Number: </label>
        <p class="value">{{ info.referenceNumber }}</p>
      </div>
    </div>
    <div style="margin-bottom: 1rem">
      <strong class="header"><u>Beneficiary Bank</u></strong>
      <div class="field">
        <label>Bank Name: </label>
        <p class="value">{{info.bankName}}</p>
      </div>
      <div class="field">
        <label>Bank Address: </label>
        <p class="value">One South Main Street, Salt Lake City, UT 84133</p>
      </div>
      <div class="field">
        <label>Bank Phone: </label>
        <p class="value">{{info.bankPhone}}</p>
      </div>
      <div class="field">
        <label>Routing Number: </label>
        <p class="value">{{info.routingNumber}}</p>
      </div>
      <div class="field">
        <label>SWIFT Code: </label>
        <p class="value">{{ info.swiftCode }}</p>
      </div>
    </div>
    <div style="margin-bottom: 1rem">
      <strong class="header"><u>Beneficiary Account</u></strong>
      <div class="field">
        <label>Account Name: </label>
        <p class="value">{{info.accountName}}</p>
      </div>
      <div class="field">
        <label>Account Address: </label>
        <p class="value">13 North 1800 East, Spanish Fork, UT 84660</p>
      </div>
      <div class="field">
        <label>Account Number: </label>
        <p class="value">{{info.accountNumber}}</p>
      </div>
    </div>
  </ng-container>
  <button class="button modalGuts__button" style="margin: 2rem auto 0 auto" (click)="sendWireInfoEmail()">
    Email Wire Info
  </button>
  <p class="small instructions" style="margin-top: 1rem; text-align: center">
    Send this information to your email address.
  </p>
  <p class="small">
    Need help? Please call or text Luke Gunderson at 801-735-8079 with questions.
  </p>
</div>
