import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-login',
  templateUrl: 'login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent {
  public email = '';
  public password = '';

  public submittingForm = false;
  public error = '';
  public notVerified = false;
  public resendToEmail = '';

  public firstLoginAttempt = null;
  public retryStatuses = [502, 503, 504];
  public retry = false;
  public retrySubmitting = false;
  public retries = 0;
  public retryWait = 0;

  constructor(private router: Router, private toast: ToastService, private auth: AuthService) {}

  public routeToSignup() {
    this.router.navigate(['/sign-up']);
  }

  public routeToForgotPassword() {
    this.router.navigate(['/forgot-password']);
  }

  public login() {
    if (this.retry) {
      this.retrySubmitting = true;
    } else {
      this.firstLoginAttempt = Date.now();
    }
    this.notVerified = false;
    this.submittingForm = true;
    this.error = '';
    this.auth
      .login(this.email, this.password)
      .then(() => {
        this.clearRetry();
        this.router.navigate(['/dashboard']);
      })
      .catch((errors: any) => {
        if (errors.length > 0) {
          if (errors[0].code && errors[0].code === 'not_verified') {
            this.clearRetry();
            this.notVerified = true;
            this.resendToEmail = this.email;
            this.error = errors[0].message;
          } else if (errors[0].status && this.retryStatuses.includes(errors[0].status)) {
            this.retry = true;
            this.retrySubmitting = false;
            this.retryWait = this.retryWaitTime(this.retries);
            let now = Date.now();
            let timeLeft = this.firstLoginAttempt + 20 * 1000;
            let diff = timeLeft - now;
            if (diff < 0) {
              this.onRetryTimeout();
              return;
            }
            setTimeout(() => {
              this.retries++;
              this.login();
            }, this.retryWait);
          } else {
            this.clearRetry();
            this.error = errors[0].message;
          }
        } else {
          this.clearRetry();
        }
      });
  }

  public retryWaitTime(retryCount) {
    if (retryCount === 0) {
      return 0;
    }
    return Math.pow(2, retryCount) * 100;
  }

  public clearRetry() {
    this.firstLoginAttempt = null;
    this.retry = false;
    this.retries = 0;
    this.retryWait = 0;
    this.submittingForm = false;
    this.retrySubmitting = false;
  }

  public onRetryTimeout() {
    this.clearRetry();
    this.error = 'Could not connect.  Please try again later.';
  }

  public resendActivationEmail() {
    if (!this.resendToEmail) {
      return;
    }
    this.auth
      .resendActivationEmail(this.resendToEmail)
      .then(() => {
        this.toast.success(`A verification email has been sent to ${this.email}.`);
        this.notVerified = false;
        this.error = '';
      })
      .catch((errors: any) => {
        this.toast.gqlErrors(errors);
      });
  }
}
