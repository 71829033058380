import { Component, ElementRef, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ModalService } from '../../modules/modals';

@Component({
  selector: 'app-document',
  templateUrl: './document.modal.html',
  styleUrls: ['./document.modal.scss'],
})
export class DocumentModalComponent implements OnInit, OnDestroy {
  public data = null;
  public dataDefaults = {};

  public documentUrl = null;

  constructor(private sanitizer: DomSanitizer, public modal: ModalService) {}

  public ngOnInit() {
    if (!this.data) {
      return;
    }
    if (this.data.documentLink) {
      this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.documentLink);
    }
    if (this.data.doc) {
      this.documentUrl = this.sanitizer.bypassSecurityTrustResourceUrl(this.data.doc.embeddedSigningLink);
    }
    window.onmessage = (e) => {
      if (e && e.data && e.data.type && e.data.type === 'closeModal') {
        this.modal.close(null);
      }
    };
  }

  public ngOnDestroy() {}
}
