import { Injectable } from '@angular/core';
import { HttpService } from './http.service';
import { Store } from './store';

@Injectable()
export class StoreService {
  public User = new Store(this.http, 'users');

  constructor(private http: HttpService) {}
}
