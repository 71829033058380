import gql from 'graphql-tag';

/**
 * Queries
 **/

export const investmentActivitiesQuery = gql`
  query {
    investmentActivities {
      id
      actionType
      created
      investment {
        id
        pk
        amount
        deal {
          id
          pk
          slug
          name
          logo {
            name
            url
          }
        }
        fundingSource {
          id
          name
        }
        profile {
          id
          legalName
        }
      }
      message
    }
  }
`;

export const dealsQuery = gql`
  query deals($before: String, $after: String, $first: Int, $last: Int, $dealType: String, $isPublished: Boolean) {
    deals(before: $before, after: $after, first: $first, last: $last, dealType: $dealType, isPublished: $isPublished) {
      edges {
        node {
          id
          pk
          slug
          created
          modified
          name
          logo {
            name
            url
          }
          status
          minimumInvestment
          dealType
          deadline
          round
          vehicle
          cap
          hasProRataRights
          allocation
          carry
          setupCost
          publishDatetime
          pledgeStageTotal
          legalStageTotal
          finalizeStageTotal
          paymentStageTotal
          fundedStageTotal
          percentageFull
          amountLeft
        }
      }
    }
  }
`;

export const dealQuery = gql`
  query deal($slug: String!) {
    deal(slug: $slug) {
      id
      pk
      slug
      name
      logo {
        url
      }
      status
      deadline
      pitchDeck {
        name
        url
      }
      allocation
      amountLeft
      percentageFull
      pledgeStageTotal
      legalStageTotal
      finalizeStageTotal
      paymentStageTotal
      fundedStageTotal
      pledgedInvestments: investments(stage: "pledge") {
        edges {
          node {
            id
            pk
            amount
            created
            fundingSource {
              name
            }
            profile {
              id
              legalName
            }
            investor {
              id
              pk
              email
              firstName
              lastName
            }
            activities {
              created
              actionType
            }
          }
        }
      }
      legalInvestments: investments(stage: "legal") {
        edges {
          node {
            id
            pk
            amount
            created
            fundingSource {
              name
            }
            profile {
              id
              legalName
            }
            investor {
              id
              email
              firstName
              lastName
            }
            activities {
              created
              actionType
            }
          }
        }
      }
      finalizeInvestments: investments(stage: "finalize") {
        edges {
          node {
            id
            pk
            amount
            created
            fundingSource {
              name
            }
            profile {
              id
              legalName
            }
            investor {
              id
              email
              firstName
              lastName
            }
            activities {
              created
              actionType
            }
          }
        }
      }
      paymentInvestments: investments(stage: "payment") {
        edges {
          node {
            id
            pk
            amount
            created
            fundingSource {
              name
            }
            profile {
              id
              legalName
            }
            investor {
              id
              email
              firstName
              lastName
            }
            activities {
              created
              actionType
            }
          }
        }
      }
      fundedInvestments: investments(stage: "funded") {
        edges {
          node {
            id
            pk
            amount
            created
            fundingSource {
              name
            }
            profile {
              id
              legalName
            }
            investor {
              id
              email
              firstName
              lastName
            }
            activities {
              created
              actionType
            }
          }
        }
      }
      documentTemplates {
        edges {
          node {
            name
            templateFile {
              url
            }
          }
        }
      }
    }
  }
`;

export const investorsQuery = gql`
  query {
    users(isInvestor: true) {
      edges {
        node {
          id
          pk
          firstName
          lastName
          email
          investorMasterData {
            syndicate {
              totalDeals
              totalAmountInvested
              averageInvestment
              inProcessInvestments
              completedInvestments
            }
            sidecar {
              totalDeals
              totalAmountInvested
              averageInvestment
              inProcessInvestments
              completedInvestments
            }
            totalInvested
            latestActivity {
              investment {
                deal {
                  name
                }
              }
              created
            }
          }
        }
      }
    }
  }
`;

export const investorQuery = gql`
  query user($id: ID!) {
    user(id: $id) {
      id
      firstName
      lastName
      email
      investmentProfiles {
        id
        legalName
        profileType
        isActive
        user {
          email
        }
        fundingSources {
          id
          name
          bankName
        }
      }
      inProcessInvestments: investments(status: "processing") {
        edges {
          node {
            deal {
              id
              name
              logo {
                url
              }
              deadline
            }
            profile {
              legalName
            }
            dealDocuments {
              edges {
                node {
                  id
                  pk
                  signnowId
                  datetimeSubmitted
                }
              }
            }
            amount
            stage
          }
        }
      }
      completedInvestments: investments(status: "completed") {
        edges {
          node {
            deal {
              id
              name
              logo {
                url
              }
              deadline
            }
            profile {
              legalName
            }
            dealDocuments {
              edges {
                node {
                  id
                  pk
                  signnowId
                  datetimeSubmitted
                }
              }
            }
            amount
            stage
          }
        }
      }
      investmentActivities {
        id
        message
        created
        investment {
          deal {
            name
            logo {
              url
            }
          }
        }
      }
    }
  }
`;

/**
 * Mutations
 **/
