import { Component, OnInit, OnDestroy } from '@angular/core';
import * as moment from 'moment';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AppConfig } from '../../app.config';
import {
  AddProfileHelpModalComponent,
  ContactModalComponent,
  DocumentModalComponent,
  DownloadDocumentsModalComponent,
  EntityProfileModalComponent,
  WireInstructionsModalComponent,
  PersonalProfileModalComponent,
} from '../../components';
import { AuthService, BannerService, GQLService } from '../../services';
import * as dealQueries from '../../queries/deal-queries';
import * as profileQueries from '../../queries/profile-queries';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnDestroy, OnInit {
  public moment = moment;

  public user = null;
  public investmentProfiles = [];
  private _selectedProfile = null;
  public loadingProfile = true;
  public draftDeals = [];
  public draftDealsOrig = [];
  public loadingDrafts = true;
  public availableDeals = [];
  public availableDealsOrig = [];
  public loadingAvailable = true;
  public inProcessInvestmentsOrig = [];
  public inProcessInvestments = [];
  public loadingInProcess = true;
  public completedInvestments = [];
  public completedInvestmentsOrig = [];
  public loadingCompleted = true;
  public numberTipOpen = {};
  public profileDropdownOpen = false;

  public loadingSigningLink = false;
  public creatingProfileApplication = false;
  public downloadingQuestionnaire = false;

  public draftSort = {
    direction: 'dsc',
    field: 'deal',
  };

  public availableSort = {
    direction: 'dsc',
    field: 'deadline',
  };

  public inProcessSort = {
    direction: 'dsc',
    field: 'deadline',
  };

  public completedSort = {
    direction: 'dsc',
    field: 'status',
  };

  private authSub = null;

  constructor(
    private modals: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private banners: BannerService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.user = user;
      if (this.user.isAdmin) {
        this.getDraftDeals();
      }
      this.getInvestmentProfiles(true);
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public shouldHighlightDeal(id: string | number) {
    return this.banners.activeBanners.map((b) => b.pk).includes(id);
  }

  public getInvestmentProfiles(selectFirst = false, selectId = null) {
    this.loadingProfile = true;
    this.gql
      .query(profileQueries.investmentProfilesQuery)
      .then((query: any) => {
        this.investmentProfiles = query.data.me.investmentProfiles;
        if (this.investmentProfiles.length > 0 && selectFirst) {
          this.selectedProfile = this.investmentProfiles[0];
        }
        if (this.investmentProfiles.length > 0 && !selectFirst && selectId) {
          let idx = this.investmentProfiles.findIndex((obj) => obj.id === selectId);
          if (idx >= 0) {
            this.selectedProfile = this.investmentProfiles[idx];
          }
        }
        this.getInProcess(this.selectedProfile.id);
        this.getCompleted(this.selectedProfile.id);
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.loadingProfile = false;
      });
  }

  public getDraftDeals() {
    this.loadingDrafts = true;
    this.gql
      .query(dealQueries.dealsQuery, {
        isPublished: false,
      })
      .then((query: any) => {
        this.draftDeals = query.data.deals.edges.map((obj: any) => {
          return obj.node;
        });
        this.draftDealsOrig = this.draftDeals;
        this.sortDrafts();
      })
      .finally(() => {
        this.loadingDrafts = false;
      });
  }

  public getAvailableDeals(profileId) {
    this.loadingAvailable = true;
    this.gql
      .query(profileQueries.investmentProfileAvailableDealsQuery, {
        id: this._selectedProfile.id,
      })
      .then((query: any) => {
        this.availableDeals = query.data.investmentProfile.availableDeals;
        this.availableDealsOrig = this.availableDeals;
        this.sortAvailable();
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.loadingAvailable = false;
      });
  }

  public getInProcess(profileId) {
    this.loadingInProcess = true;
    this.gql
      .query(profileQueries.myInvestmentsQuery, { status: 'processing', profile: profileId })
      .then((query: any) => {
        this.inProcessInvestments = query.data.me.investments.edges.map((obj: any) => {
          return obj.node;
        });
        this.banners.inProgressSubject$.next(this.inProcessInvestments);
        this.inProcessInvestmentsOrig = this.inProcessInvestments;
        this.sortInProcess();
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.loadingInProcess = false;
      });
  }

  public getCompleted(profileId) {
    this.loadingCompleted = true;
    this.gql
      .query(profileQueries.myInvestmentsQuery, { status: 'completed', profile: profileId })
      .then((query: any) => {
        this.completedInvestments = query.data.me.investments.edges.map((obj: any) => {
          return obj.node;
        });
        this.completedInvestmentsOrig = this.completedInvestments;
        this.sortCompleted();
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      })
      .finally(() => {
        this.loadingCompleted = false;
      });
  }

  public removeUnderscore(str: string) {
    return str.split('_').join(' ');
  }

  public draftHeaderClick(field: string) {
    if (this.draftSort.field === field) {
      this.draftSort.direction = this.draftSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.draftSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortDrafts();
  }

  public sortDrafts() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.name).localeCompare(b.name);
      },
      roundSize: (a: any, b: any) => {
        return b.cap - a.cap;
      },
      allocation: (a: any, b: any) => {
        return b.allocation - a.allocation;
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      },
    };
    this.draftDeals = [...this.draftDealsOrig].sort(functions[this.draftSort.field]);
    if (this.draftSort.direction === 'asc') {
      this.draftDeals = [...this.draftDeals].reverse();
    }
  }

  public availableHeaderClick(field: string) {
    if (this.availableSort.field === field) {
      this.availableSort.direction = this.availableSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.availableSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortAvailable();
  }

  public sortAvailable() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.name).localeCompare(b.name);
      },
      stage: (a: any, b: any) => {
        return ('' + a.stage).localeCompare(b.stage);
      },
      roundSize: (a: any, b: any) => {
        return b.cap - a.cap;
      },
      allocation: (a: any, b: any) => {
        return b.allocation - a.allocation;
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      },
    };
    this.availableDeals = [...this.availableDealsOrig].sort(functions[this.availableSort.field]);
    if (this.availableSort.direction === 'asc') {
      this.availableDeals = [...this.availableDeals].reverse();
    }
  }

  public inProcessHeaderClick(field: string) {
    if (this.inProcessSort.field === field) {
      this.inProcessSort.direction = this.inProcessSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.inProcessSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortInProcess();
  }

  public sortInProcess() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.deal.name).localeCompare(b.deal.name);
      },
      amount: (a: any, b: any) => {
        return b.amount - a.amount;
      },
      profile: (a: any, b: any) => {
        return ('' + a.profile.legalName).localeCompare(b.profile.legalName);
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deal.deadline).getTime() - new Date(b.deal.deadline).getTime();
      },
      stage: (a: any, b: any) => {
        return ('' + a.stage).localeCompare(b.stage);
      },
    };
    this.inProcessInvestments = [...this.inProcessInvestmentsOrig].sort(functions[this.inProcessSort.field]);
    if (this.inProcessSort.direction === 'asc') {
      this.inProcessInvestments = [...this.inProcessInvestments].reverse();
    }
  }

  public completedHeaderClick(field: string) {
    if (this.completedSort.field === field) {
      this.completedSort.direction = this.completedSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.completedSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortCompleted();
  }

  public sortCompleted() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.deal.name).localeCompare(b.deal.name);
      },
      amount: (a: any, b: any) => {
        return b.amount - a.amount;
      },
      profile: (a: any, b: any) => {
        return ('' + a.profile.legalName).localeCompare(b.profile.legalName);
      },
      status: (a: any, b: any) => {
        return ('' + a.deal.status).localeCompare(b.deal.status);
      },
    };
    this.completedInvestments = [...this.completedInvestmentsOrig].sort(functions[this.completedSort.field]);
    if (this.completedSort.direction === 'asc') {
      this.completedInvestments = [...this.completedInvestments].reverse();
    }
  }

  public routerLinkForStage(investment: any) {
    switch (investment.stage) {
      case 'Provide Details':
        return ['/invest', investment.deal.slug, 'profile'];
      case 'Sign Docs':
        return ['/invest', investment.deal.slug, 'docs'];
      case 'Finalize Docs':
        return ['/invest', investment.deal.slug, 'finalize'];
      case 'Initiate Transfer':
        return ['/invest', investment.deal.slug, 'payment'];
      case 'Transfer Pending':
        return ['/invest', investment.deal.slug, 'payment'];
    }
  }

  public applicationReapply(profileId) {
    this.creatingProfileApplication = true;
    this.gql
      .mutation(profileQueries.createProfileApplicationMutation, {
        profile: profileId,
      })
      .then((mutation: any) => {
        this.creatingProfileApplication = false;
        this.openProfileQuestionnaire(profileId);
      })
      .catch((errs: any) => {
        this.creatingProfileApplication = false;
        this.toast.gqlErrors(errs);
      });
  }

  public openInvestProfileHelp() {
    this.modals
      .open(AddProfileHelpModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: {},
        width: '600px',
      })
      .subscribe((data) => {
        if (data && data === 'contact') {
          setTimeout(() => {
            this.openContactModal();
          });
        }
      });
  }

  public openContactModal() {
    this.modals
      .open(ContactModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        width: '900px',
      })
      .subscribe();
  }

  public openPersonalProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(PersonalProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe((updatedOrNewProfile) => {
        // If this is a newly created profile, open the questionnaire document
        if (updatedOrNewProfile && !profile) {
          this.openProfileQuestionnaire(updatedOrNewProfile.id);
          this.getInvestmentProfiles(false, updatedOrNewProfile.id);
        } else {
          this.getInvestmentProfiles();
        }
      });
  }

  public openEntityProfileModal(profile = null) {
    let data: any = {};
    if (profile) {
      data.profile = profile;
    }
    this.modals
      .open(EntityProfileModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: data,
        width: '900px',
      })
      .subscribe((updatedOrNewProfile) => {
        // If this is a newly created profile, open the questionnaire document
        if (updatedOrNewProfile && !profile) {
          this.openProfileQuestionnaire(updatedOrNewProfile.id);
          this.getInvestmentProfiles(false, updatedOrNewProfile.id);
        } else {
          this.getInvestmentProfiles();
        }
      });
  }

  public openProfileQuestionnaire(profileId) {
    this.loadingSigningLink = true;
    this.gql
      .query(profileQueries.investmentProfileQuestionnaireQuery, {
        id: profileId,
      })
      .then((query: any) => {
        this.loadingSigningLink = false;
        this.modals
          .open(DocumentModalComponent, {
            animation: 'scaleIn .6s',
            backdrop: true,
            closeDisabled: true,
            data: {
              documentLink: query.data.investmentProfile.latestApplication.embeddedSigningLink,
            },
          })
          .subscribe(() => {
            this.getInvestmentProfiles(false, profileId);
          });
      })
      .catch(() => {
        this.loadingSigningLink = false;
      });
  }

  public downloadQuestionnaire(profile: any) {
    if (this.downloadingQuestionnaire) {
      return;
    }
    if (
      !profile ||
      !profile.latestApplication ||
      !profile.latestApplication.isCompleted ||
      !profile.latestApplication.id
    ) {
      return;
    }
    window.open(AppConfig.createUrl('docs/' + profile.latestApplication.id.substring(0, 8)));
  }

  public editInvestmentProfile(profile) {
    if (profile.profileType === 'person') {
      this.openPersonalProfileModal(profile);
    } else if (profile.profileType === 'entity') {
      this.openEntityProfileModal(profile);
    }
  }

  public openWireInstructions(investment) {
    let modalSub = this.modals
      .open(WireInstructionsModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: {
          investment: investment,
        },
        width: '650px',
      })
      .subscribe(
        () => {},
        () => {},
        () => {
          modalSub.unsubscribe();
        }
      );
  }

  public clickDownloadDocs(investment: any) {
    let modalSub = this.modals
      .open(DownloadDocumentsModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: {
          investment,
          user: this.user,
        },
        width: '650px',
      })
      .subscribe(
        () => {},
        () => {},
        () => {
          modalSub.unsubscribe();
        }
      );
  }

  public openNumberTip(field: string, obj: any, value: number | string) {
    if (value && value < 10000) {
      return;
    }
    this.numberTipOpen[`${field}${obj.id}`] = !this.numberTipOpen[`${field}${obj.id}`];
  }

  public daysDiff(date) {
    let now = moment();
    return Math.abs(now.diff(moment(date), 'days'));
  }

  public get verifiedProfiles() {
    return this.investmentProfiles.filter(
      (profile) => !!profile.latestApplication && !!profile.latestApplication.isAccepted
    );
  }

  public get personalProfiles() {
    return this.investmentProfiles.filter((profile) => profile.profileType === 'person');
  }

  public get entityProfiles() {
    return this.investmentProfiles.filter((profile) => profile.profileType === 'entity');
  }

  public get selectedProfile() {
    return this._selectedProfile;
  }

  public set selectedProfile(selectedProfile) {
    this._selectedProfile = selectedProfile;
    if (this.user.isAdmin) {
      this.getDraftDeals();
    }
    if (this.selectedProfileVerified) {
      this.getAvailableDeals(this._selectedProfile.id);
      this.getInProcess(this._selectedProfile.id);
      this.getCompleted(this._selectedProfile.id);
    }
  }

  public get selectedProfileRejectedReason() {
    if (
      !!this.selectedProfile &&
      this.selectedProfile.latestApplication &&
      !!this.selectedProfile.latestApplication.rejectedReason
    ) {
      return this.selectedProfile.latestApplication.rejectedReason;
    } else {
      return null;
    }
  }

  public get selectedProfileVerified() {
    return (
      !!this.selectedProfile &&
      !!this.selectedProfile.latestApplication &&
      !!this.selectedProfile.latestApplication.isAccepted
    );
  }

  public get selectedProfileQuestionnaireComplete() {
    return (
      !!this.selectedProfile &&
      !!this.selectedProfile.latestApplication &&
      !!this.selectedProfile.latestApplication.isCompleted
    );
  }

  public get selectedProfileTypeHumanized() {
    if (!this.selectedProfile) {
      return '';
    } else {
      return this.selectedProfile.profileType === 'person' ? 'individual' : 'entity';
    }
  }

  public get showNoApplication() {
    return !this.loadingProfile && !!this.selectedProfile && !this.selectedProfile.latestApplication;
  }

  public get showRejected() {
    return (
      !this.loadingProfile &&
      !!this.selectedProfile &&
      this.selectedProfile.latestApplication &&
      this.selectedProfile.latestApplication.isRejected
    );
  }

  public get showIncompleteQuestionnaire() {
    return (
      !this.loadingProfile &&
      !!this.selectedProfile &&
      this.selectedProfile.latestApplication &&
      !this.selectedProfile.latestApplication.isRejected &&
      !this.selectedProfileQuestionnaireComplete
    );
  }

  public get showPendingVerification() {
    return (
      !this.loadingProfile &&
      !!this.selectedProfile &&
      this.selectedProfile.latestApplication &&
      !this.selectedProfile.latestApplication.isRejected &&
      this.selectedProfileQuestionnaireComplete &&
      !this.selectedProfileVerified
    );
  }
}
