import { Component, OnDestroy, OnInit } from '@angular/core';
import { ToastService } from '../../modules/toast';
import { GQLService } from '../../services';
import * as investQueries from '../../queries/invest-queries';

@Component({
  selector: 'app-wire-instructions',
  templateUrl: './wire-instructions.modal.html',
  styleUrls: ['./wire-instructions.modal.scss'],
})
export class WireInstructionsModalComponent implements OnDestroy, OnInit {
  public data = null;
  public dataDefaults = {};

  public info = null;

  constructor(private toast: ToastService, private gql: GQLService) {}

  public ngOnInit() {
    if (this.data && this.data.investment) {
      this.info = {
        purchasePrice: this.data.investment.purchasePrice,
        bankName: this.data.investment.wireTransfer.wireDestination.bank.name,
        bankPhone: this.data.investment.wireTransfer.wireDestination.bank.phoneNumber,
        accountName: this.data.investment.wireTransfer.wireDestination.accountName,
        routingNumber: this.data.investment.wireTransfer.wireDestination.bank.routingNumber,
        accountNumber: this.data.investment.wireTransfer.wireDestination.accountNumber,
        swiftCode: this.data.investment.wireTransfer.wireDestination.bank.swiftCode,
        referenceNumber: this.data.investment.wireTransfer.referenceNumber,
      };
    }
  }

  public ngOnDestroy() {}

  public sendWireInfoEmail() {
    this.gql
      .mutation(investQueries.sendWireInfoEmailMutation, {
        id: this.data.investment.wireTransfer.id,
      })
      .then(() => {
        this.toast.success('Wire information for this investment has been delivered to your email address.');
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }
}
