<div class="modalGuts">
  <h1>{{investment?.deal.name}} Documents</h1>
  <p class="hint" style="margin-bottom: 1rem">Click each of the documents below to download.</p>
  <div class="docs__row" *ngFor="let doc of documents">
    <a class="docs__link" [ngClass]="{'docs__link--disabled': isDownloading(doc)}" (click)="downloadDocument(doc)">
      <i class="docs__icon fa fa-file"></i>
      <span class="docs__text">{{ doc.template.name }}</span>
    </a>
    <i class="fas fa-circle-notch fa-spin" *ngIf="isDownloading(doc)"></i>
    <i class="fa fa-check" *ngIf="!isDownloading(doc) && isDownloaded(doc)"></i>
  </div>
  <button type="button" class="button centerPage__button" (click)="modal.close(null)">Close</button>
</div>
