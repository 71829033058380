import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ActivatedRoute, Router } from '@angular/router';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { ToastService } from '../../modules/toast';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-doc-redirect',
  templateUrl: './doc-redirect.component.html',
  styleUrls: ['./doc-redirect.component.scss'],
})
export class DocRedirectComponent implements OnInit, OnDestroy {
  public downloadingDocument = false;

  private paramsSub = null;

  constructor(
    private http: HttpClient,
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      if (params && params.id) {
        if (this.router.url.startsWith('/docs')) {
          this.downloadDocument(AppConfig.downloadApplicationUrl(params.id), 'application');
        } else if (this.router.url.startsWith('/diligence')) {
          this.downloadDocument(AppConfig.downloadDueDiligenceUrl(params.id), params.id);
        }
      } else {
        this.router.navigate(['/error']);
      }
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public downloadDocument(url, name) {
    if (this.downloadingDocument) {
      return;
    }
    let headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/pdf');
    let docName = '';
    if (environment.name !== 'prod') {
      docName = `[DEV] ${name}.pdf`;
    } else {
      docName = `${name}.pdf`;
    }
    this.downloadingDocument = true;
    this.http
      .get(url, {
        headers: headers,
        params: {},
        responseType: 'blob',
      })
      .subscribe(
        (res: any) => {
          let blob = new Blob([res], { type: 'application/pdf' });
          saveAs(blob, docName);
          setTimeout(() => {
            window.close();
          });
          this.downloadingDocument = false;
        },
        (err) => {
          this.toast.error('Error downloading ' + docName);
          this.downloadingDocument = false;
        },
        () => {
          this.downloadingDocument = false;
        }
      );
  }
}
