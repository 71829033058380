<div class="retryBlur" *ngIf="retry">
  <div class="retryBlur__content">
    <p class="text" *ngIf="!retrySubmitting">Error connecting to server. Retrying in {{ retryWait }}ms...</p>
    <p class="text" *ngIf="retrySubmitting">Retrying...</p>
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer">
  <h1>Login</h1>
  <div class="centerPage">
    <form (submit)="login()">
      <input
        class="input centerPage__input"
        type="email"
        name="email"
        id="email"
        [(ngModel)]="email"
        placeholder="Email"
      />
      <input
        class="input centerPage__input"
        type="password"
        name="password"
        id="password"
        [(ngModel)]="password"
        placeholder="Password"
      />
      <p class="error" *ngIf="error">{{ error }}</p>
      <a class="resend" *ngIf="notVerified && !!resendToEmail" (click)="resendActivationEmail()"
        >Resend verification email</a
      >
      <button class="button centerPage__button" type="submit" [disabled]="!email || !password || submittingForm">
        <span *ngIf="!submittingForm">Login</span>
        <i class="fas fa-circle-notch fa-spin" *ngIf="submittingForm"></i>
      </button>
    </form>
    <a [routerLink]="['/forgot-password']" class="buttonText centerPage__link">Forgot Password?</a>
    <p class="centerPage__p">Don't have an account? <a class="buttonText" [routerLink]="['/sign-up']">Sign Up</a></p>
  </div>
</div>
