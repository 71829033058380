<div class="ssContainer">
  <h1>Activity List</h1>
  <div class="spinnerContainer" *ngIf="!userLoaded">
    <div class="ssSpinner"></div>
  </div>
  <div class="tableWrap" *ngIf="userLoaded">
    <div class="tableWrap__inner">
      <table>
        <tbody *ngIf="investmentActivitiesLoaded">
          <tr *ngFor="let activity of investmentActivities">
            <td>
              <a class="table__flex" [routerLink]="['/reports', 'deal', activity.investment.deal.slug]">
                <img src="{{ activity.investment.deal.logo.url }}" class="table__logo" />
                <span class="table__link">{{ activity.investment.deal.name }}</span>
              </a>
            </td>
            <td>{{ activity.message }}</td>
            <td>
              <p class="fineprint fineprint--right">
                {{ moment(activity.created).fromNow() }} ({{
                  moment(activity.created).format('MMM D, YYYY • hh:mm A')
                }})
              </p>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
</div>
