import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalService } from '../../../modules/modals';
import { ToastService } from '../../../modules/toast';
import { FundingSourceModalComponent, ThanksModalComponent, WireInstructionsModalComponent } from '../../../components';
import { AuthService, GQLService, PlaidService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';
import * as investQueries from '../../../queries/invest-queries';
import * as profileQueries from '../../../queries/profile-queries';

@Component({
  selector: 'app-invest-payment',
  templateUrl: './invest-payment.component.html',
  styleUrls: ['./invest-payment.component.scss'],
})
export class InvestPaymentComponent implements OnInit, OnDestroy {
  public user = null;
  public dealSlug = null;
  public deal = null;
  public loadingDeal = true;

  public transactionLimits = {
    personal: 5000,
    entity: 10000,
  };

  public paymentMethod = '';
  public clickedInvest = false;

  public sourceDropdownOpen = false;
  public selectedFundingSource = null;
  public fundingSources = [];

  private userSub = null;
  private paramsSub = null;
  private plaidSuccessSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private modals: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService,
    private plaid: PlaidService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.dealSlug = params.slug;
      this.userSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.getDeal(true).then((deal: any) => {
          this.deal = deal;
          this.getInvestmentProfiles();
          if (this.myInvestment) {
            if (this.myInvestment.stage === 'Provide Details') {
              this.router.navigate(['/invest', this.deal.slug, 'profile']);
            }
            if (this.myInvestment.stage === 'Sign Docs') {
              this.router.navigate(['/invest', this.deal.slug, 'docs']);
            }
            if (this.myInvestment.stage === 'Finalize Docs') {
              this.router.navigate(['/invest', this.deal.slug, 'finalize']);
            }
            // if (this.myInvestment.stage === 'Transfer Pending') {
            //   this.router.navigate(['/deal', this.deal.slug]);
            //   this.toast.error('You have already completed an investment for this deal.');
            // }
          } else {
            this.router.navigate(['/invest', this.deal.slug, 'details']);
          }
          if (this.isACH || this.isWire) {
            this.paymentMethod = this.myInvestment.paymentType;
          }
          if (!this.isACH && !this.isWire) {
            // TODO: Remove when ACH is enabled
            this.paymentMethod = 'wire';
            this.setPaymentMethod();
          }
          this.gql.query(investQueries.transactionLimitsQuery).then((limitsQuery: any) => {
            this.transactionLimits = limitsQuery.data.transactionLimits;
          });
        });
      });
    });
    this.plaidSuccessSub = this.plaid.onSuccessSubject.subscribe(
      (success: any) => {
        let fundingModalSub = this.modals
          .open(FundingSourceModalComponent, {
            animation: 'scaleIn .6s',
            backdrop: true,
            closeDisabled: true,
            data: {
              profileId: success.profileId,
              public_token: success.public_token,
              metadata: success.metadata,
            },
            width: '700px',
          })
          .subscribe(
            () => {
              this.getInvestmentProfiles();
            },
            () => {},
            () => {
              fundingModalSub.unsubscribe();
            }
          );
      },
      () => {},
      () => {}
    );
  }

  public ngOnDestroy() {
    if (this.userSub) {
      this.userSub.unsubscribe();
    }
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
    if (this.plaidSuccessSub) {
      this.plaidSuccessSub.unsubscribe();
    }
  }

  public getDeal(doLoad = false) {
    if (doLoad) {
      this.loadingDeal = true;
    }
    let promise = new Promise((resolve, reject) => {
      this.gql
        .query(dealQueries.dealQuery, { slug: this.dealSlug })
        .then((query: any) => resolve(query.data.deal))
        .catch((errs) => reject(errs))
        .finally(() => {
          this.loadingDeal = false;
        });
    });
    return promise;
  }

  public getInvestmentProfiles() {
    this.gql.query(profileQueries.investmentProfilesQuery).then((query: any) => {
      this.fundingSources = query.data.me.investmentProfiles
        .filter((obj) => obj.isActive)
        .map((obj) => obj.fundingSources)
        .flat();
      if (this.fundingSources.length > 0) {
        this.selectedFundingSource = this.fundingSources[0];
      }
    });
  }

  public get myInvestment() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }

  public achDisabled() {
    return (
      (this.myInvestment.profile.profileType === 'person' &&
        this.myInvestment.amount >= this.transactionLimits.personal) ||
      (this.myInvestment.profile.profileType === 'entity' && this.myInvestment.amount >= this.transactionLimits.entity)
    );
  }

  public get isACH() {
    return this.myInvestment && this.myInvestment.paymentType === 'ach';
  }

  public get isWire() {
    return this.myInvestment && this.myInvestment.paymentType === 'wire';
  }

  public setPaymentMethod() {
    this.gql
      .mutation(investQueries.selectPaymentMethodMutation, {
        id: this.myInvestment.pk,
        paymentType: this.paymentMethod,
      })
      .then(() => {
        this.getDeal().then((deal: any) => {
          this.deal = deal;
        });
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public openWireInstructions() {
    let modalSub = this.modals
      .open(WireInstructionsModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        data: {
          investment: this.myInvestment,
        },
        width: '650px',
      })
      .subscribe(
        () => {},
        () => {},
        () => {
          modalSub.unsubscribe();
        }
      );
  }

  public addFundingSource() {
    this.plaid.open(this.myInvestment.profile.id);
  }

  public initiateTransferDisabled() {
    return this.clickedInvest || !this.myInvestment || !this.selectedFundingSource;
  }

  public initiateTransfer() {
    this.clickedInvest = true;
    this.gql
      .mutation(investQueries.initiateAchTransferMutation, {
        id: this.myInvestment.pk,
        fundingSource: this.selectedFundingSource.id,
      })
      .then((transferQuery: any) => {
        let modalSub = this.modals
          .open(ThanksModalComponent, {
            animation: 'scaleIn .6s',
            backdrop: true,
            closeDisabled: false,
            data: {
              deal: this.myInvestment.deal,
            },
            width: '900px',
          })
          .subscribe(
            () => {},
            () => {},
            () => {
              modalSub.unsubscribe();
            }
          );
        this.router.navigate(['/dashboard']);
      })
      .catch((transferErrs) => {
        this.clickedInvest = false;
        this.toast.gqlErrors(transferErrs);
      })
      .finally(() => {
        this.clickedInvest = false;
      });
  }
}
