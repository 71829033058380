import { environment } from '../environments/environment';

export class AppConfig {
  public static baseUrl = environment.apiHost;
  public static apiUrl = AppConfig.createUrl('api');

  public static createUrl(str: string): string {
    return `${this.baseUrl}${str}/`;
  }

  public static createWithApiUrl(str: string): string {
    return `${this.baseUrl}api/${str}/`;
  }

  public static documentDownloadUrl(signnowId: string): string {
    return `${this.baseUrl}documents/signnow/download-document/${signnowId}/`;
  }

  public static downloadApplicationUrl(applicationId: string): string {
    return `${this.baseUrl}documents/signnow/download-application/${applicationId.substring(0, 8)}/`;
  }

  public static downloadDueDiligenceUrl(investmentId: string): string {
    return `${this.baseUrl}investments/investment/${investmentId}/download-due-diligence/ `;
  }
}
