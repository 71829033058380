import { flatMap, map, catchError } from 'rxjs/operators';
import {
  of as observableOf,
  throwError as observableThrowError,
  combineLatest as observableCombineLatest,
  Observable,
} from 'rxjs';
import { AppConfig } from '../app.config';
import { HttpService } from './http.service';

interface ListResponse {
  results: any[];
  count: number;
  previous: string;
  next: string;
}

export class Store {
  private http: HttpService;
  private endpoint: string;

  constructor(http: HttpService, endpoint: string) {
    this.http = http;
    this.endpoint = endpoint;
  }

  public create(payload: any): Observable<any> {
    let request = this.http.post(this.createUrl(), payload);
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public read(id: number | string): Observable<any> {
    let request = this.http.get(this.createUrl(id));
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public readList(params = {}): Observable<any> {
    let request = this.http.get(this.createUrl(), params);
    return request.pipe(
      map((response: any) => {
        // const results = Object.assign(response.results, {
        //   count: response.count,
        //   next: response.next,
        //   previous: response.previous
        // });
        return response;
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public readListPaged(params = {}): Observable<any> {
    let request = this.http.get(this.createUrl(), params);
    return request.pipe(
      flatMap((firstPage: ListResponse) => {
        let pageObservables: Observable<any>[] = [observableOf(firstPage.results)];
        // construct each page url for each existing page, starting at 2
        if (firstPage.next) {
          for (let i = 2; i <= Math.ceil(firstPage.count / firstPage.results.length); i++) {
            const page = this.http
              .get(this.createUrl(), Object.assign(params, { page: i }))
              .pipe(map((pageResponse: ListResponse) => pageResponse.results));
            pageObservables.push(page);
          }
        }
        return observableCombineLatest(pageObservables).pipe(
          map((nested) => nested.reduce((acc, cur) => acc.concat(cur), [])),
          catchError((error: any) => {
            return observableThrowError(error);
          })
        );
      }),
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public update(id: number | string, payload: any, patch = true): Observable<any> {
    let request = null;
    if (patch) {
      request = this.http.patch(this.createUrl(id), payload);
    } else {
      request = this.http.put(this.createUrl(id), payload);
    }
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public destroy(id: number | string): Observable<any> {
    let request = this.http.delete(this.createUrl(id));
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public detailRoute(method: string, id: number | string, route: string, payload = {}, params = {}) {
    let request = this.http.request(method, `${this.createUrl(id)}${route}/`, payload, params);
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  public listRoute(method: string, route: string, payload = {}, params = {}) {
    let request = this.http.request(method, `${this.createUrl()}${route}/`, payload, params);
    return request.pipe(
      catchError((error: any) => {
        return observableThrowError(error);
      })
    );
  }

  private createUrl(id: number | string = null): string {
    if (id) {
      return `${AppConfig.apiUrl}${this.endpoint}/${id}/`;
    } else {
      return `${AppConfig.apiUrl}${this.endpoint}/`;
    }
  }
}
