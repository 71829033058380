import gql from 'graphql-tag';

/**
 * Queries
 **/

export const investmentProfilesQuery = gql`
  query {
    me {
      investmentProfiles {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        fundingSources {
          id
          name
          bankName
        }
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const investmentProfileAvailableDealsQuery = gql`
  query investmentProfile($id: ID!) {
    investmentProfile(id: $id) {
      availableDeals {
        id
        pk
        slug
        created
        modified
        name
        logo {
          name
          url
        }
        status
        minimumInvestment
        dealType
        deadline
        round
        roundSize
        vehicle
        cap
        hasProRataRights
        allocation
        carry
        setupCost
        publishDatetime
      }
    }
  }
`;

export const investmentProfileSSNQuery = gql`
  query investmentProfile($id: ID!) {
    investmentProfile(id: $id) {
      ssnLast4
    }
  }
`;

export const investmentProfileQuestionnaireQuery = gql`
  query investmentProfile($id: ID!) {
    investmentProfile(id: $id) {
      latestApplication {
        embeddedSigningLink
      }
    }
  }
`;

export const businessClassificationsQuery = gql`
  query {
    businessClassifications {
      id
      name
      industryClassifications {
        id
        name
      }
    }
  }
`;

export const plaidLinkTokenQuery = gql`
  query {
    me {
      plaidLinkToken
    }
  }
`;

export const myInvestmentsQuery = gql`
  query myInvestments($status: String, $profile: ID) {
    me {
      investments(status: $status, profile: $profile) {
        edges {
          node {
            deal {
              id
              pk
              name
              slug
              logo {
                name
                url
              }
              deadline
              status
            }
            wireTransfer {
              id
              status
              referenceNumber
              wireDestination {
                bank {
                  name
                  routingNumber
                  swiftCode
                  phoneNumber
                }
                accountName
                accountNumber
              }
            }
            dealDocuments {
              edges {
                node {
                  id
                  pk
                  signnowId
                  datetimeSubmitted
                  template {
                    name
                  }
                }
              }
            }
            fundingSource {
              id
              name
              bankName
              customer {
                id
                legalName
              }
            }
            profile {
              legalName
              profileType
            }
            activities {
              id
              created
              actionType
              document {
                id
                pk
                template {
                  id
                  pk
                  name
                }
                datetimeSubmitted
              }
              message
            }
            id
            pk
            amount
            purchasePrice
            stage
            paymentType
          }
        }
      }
    }
  }
`;

/**
 * Mutations
 **/

export const createPersonalProfileMutation = gql`
  mutation createPersonalProfile(
    $address1: String!
    $address2: String
    $birthdate: Date!
    $city: String!
    $postalCode: String!
    $ssn: String!
    $state: String!
  ) {
    createPersonalProfile(
      address1: $address1
      address2: $address2
      birthdate: $birthdate
      city: $city
      postalCode: $postalCode
      ssn: $ssn
      state: $state
    ) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const updatePersonalProfileMutation = gql`
  mutation updatePersonalProfile(
    $address1: String
    $address2: String
    $city: String
    $id: ID!
    $postalCode: String
    $state: String
  ) {
    updatePersonalProfile(
      address1: $address1
      address2: $address2
      city: $city
      id: $id
      postalCode: $postalCode
      state: $state
    ) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const createEntityProfileMutation = gql`
  mutation createEntityProfile(
    $address1: String!
    $address2: String
    $birthdate: Date!
    $businessType: String!
    $city: String!
    $ein: String!
    $industryClassification: UUID!
    $industryClassificationName: String!
    $legalName: String!
    $postalCode: String!
    $ssn: String!
    $state: String!
  ) {
    createEntityProfile(
      address1: $address1
      address2: $address2
      birthdate: $birthdate
      businessType: $businessType
      city: $city
      ein: $ein
      industryClassification: $industryClassification
      industryClassificationName: $industryClassificationName
      legalName: $legalName
      postalCode: $postalCode
      ssn: $ssn
      state: $state
    ) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const updateEntityProfileMutation = gql`
  mutation updateEntityProfile(
    $address1: String
    $address2: String
    $businessType: String
    $city: String
    $ein: String
    $id: ID!
    $legalName: String
    $postalCode: String
    $state: String!
  ) {
    updateEntityProfile(
      address1: $address1
      address2: $address2
      businessType: $businessType
      city: $city
      ein: $ein
      id: $id
      legalName: $legalName
      postalCode: $postalCode
      state: $state
    ) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const deactivateInvestmentProfileMutation = gql`
  mutation deactivateInvestmentProfile($id: ID!) {
    deactivateInvestmentProfile(id: $id) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const activateInvestmentProfileMutation = gql`
  mutation activateInvestmentProfile($id: ID!) {
    activateInvestmentProfile(id: $id) {
      profile {
        id
        created
        modified
        address1
        address2
        city
        state
        postalCode
        legalName
        profileType
        birthdate
        ein
        businessType
        industryClassification
        industryClassificationName
        isActive
        status
        latestApplication {
          id
          documentId
          documentName
          isCompleted
          isAccepted
          isRejected
          rejectedReason
        }
      }
    }
  }
`;

export const createProfileApplicationMutation = gql`
  mutation createProfile($profile: ID!) {
    createProfileApplication(profile: $profile) {
      application {
        id
        documentId
        documentName
        isCompleted
        isAccepted
        isRejected
        rejectedReason
      }
    }
  }
`;

export const syncApplicationDocumentMutation = gql`
  mutation syncApplicationDocument($documentId: ID!) {
    syncApplicationDocument(documentId: $documentId) {
      application {
        id
        documentId
        documentName
        isCompleted
        isAccepted
        isRejected
        rejectedReason
      }
    }
  }
`;
