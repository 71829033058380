import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { GQLService } from '../../../services';
import * as profileQueries from '../../../queries/profile-queries';

@Component({
  selector: 'app-application',
  templateUrl: './application.component.html',
  styleUrls: ['./application.component.scss'],
})
export class ApplicationComponent implements OnInit, OnDestroy {
  public id = null;
  public syncSuccess = false;
  public retryEnabled = false;

  private paramsSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      if (!params.id) {
        this.router.navigate(['/error']);
      }
      this.id = params.id;
      this.syncApplicationDocument();
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public syncApplicationDocument() {
    this.syncSuccess = false;
    this.retryEnabled = false;
    this.gql
      .mutation(profileQueries.syncApplicationDocumentMutation, {
        documentId: this.id,
      })
      .then(() => {
        this.syncSuccess = true;
        this.retryEnabled = false;
      })
      .catch((errs: any) => {
        this.syncSuccess = false;
        this.retryEnabled = true;
        this.toast.gqlErrors(errs);
      });
  }

  public clickClose() {
    window.top.postMessage({ type: 'closeModal' }, '*');
  }
}
