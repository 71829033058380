export * from './accelerator/accelerator.component';
export * from './dashboard/dashboard.component';
export * from './doc-redirect/doc-redirect.component';
export * from './error/error.component';
export * from './home/home.component';
export * from './settings/settings.component';
// Admin
export * from './admin/activity-list/activity-list.component';
export * from './admin/deal-detail/deal-detail.component';
export * from './admin/deal-list/deal-list.component';
export * from './admin/investment-detail/investment-detail.component';
export * from './admin/investor-detail/investor-detail.component';
export * from './admin/investor-list/investor-list.component';
// Auth
export * from './auth/forgot-password/forgot-password.component';
export * from './auth/login/login.component';
export * from './auth/privacy-policy/privacy-policy.component';
export * from './auth/reset-password/reset-password.component';
export * from './auth/sign-up/sign-up.component';
export * from './auth/tos/tos.component';
export * from './auth/verify/verify.component';
// Embed
export * from './embed/application/application.component';
export * from './embed/document/document.component';
// Investing
export * from './investing/deal/deal.component';
export * from './investing/invest-details/invest-details.component';
export * from './investing/invest-docs/invest-docs.component';
export * from './investing/invest-payment/invest-payment.component';
export * from './investing/invest-profile/invest-profile.component';
export * from './investing/invest-verify/invest-verify.component';
export * from './investing/manager-doc/manager-doc.component';
