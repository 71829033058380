import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {
  // Admin Routes
  ActivityListComponent,
  DealDetailComponent,
  DealListComponent,
  InvestmentDetailComponent,
  InvestorDetailComponent,
  InvestorListComponent,
  // Auth Routes
  ForgotPasswordComponent,
  LoginComponent,
  PrivacyPolicyComponent,
  ResetPasswordComponent,
  SignUpComponent,
  TOSComponent,
  VerifyComponent,
  // Embed Routes
  ApplicationComponent,
  DocumentComponent,
  // Investing Routes
  DealComponent,
  InvestDetailsComponent,
  InvestDocsComponent,
  InvestPaymentComponent,
  InvestProfileComponent,
  InvestVerifyComponent,
  ManagerDocComponent,
  // Other Routes
  AcceleratorComponent,
  DashboardComponent,
  DocRedirectComponent,
  ErrorComponent,
  HomeComponent,
  SettingsComponent,
} from './routes';
import { CanActivateViaAuthGuard, RedirectHomeIfLoggedIn, IsAdminAuthGuard } from './services';

const routes: Routes = [
  // Admin Routes

  {
    path: 'reports/investment-activity',
    component: ActivityListComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: { title: 'Investment Activity' },
  },
  {
    path: 'reports/deal/:slug',
    component: DealDetailComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: {
      title: 'Deal Report',
    },
  },
  {
    path: 'reports/deal-list',
    component: DealListComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: {
      title: 'Deal List',
    },
  },
  {
    path: 'reports/investment-detail',
    component: InvestmentDetailComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: {
      title: 'Investment Detail',
    },
  },
  {
    path: 'reports/investor/:id',
    component: InvestorDetailComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: {
      title: 'Investor Detail',
    },
  },
  {
    path: 'reports/investor-list',
    component: InvestorListComponent,
    // canActivate: [CanActivateViaAuthGuard, IsAdminAuthGuard],
    data: {
      title: 'Investor List',
    },
  },

  // Auth Routes

  {
    path: 'forgot-password',
    component: ForgotPasswordComponent,
    canActivate: [RedirectHomeIfLoggedIn],
    data: { title: 'Forgot Password' },
  },
  {
    path: 'login',
    component: LoginComponent,
    canActivate: [RedirectHomeIfLoggedIn],
    data: { title: 'Login' },
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent,
    data: { title: 'Privacy Policy' },
  },
  {
    path: 'reset-pass/:token',
    component: ResetPasswordComponent,
    canActivate: [RedirectHomeIfLoggedIn],
    data: { title: 'Reset Password' },
  },
  {
    path: 'sign-up',
    component: SignUpComponent,
    canActivate: [RedirectHomeIfLoggedIn],
    data: { title: 'Sign Up' },
  },
  {
    path: 'tos',
    component: TOSComponent,
    data: { title: 'Terms of Service' },
  },
  {
    path: 'verify-email/:token',
    component: VerifyComponent,
    canActivate: [RedirectHomeIfLoggedIn],
    data: { title: 'Account Verified' },
  },

  // Embed Routes

  {
    path: 'application/:id',
    component: ApplicationComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Application',
    },
  },
  {
    path: 'document/:id',
    component: DocumentComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Document',
    },
  },

  // Investing Routes

  {
    path: 'deal/:slug',
    component: DealComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Deal',
    },
  },
  {
    path: 'invest/:slug/details',
    component: InvestDetailsComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Invest: Details',
    },
  },
  {
    path: 'invest/:slug/docs',
    component: InvestDocsComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Invest: Docs',
    },
  },
  {
    path: 'invest/:slug/payment',
    component: InvestPaymentComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Invest: Payment',
    },
  },
  {
    path: 'invest/:slug/profile',
    component: InvestProfileComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Invest: Profile',
    },
  },
  {
    path: 'invest/:slug/finalize',
    component: InvestVerifyComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Invest: Finalize',
    },
  },
  {
    path: 'manager-doc/:id',
    component: ManagerDocComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Sign Document',
    },
  },

  // Other Routes

  {
    path: 'accelerator',
    component: AcceleratorComponent,
    data: {
      title: 'Accelerator',
    },
  },
  {
    path: 'dashboard',
    component: DashboardComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'My Dashboard',
    },
  },
  {
    path: 'docs/:id',
    component: DocRedirectComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Downloading Document',
    },
  },
  {
    path: 'diligence/:id',
    component: DocRedirectComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Downloading Document',
    },
  },
  {
    path: '',
    component: HomeComponent,
  },
  {
    path: 'settings',
    component: SettingsComponent,
    canActivate: [CanActivateViaAuthGuard],
    data: {
      title: 'Settings',
    },
  },
  {
    path: '**',
    component: ErrorComponent,
    data: { title: 'Page Not Found' }, // because title str should differ from path str
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
