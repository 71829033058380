import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { BehaviorSubject } from 'rxjs';
import { tap } from 'rxjs/operators';
import { Apollo } from 'apollo-angular';
import { BannerService } from './banner.service';
import { GQLService } from './gql.service';
import { LocalStorageService } from './storage.service';
import * as authQueries from '../queries/auth-queries';

@Injectable()
export class AuthService {
  private user: any = null;
  private userSubject$ = new BehaviorSubject<any>(null);
  public user$ = this.userSubject$.asObservable();

  constructor(
    private apollo: Apollo,
    private banners: BannerService,
    private gql: GQLService,
    private storage: LocalStorageService
  ) {
    // Verify auth token.
    // If success, get the user.
    // If error, refresh token and then get user.
    if (this.getAuthToken()) {
      this.verifyToken(this.getAuthToken())
        .then(() => {
          this.getUser().subscribe();
        })
        .catch(() => {
          this.refreshToken(this.getRefreshToken())
            .then((refreshToken: any) => {
              let response = refreshToken.data.refreshToken;
              this.setAuthToken(response.token);
              this.setRefreshToken(response.refreshToken);
              this.getUser().subscribe();
            })
            .catch(() => {
              this.logout();
            });
        });
    }
    // Create timer to verify auth token every 5 minutes.
    // If success, do nothing.
    // If error, refresh token.
    setInterval(() => {
      if (this.getAuthToken()) {
        this.verifyToken(this.getAuthToken()).catch(() => {
          this.refreshToken(this.getRefreshToken())
            .then((refreshToken: any) => {
              let response = refreshToken.data.refreshToken;
              this.setAuthToken(response.token);
              this.setRefreshToken(response.refreshToken);
            })
            .catch(() => {
              this.logout();
            });
        });
      }
    }, 5 * 60 * 1000);
  }

  public register(
    email: string,
    firstName: string,
    lastName: string,
    phone: string,
    password1: string,
    password2: string
  ) {
    return this.gql.mutation(authQueries.registerMutation, {
      email,
      firstName,
      lastName,
      phone,
      password1,
      password2,
    });
  }

  public verifyAccount(token: string) {
    return this.gql.mutation(authQueries.verifyAccountMutation, {
      token,
    });
  }

  public resendActivationEmail(email: string) {
    return this.gql.mutation(authQueries.resendActivationEmailMutation, {
      email,
    });
  }

  public sendPasswordResetEmail(email: string) {
    return this.gql.mutation(authQueries.sendPasswordResetEmailMutation, {
      email,
    });
  }

  public passwordReset(token: string, newPassword1: string, newPassword2: string) {
    return this.gql.mutation(authQueries.passwordResetMutation, {
      token,
      newPassword1,
      newPassword2,
    });
  }

  public passwordChange(oldPassword: string, newPassword1: string, newPassword2: string) {
    return this.gql.mutation(authQueries.passwordChangeMutation, {
      oldPassword,
      newPassword1,
      newPassword2,
    });
  }

  public tokenAuth(email: string, password: string) {
    return this.gql.mutation(authQueries.tokenAuthMutation, {
      email,
      password,
    });
  }

  public verifyToken(token: any) {
    return this.gql.mutation(authQueries.verifyTokenMutation, {
      token,
    });
  }

  public refreshToken(refreshToken: any) {
    return this.gql.mutation(authQueries.refreshTokenMutation, {
      refreshToken,
    });
  }

  public revokeToken(refreshToken: any) {
    return this.gql.mutation(authQueries.revokeTokenMutation, {
      refreshToken,
    });
  }

  public updateAccount(firstName: string, lastName: string, phone: string) {
    let variables: any = {};
    if (firstName) {
      variables.firstName = firstName;
    }
    if (lastName) {
      variables.lastName = lastName;
    }
    if (phone) {
      variables.phone = phone;
    }
    return this.gql.mutation(authQueries.updateAccountMutation, variables);
  }

  public getUser() {
    return this.apollo
      .query({
        query: authQueries.meQuery,
        fetchPolicy: 'no-cache',
      })
      .pipe(
        tap((me: any) => {
          let userData = me.data.me;
          this.user = userData;
          this.userSubject$.next(userData);
        })
      );
  }

  public login(email: string, password: string) {
    let promise = new Promise((resolve, reject) => {
      this.tokenAuth(email, password)
        .then((tokenAuth: any) => {
          this.setAuthToken(tokenAuth.data.tokenAuth.token);
          this.setRefreshToken(tokenAuth.data.tokenAuth.refreshToken);
          this.user = tokenAuth.data.tokenAuth.user;
          this.userSubject$.next(tokenAuth.data.tokenAuth.user);
          resolve(tokenAuth);
        })
        .catch((err) => {
          reject(err);
        });
    });
    return promise;
  }

  public logout() {
    this.clearTokens();
    this.user = null;
    this.userSubject$.next(this.user);
  }

  public getAuthToken() {
    return this.storage.getItem('jwt');
  }

  public setAuthToken(token: string) {
    this.storage.setItem('jwt', token);
  }

  public getRefreshToken() {
    return this.storage.getItem('refreshToken');
  }

  public setRefreshToken(refreshToken: string) {
    this.storage.setItem('refreshToken', refreshToken);
  }

  public clearTokens() {
    this.storage.removeItem('jwt');
    this.storage.removeItem('refreshToken');
    this.storage.removeItem('dismissConfidential');
    this.banners.clear();
  }

  public isLoggedIn(): boolean {
    return !!this.getAuthToken();
  }

  public isAdmin(): boolean {
    return this.user && this.user.isAdmin;
  }
}

@Injectable()
export class CanActivateViaAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  public canActivate() {
    let worthy = this.auth.isLoggedIn();
    if (!worthy) {
      this.router.navigate(['/login']);
    }
    return worthy;
  }
}

@Injectable()
export class RedirectHomeIfLoggedIn implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  public canActivate() {
    let worthy = !this.auth.isLoggedIn();
    if (!worthy) {
      this.router.navigate(['/']);
    }
    return worthy;
  }
}

@Injectable()
export class IsAdminAuthGuard implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  public canActivate() {
    let worthy = this.auth.isAdmin();
    if (!worthy) {
      this.router.navigate(['/']);
    }
    return worthy;
  }
}
