<!-- Sign up -->
<ng-container *ngIf="!showVerifyEmail">
  <div class="ssContainer">
    <h1 class="centerPage__title">Sign Up</h1>
    <div class="centerPage">
      <form (submit)="signUp()">
        <input
          class="input centerPage__input"
          type="text"
          name="firstName"
          id="firstName"
          [(ngModel)]="firstName"
          placeholder="First Name"
        />
        <input
          class="input centerPage__input"
          type="text"
          name="lastName"
          id="lastName"
          [(ngModel)]="lastName"
          placeholder="Last Name"
        />
        <input
          class="input centerPage__input"
          type="email"
          name="email"
          id="email"
          [(ngModel)]="email"
          placeholder="Email"
        />
        <input
          class="input centerPage__input"
          type="tel"
          name="phone"
          id="phone"
          mask="000-000-0000"
          [(ngModel)]="phone"
          placeholder="Mobile Phone"
        />
        <input
          class="input centerPage__input"
          type="password"
          name="password1"
          id="password1"
          [(ngModel)]="password1"
          placeholder="Password"
        />
        <input
          class="input centerPage__input"
          type="password"
          name="password2"
          id="password2"
          [(ngModel)]="password2"
          placeholder="Password (Again)"
        />
        <h6 class="hint">
          Passwords must be at least 12 characters, with at least one number, at least one letter, and at least one
          symbol.
        </h6>
        <label for="tos">
          <input type="checkbox" name="tos" [(ngModel)]="tosAgreement" />
          By checking this box you agree to <a href="/tos" target="_blank">Our Terms of Service</a> and
          <a href="/privacy-policy" target="_blank">Privacy Policy</a>, as well as our partner
          <a href="https://www.dwolla.com/legal/tos/" target="_blank">Dwolla's Terms of Service</a> and
          <a href="https://www.dwolla.com/legal/privacy/" target="_blank">Privacy Policy</a>.
        </label>
        <p class="error" *ngIf="error">{{ error }}</p>
        <button type="submit" [disabled]="signUpDisabled()" class="button centerPage__button">Agree & Continue</button>
      </form>
      <p class="centerPage__p">Already have an account?</p>
      <a [routerLink]="['/login']" class="buttonText">Log In</a>
    </div>
  </div>
</ng-container>

<!-- Verify email step -->
<ng-container *ngIf="showVerifyEmail">
  <div class="ssContainer">
    <h1 class="centerPage__title">Almost there!</h1>
    <div class="centerPage">
      <p class="signup__almost">
        Please check your email<br />
        <strong>{{ email }}</strong
        ><br />
        to verify your account.
      </p>
      <button class="button centerPage__button" (click)="resendActivationEmail()">Resend verification email</button>
    </div>
  </div>
</ng-container>
