<div class="ssContainer">
  <h1 *ngIf="!error">Success!</h1>
  <h1 *ngIf="error && !alreadyVerified">Error!</h1>
  <h3 class="verified__h3" *ngIf="!error">Your account has been verified.</h3>
  <h3 class="verified__h3" *ngIf="error" [ngStyle]="{ 'margin-top': alreadyVerified ? '64px' : '0px' }">
    {{ error.message }}
  </h3>
</div>
<!-- <div class="verified__blue" *ngIf="!error">
  <div class="verified__container">
    <h3 class="verified__h3 verified__h3--light">Real quick: Please tell us about yourself.</h3>
    <label for="accCheck">
      <input type="checkbox" id="accCheck" name="accCheck" />
      I'm an accredited investor (or an authorized agent thereof) and I'm interested in seeing investment opportunities.
    </label>
    <h6 class="hint verified__hint">
      Important: if you don't check this box, we won't be able to share deal information with you.
    </h6>
    <p class="verified__p">
      (If you're not an accredited investor, that's OK. There are still a number of things you can do on the site.)
    </p>
  </div>
</div> -->
<div class="ssContainer" *ngIf="!error">
  <div class="centerPage">
    <button type="button" class="button centerPage__button" (click)="routeToLogin()">Go To Login</button>
  </div>
</div>
<div class="ssContainer" *ngIf="error && alreadyVerified">
  <div class="centerPage">
    <button type="button" class="button centerPage__button" (click)="routeToLogin()">Login</button>
  </div>
</div>
