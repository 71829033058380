import { Component, OnInit, OnDestroy } from '@angular/core';
import { PopoverOptions } from '../../../modules/popover';

@Component({
  selector: 'app-investment-detail',
  templateUrl: './investment-detail.component.html',
  styleUrls: ['./investment-detail.component.scss'],
})
export class InvestmentDetailComponent implements OnDestroy, OnInit {
  public commentMenuOpen = false;
  public commentMenuOptions: PopoverOptions = {
    relativeTop: '48px',
    relativeLeft: '0px',
  };

  constructor() {}

  public ngOnInit() {}

  public ngOnDestroy() {}
}
