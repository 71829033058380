<div class="ssContainer">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/reports/deal-detail']">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal Report</span>
    </a>
    <div class="backWrapper__header">
      <h1>Investment Detail</h1>
    </div>
  </div>
  <div class="logoHeader">
    <h3 class="logoHeader__name">Roger Stevenson</h3>
    <i class="logoHeader__icon fa fa-angle-double-right fa-fw"></i>
    <div class="logoHeader__logoContainer">
      <img src="../../assets/img/artifact-logo-small.png" class="logoHeader__logo" />
      <h3 class="logoHeader__company">Artifact</h3>
    </div>
    <div>sidecar deal</div>
  </div>
  <div class="dealStats">
    <div class="stat">
      <label>Profile</label>
      <p>Individual Profile<br />Central …3987</p>
    </div>
    <div class="stat">
      <label>Phone</label>
      <p>555-555-5555</p>
    </div>
    <div class="stat stat--mobileFull">
      <label>Email</label>
      <p>roger@stevensonllc.org</p>
    </div>
    <div class="stat">
      <label>Amount</label>
      <p>$9,000</p>
    </div>
    <div class="stat">
      <label>Stage</label>
      <p>Pledged</p>
    </div>
  </div>
  <div class="detailsList">
    <div class="detailsList__item">
      <div class="detailsList__item__container">
        <i class="fa fa-comment fa-fw detailsList__item__icon"></i>
        <div class="detailsList__item__text">
          <p>
            Chris Employee commented, "I emailed Roger today to check on some investment details. Follow up on this.
          </p>
        </div>
        <div class="detailsList__item__spacer"></div>
        <div class="detailsList__item__buttons">
          <button class="iconButton iconButton--48" (click)="commentMenuOpen = true">
            <i class="fa fa-ellipsis-v fa-fw"></i>
          </button>
          <button class="iconButton iconButton--48"><i class="fa fa-flag fa-fw"></i></button>
          <app-popover
            [options]="commentMenuOptions"
            [visible]="commentMenuOpen"
            (visibleChange)="commentMenuOpen = $event"
          >
            <div class="commentMenu">
              <div class="commentMenu__option">Edit</div>
              <div class="commentMenu__option">Delete</div>
            </div>
          </app-popover>
        </div>
      </div>
      <p class="detailsList__item__timestamp mutedText">3 days ago (Jul 3, 2020 • 4:37 PM)</p>
    </div>
    <div class="detailsList__item">
      <div class="detailsList__item__container">
        <div class="detailsList__item__text">
          <p>Roger Stevenson requested $4000 from First National Bank …1234, via ACH.</p>
          <p class="detailsList__item__sub mutedText"><strong>Deal status updated:</strong> ACH Scheduled</p>
        </div>
        <div class="detailsList__item__spacer"></div>
      </div>
      <p class="detailsList__item__timestamp mutedText">6 days ago (Jul 6, 2020 • 1:02 PM)</p>
    </div>
    <div class="detailsList__item">
      <div class="detailsList__item__container">
        <div class="detailsList__item__text">
          <p>Chris Employee indicated ACH settled</p>
          <p class="detailsList__item__sub mutedText"><strong>Deal status updated:</strong> ACH Settled</p>
        </div>
        <div class="detailsList__item__spacer"></div>
      </div>
      <p class="detailsList__item__timestamp mutedText">6 days ago (Jul 6, 2020 • 1:02 PM)</p>
    </div>
  </div>
  <textarea class="input input--textarea comment" placeholder="Comment…"></textarea>
  <div class="comment__flag">
    <i class="fa fa-flag fa-fw"></i>
    <input type="checkbox" />
    <p>Flag this comment as needing follow-up.</p>
  </div>
  <button class="button comment__submit">Post</button>
</div>
