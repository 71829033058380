import { Component, OnInit, OnDestroy } from '@angular/core';
import { ModalService } from '../../modules/modals';
import { ContactModalComponent } from '../../components';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit, OnDestroy {
  public accelFootTipOpen = false;

  public user = null;

  private authSub = null;

  public constructor(private modals: ModalService) {}

  public ngOnInit() {}

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public openContactModal() {
    this.modals
      .open(ContactModalComponent, {
        animation: 'scaleIn .6s',
        backdrop: true,
        closeDisabled: false,
        width: '900px',
      })
      .subscribe();
  }
}
