import { Component, OnDestroy, OnInit } from '@angular/core';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AuthService } from '../../services';

@Component({
  selector: 'app-investor-status',
  templateUrl: './investor-status.modal.html',
  styleUrls: ['./investor-status.modal.scss'],
})
export class InvestorStatusModalComponent implements OnDestroy, OnInit {
  public data = null;
  public dataDefaults = {};
  public investorType = null;
  public accreditedQuestionOpen = false;
  public purchaserQuestionOpen = false;

  constructor(private modal: ModalService, private toast: ToastService, private auth: AuthService) {}

  public ngOnInit() {
    if (this.data && this.data.user) {
      this.investorType = this.data.user.investorType.toLowerCase();
      if (!this.investorType) {
        this.investorType = null;
      }
    }
  }

  public ngOnDestroy() {}

  public updateAccount() {
    // this.auth
    //   .updateAccount(null, null, this.investorType, null)
    //   .then(() => {
    //     this.modal.close(null);
    //   })
    //   .catch((errs) => {
    //     this.toast.gqlErrors(errs);
    //   });
  }
}
