import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ToastService } from '../modules/toast';
import { GQLService } from './gql.service';
import { LocalStorageService } from './storage.service';
import * as dealQueries from '../queries/deal-queries';
import * as profileQueries from '../queries/profile-queries';

@Injectable()
export class BannerService {
  public activeBanners = [];
  private availableDeals = [];
  private viewedDealIds = [];
  public inProgressSubject$ = new BehaviorSubject<any>([]);
  public inProgress$ = this.inProgressSubject$.asObservable();

  constructor(private toast: ToastService, private gql: GQLService, private localStorage: LocalStorageService) {
    this.viewedDealIds = this.localStorage.getObj('viewedDeals') || [];
  }

  public getAvailableDeals() {
    this.gql
      .query(dealQueries.availableDealsQuery, {
        isPublished: true,
      })
      .then((query: any) => {
        this.availableDeals = query.data.availableDeals.edges
          .map((obj: any) => {
            return obj.node;
          })
          .sort((a: any, b: any) => {
            return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
          });
        this.activeBanners = this.availableDeals.filter((deal) => {
          return !this.viewedDealIds.includes(deal.pk);
        });
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public dismiss(id: string | number) {
    let activeIdx = this.activeBanners.indexOf((obj) => {
      return obj.pk === id;
    });
    this.viewedDealIds.push(id);
    this.localStorage.setObj('viewedDeals', this.viewedDealIds);
    this.activeBanners.splice(activeIdx, 1);
  }

  public clear() {
    this.activeBanners = [];
    this.availableDeals = [];
    this.viewedDealIds = [];
    this.localStorage.removeItem('viewedDeals');
  }
}
