import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../modules/toast';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-verify',
  templateUrl: 'verify.component.html',
  styleUrls: ['./verify.component.scss'],
})
export class VerifyComponent implements OnInit, OnDestroy {
  public error = null;

  private paramsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private auth: AuthService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      if (params.token) {
        this.verifyAccount(params.token);
      }
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public get alreadyVerified() {
    return this.error.code && this.error.code === 'already_verified';
  }

  public verifyAccount(token: string) {
    this.error = '';
    this.auth
      .verifyAccount(token)
      .then(() => {
        // this.toast.success('Success! Your account has been verified. You can now log in.');
      })
      .catch((errors) => {
        if (errors.length > 0) {
          this.error = errors[0];
        }
      });
  }

  public routeToLogin() {
    this.router.navigate(['/login']);
  }
}
