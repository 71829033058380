import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'formatCurrency' })
/**
 * exp 9999 | formatCurrency
 * val: $9999
 *
 * exp: 10000 | formatCurrency
 * val: $10K
 *
 * exp: 1000000 | formatCurrency
 * val: 1M
 **/
export class CurrencyPipe implements PipeTransform {
  transform(value: any) {
    try {
      value = parseInt(value, 10);
    } catch (e) {}
    if (value >= 1000000) {
      return '$' + Math.round((value / 1000000) * 10) / 10 + 'M';
    }
    if (value >= 10000) {
      return '$' + Math.round(value / 1000) + 'K';
    }
    return '$' + Math.round(value);
  }
}
