import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { ToastService } from '../../../modules/toast';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-reset-password',
  templateUrl: 'reset-password.component.html',
  styleUrls: ['./reset-password.component.scss'],
})
export class ResetPasswordComponent implements OnInit, OnDestroy {
  public token = '';
  public password1 = '';
  public password2 = '';

  public error = '';

  private paramsSub: Subscription;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthService,
    private toast: ToastService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      if (params.token) {
        this.token = params.token;
      } else {
        this.router.navigate(['/forgot-password']);
      }
    });
  }

  public ngOnDestroy() {
    if (this.paramsSub) {
      this.paramsSub.unsubscribe();
    }
  }

  public updatePasswordDisabled() {
    return !this.password1 || !this.password2;
  }

  public updatePassword() {
    this.error = '';
    this.auth
      .passwordReset(this.token, this.password1, this.password2)
      .then(() => {
        this.router.navigate(['/login']);
        this.toast.success('Log in with your new password.');
      })
      .catch((errors: any) => {
        if (errors.length > 0) {
          this.error = errors[0].message;
        }
      });
  }
}
