<div class="confirm">
  <h4 *ngIf="data?.title" class="confirm__title">{{data?.title}}</h4>
  <hr class="divider" />
  <p class="confirm__body">{{data?.body}}</p>
  <div class="confirm__buttons">
    <button class="button" type="button" (click)="modal.close(data?.okText)">{{data?.okText}}</button>
    <button class="button button--light" type="button" (click)="modal.close(data?.cancelText)">
      {{data?.cancelText}}
    </button>
  </div>
</div>
