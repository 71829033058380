<div class="ssContainer" *ngIf="loadingDeal">
  <div class="spinnerContainer">
    <div class="ssSpinner"></div>
  </div>
</div>
<div class="ssContainer" *ngIf="!loadingDeal">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/deal', deal?.slug]">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Deal</span>
    </a>
    <h1 class="backWrapper__header">Invest in {{ deal?.name }}</h1>
  </div>
  <!--/.backWrapper-->
  <div class="centerPage">
    <div class="steps" *ngIf="deal">
      <a class="steps__step steps__step--1 steps__step--done"></a>
      <div class="steps__line steps__line--1"></div>
      <a class="steps__step steps__step--2 steps__step--done"></a>
      <div class="steps__line steps__line--2"></div>
      <a class="steps__step steps__step--3 steps__step--done"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--4 steps__step--active"></a>
      <div class="steps__line steps__line--3"></div>
      <a class="steps__step steps__step--5 steps__step--disabled"></a>
    </div>
    <!--/.steps-->
  </div>
  <div class="verifyBanner">
    <h3 class="verifyBanner__header">Documents Pending Approval</h3>
    <h4 class="verifyBanner__text">
      Thank you for submitting your subscription packet.<br /><br />
      If all goes well you should receive an email with wiring instructions shortly!
    </h4>
  </div>
  <!--/.centerPage-->
</div>
<!--/.ssContainer-->
