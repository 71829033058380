import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { AuthService, GQLService } from '../../../services';
import * as dealQueries from '../../../queries/deal-queries';

@Component({
  selector: 'app-invest-verify',
  templateUrl: './invest-verify.component.html',
  styleUrls: ['./invest-verify.component.scss'],
})
export class InvestVerifyComponent implements OnInit, OnDestroy {
  public user = null;
  public deal = null;
  public loadingDeal = null;

  private paramsSub = null;
  private authSub = null;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.paramsSub = this.route.params.subscribe((params) => {
      this.authSub = this.auth.user$.subscribe((user) => {
        if (!user) {
          return;
        }
        this.user = user;
        this.gql
          .query(dealQueries.dealQuery, { slug: params.slug })
          .then((query: any) => {
            this.deal = query.data.deal;
            if (this.myInvestment) {
              if (this.myInvestment.stage === 'Provide Details') {
                this.router.navigate(['/invest', this.deal.slug, 'profile']);
              }
              if (this.myInvestment.stage === 'Sign Docs') {
                this.router.navigate(['/invest', this.deal.slug, 'docs']);
              }
              if (this.myInvestment.stage === 'Initiate Transfer') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
              if (this.myInvestment.stage === 'Transfer Pending') {
                this.router.navigate(['/invest', this.deal.slug, 'payment']);
              }
            } else {
              this.router.navigate(['/invest', this.deal.slug, 'details']);
            }
          })
          .catch((errs: any) => {
            this.toast.gqlErrors(errs);
          })
          .finally(() => {
            this.loadingDeal = false;
          });
      });
    });
  }

  public ngOnDestroy() {}

  public get myInvestment() {
    let investments = this.deal.investments.edges;
    let userInvestments = investments.filter((investment) => {
      return investment.node.investor.pk === this.user.pk && investment.node.stage !== 'Transfer Completed';
    });
    if (userInvestments.length > 0) {
      return userInvestments[0].node;
    } else {
      return null;
    }
  }
}
