import { Component, OnInit, OnDestroy } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, NavigationEnd } from '@angular/router';
import { Subscription } from 'rxjs';
import { filter } from 'rxjs/operators';
import * as gitVersion from './git-version.json';
import { AppConfig } from './app.config';
import { ToastService } from './modules/toast';
import { AuthService, AutoLogoutService, BannerService, GQLService, HttpService } from './services';
import * as profileQueries from './queries/profile-queries';
import { environment } from '../environments/environment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  public inProcessInvestments = [];

  private authSub: Subscription;
  private routerSub: Subscription;
  private dataSub: Subscription;
  private inProcessSub: Subscription;

  constructor(
    private router: Router,
    private title: Title,
    private toast: ToastService,
    private auth: AuthService,
    // private autoLogout: AutoLogoutService,
    public banners: BannerService,
    private gql: GQLService,
    private http: HttpService
  ) {}

  public ngOnInit() {
    this.logVersion();
    this.authSub = this.auth.user$.subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.getBannerAvailableDeals();
      this.getInProcess();
    });
    this.inProcessSub = this.banners.inProgress$.subscribe((investments: any) => {
      this.inProcessInvestments = investments.filter((obj) => {
        return obj.stage !== 'Transfer Pending';
      });
    });
    this.routerSub = this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      // scroll to top on route change
      window.scrollTo(0, 0);
      this.toast.clear();
      // change browser tab/window title on route change
      this.dataSub = this.router.routerState.root.firstChild.data.subscribe((data: any) => {
        let titleStr = data.title || this.router.routerState.root.firstChild.routeConfig.path;
        this.setTitle(titleStr);
      });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
    if (this.routerSub) {
      this.routerSub.unsubscribe();
    }
    if (this.dataSub) {
      this.dataSub.unsubscribe();
    }
    if (this.inProcessSub) {
      this.inProcessSub.unsubscribe();
    }
  }

  private setTitle(desc: string) {
    let title = 'Beehive Venture Partners';
    if (desc) {
      title = `${desc} | Beehive`;
    }
    this.title.setTitle(title);
  }

  public logVersion() {
    console.log('Version', gitVersion.tag, 'Hash', gitVersion.hash, 'Env', environment);
    this.http.get(AppConfig.createUrl('version')).subscribe((version: any) => {
      console.log(version);
    });
  }

  public getBannerAvailableDeals() {
    this.gql
      .query(profileQueries.investmentProfilesQuery)
      .then((query: any) => {
        let verifiedProfiles = query.data.me.investmentProfiles.filter(
          (profile) => !!profile.latestApplication && !!profile.latestApplication.isAccepted
        );
        if (verifiedProfiles.length > 0) {
          this.banners.getAvailableDeals();
        }
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
      });
  }

  public getInProcess() {
    this.gql
      .query(profileQueries.myInvestmentsQuery, {
        status: 'processing',
      })
      .then((query: any) => {
        let inProcessInvestments = query.data.me.investments.edges.map((obj: any) => {
          return obj.node;
        });
        this.banners.inProgressSubject$.next(inProcessInvestments);
      });
  }

  public get showNav() {
    return (
      this.router &&
      !this.router.url.includes('/application') &&
      !this.router.url.includes('/document') &&
      !this.router.url.startsWith('/docs') &&
      !this.router.url.startsWith('/manager-doc') &&
      !this.router.url.startsWith('/diligence')
    );
  }

  public get showFooter() {
    return (
      this.router &&
      !this.router.url.includes('/invest') &&
      !this.router.url.includes('/application') &&
      !this.router.url.includes('/document') &&
      !this.router.url.startsWith('/docs') &&
      !this.router.url.startsWith('/manager-doc') &&
      !this.router.url.startsWith('/diligence')
    );
  }

  public get showCTA() {
    return (
      this.router &&
      this.auth.isLoggedIn() &&
      !this.router.url.includes('/invest') &&
      !this.router.url.includes('/deal/') &&
      !this.router.url.includes('/dashboard') &&
      !this.router.url.includes('/reports/') &&
      !this.router.url.includes('/application') &&
      !this.router.url.includes('/document') &&
      !this.router.url.startsWith('/docs') &&
      !this.router.url.startsWith('/diligence') &&
      !this.router.url.startsWith('/manager-doc') &&
      (this.banners.activeBanners.length > 0 || this.inProcessInvestments.length > 0)
    );
  }
}
