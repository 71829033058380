import { Component, OnInit, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { saveAs } from 'file-saver';
import { environment } from '../../../environments/environment';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AppConfig } from '../../app.config';

@Component({
  selector: 'app-download-documents',
  templateUrl: './download-documents.modal.html',
  styleUrls: ['./download-documents.modal.scss'],
})
export class DownloadDocumentsModalComponent implements OnInit, OnDestroy {
  public data = null;
  public dataDefaults = {};

  public user = null;
  public investment = null;
  public documents = [];
  public downloadingDocuments = [];
  public downloadedDocuments = [];

  constructor(private http: HttpClient, public modal: ModalService, private toast: ToastService) {}

  public ngOnInit() {
    if (!this.data || !this.data.user || !this.data.investment) {
      return;
    }
    this.user = this.data.user;
    this.investment = this.data.investment;
    this.documents = this.investment.dealDocuments.edges.map((doc) => doc.node);
  }

  public ngOnDestroy() {}

  public isDownloading(doc: any) {
    return this.downloadingDocuments.indexOf(doc) > -1;
  }

  public isDownloaded(doc: any) {
    return this.downloadedDocuments.indexOf(doc) > -1;
  }

  public downloadDocument(doc: any) {
    if (this.isDownloading(doc)) {
      return;
    }
    const url = AppConfig.documentDownloadUrl(doc.signnowId);
    let headers = new HttpHeaders().set('Accept', 'application/pdf').set('Content-Type', 'application/pdf');
    let docName = '';
    if (environment.name !== 'prod') {
      docName = `[DEV] ${doc.template.name} [${this.user.firstName} ${this.user.lastName}].pdf`;
    } else {
      docName = `${doc.template.name} [${this.user.firstName} ${this.user.lastName}].pdf`;
    }
    this.downloadingDocuments.push(doc);
    this.http
      .get(url, {
        headers: headers,
        params: {},
        responseType: 'blob',
      })
      .subscribe(
        (res: any) => {
          let blob = new Blob([res], { type: 'application/pdf' });
          saveAs(blob, docName);
          this.downloadedDocuments.push(doc);
        },
        (err) => {
          this.toast.error('Error downloading ' + docName);
          let downloadingIdx = this.downloadingDocuments.indexOf(doc);
          this.downloadingDocuments.splice(downloadingIdx, 1);
        },
        () => {
          let downloadingIdx = this.downloadingDocuments.indexOf(doc);
          this.downloadingDocuments.splice(downloadingIdx, 1);
        }
      );
  }
}
