import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { Router } from '@angular/router';

@Injectable()
export class AutoLogoutService {
  private lastAction = Date.now();

  constructor(private auth: AuthService, private router: Router) {
    this.initClickListener();
    this.initInterval();
  }

  private initClickListener() {
    document.body.addEventListener('click', () => this.reset());
  }

  private initInterval() {
    setInterval(() => {
      this.checkInactiveTime();
    }, 30 * 1000);
  }

  private reset() {
    this.lastAction = Date.now();
  }

  private checkInactiveTime() {
    const now = Date.now();
    const timeLeft = this.lastAction + 1800 * 1000; // 30 minute timeout
    const diff = timeLeft - now;
    if (diff < 0 && this.auth.isLoggedIn()) {
      this.auth.logout();
      this.router.navigate(['/login']);
    }
  }
}
