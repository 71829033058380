<div class="modalGuts">
  <h1>Add Funding Sources</h1>
  <div class="spinnerContainer" *ngIf="formSubmitting">
    <div class="ssSpinner"></div>
  </div>
  <form (submit)="create()" *ngIf="!formSubmitting">
    <div class="modalGuts__section">
      <div class="modalGuts__group">
        <label>Account*</label>
        <div class="input modalGuts__input" (click)="accountsDropdownOpen = true;">
          <span *ngIf="accounts.length < 1">Select Accounts</span>
          <span *ngIf="accounts.length > 0"
            >{{accounts[0].name}} ({{accounts[0].mask}})
            <span *ngIf="accounts.length > 1">+{{accounts.length - 1}}</span></span
          >
        </div>
        <app-popover
          [visible]="accountsDropdownOpen"
          (visibleChange)="accountsDropdownOpen = $event"
          [options]="accountsDropdownOptions"
        >
          <div class="accountDropdown">
            <div
              class="accountDropdown__item"
              [ngClass]="{'accountDropdown__item--selected': isSelected(account)}"
              *ngFor="let account of data?.metadata.accounts"
              (click)="selectAccount(account)"
            >
              {{account.name}} ({{account.mask}})
            </div>
          </div>
        </app-popover>
      </div>
    </div>
    <button
      type="submit"
      class="button modalGuts__button"
      [ngStyle]="{'margin-top': accountsDropdownOpen ? (data?.metadata.accounts.length * 48 + 32) + 'px' : '2rem'}"
      [disabled]="accounts.length < 1"
    >
      <span>Save</span>
    </button>
  </form>
</div>
