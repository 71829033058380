import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LocalStorageService } from './storage.service';

@Injectable()
export class JWTTokenInterceptor implements HttpInterceptor {
  constructor(private storage: LocalStorageService) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const authToken = this.storage.getItem('jwt');
    if (authToken) {
      request = request.clone({
        headers: request.headers.set('Authorization', `JWT ${authToken}`),
      });
    }
    return next.handle(request);
  }
}
