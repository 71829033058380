// Angular
import { NgModule, ErrorHandler } from '@angular/core';
import { CommonModule, APP_BASE_HREF } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { RouterModule } from '@angular/router';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

// App
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { environment } from '../environments/environment';

// Rollbar
import { RollbarService, rollbarFactory, RollbarErrorHandler } from './rollbar';

// Apollo
import { ApolloModule, APOLLO_OPTIONS } from 'apollo-angular';
import { HttpLinkModule, HttpLink } from 'apollo-angular-link-http';
import { InMemoryCache } from 'apollo-cache-inmemory';

import { NgxCurrencyModule } from 'ngx-currency';
import { MarkdownModule } from 'ngx-markdown';
import { NgxMaskModule, IConfig } from 'ngx-mask';

// Modules
import { CalendarModule } from './modules/calendar';
import { ModalsModule } from './modules/modals';
import { PopoverModule } from './modules/popover';
import { ToastModule } from './modules/toast';

// Components
import {
  AddProfileHelpModalComponent,
  BannerComponent,
  ChangePasswordModalComponent,
  ContactModalComponent,
  DocumentModalComponent,
  DownloadDocumentsModalComponent,
  EntityProfileModalComponent,
  FileUploadComponent,
  FooterComponent,
  FundingSourceModalComponent,
  InvestorStatusModalComponent,
  NavComponent,
  PersonalProfileModalComponent,
  ThanksModalComponent,
  WireInstructionsModalComponent,
} from './components';

import { CurrencyPipe, ToNowPipe } from './pipes';

// Services
import {
  AuthService,
  AutoLogoutService,
  BannerService,
  CanActivateViaAuthGuard,
  RedirectHomeIfLoggedIn,
  IsAdminAuthGuard,
  GQLService,
  HttpService,
  JWTTokenInterceptor,
  PlaidService,
  LocalStorageService,
  SessionStorageService,
  StoreService,
} from './services';

// Routes
import {
  // Admin Routes
  ActivityListComponent,
  DealDetailComponent,
  DealListComponent,
  InvestmentDetailComponent,
  InvestorDetailComponent,
  InvestorListComponent,
  // Auth Routes
  ForgotPasswordComponent,
  LoginComponent,
  PrivacyPolicyComponent,
  ResetPasswordComponent,
  SignUpComponent,
  TOSComponent,
  VerifyComponent,
  // Embed
  ApplicationComponent,
  DocumentComponent,
  // Investing Routes
  DealComponent,
  InvestDetailsComponent,
  InvestDocsComponent,
  InvestPaymentComponent,
  InvestProfileComponent,
  InvestVerifyComponent,
  ManagerDocComponent,
  // Other Routes
  AcceleratorComponent,
  DashboardComponent,
  DocRedirectComponent,
  ErrorComponent,
  HomeComponent,
  SettingsComponent,
} from './routes';

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    RouterModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    ApolloModule,
    HttpLinkModule,
    AppRoutingModule,
    NgxCurrencyModule,
    MarkdownModule.forRoot(),
    NgxMaskModule.forRoot(maskConfig),
    CalendarModule,
    ModalsModule,
    PopoverModule,
    ToastModule,
  ],
  declarations: [
    AppComponent,
    // Pipes
    CurrencyPipe,
    ToNowPipe,
    // Routes
    // Admin Routes
    ActivityListComponent,
    DealDetailComponent,
    DealListComponent,
    InvestmentDetailComponent,
    InvestorDetailComponent,
    InvestorListComponent,
    // Auth Routes
    ForgotPasswordComponent,
    LoginComponent,
    PrivacyPolicyComponent,
    ResetPasswordComponent,
    SignUpComponent,
    TOSComponent,
    VerifyComponent,
    // Embed Routes
    ApplicationComponent,
    DocumentComponent,
    // Investing Routes
    DealComponent,
    InvestDetailsComponent,
    InvestDocsComponent,
    InvestPaymentComponent,
    InvestProfileComponent,
    InvestVerifyComponent,
    ManagerDocComponent,
    // Other Routes
    AcceleratorComponent,
    DashboardComponent,
    DocRedirectComponent,
    ErrorComponent,
    HomeComponent,
    SettingsComponent,
    // Components
    AddProfileHelpModalComponent,
    BannerComponent,
    ChangePasswordModalComponent,
    ContactModalComponent,
    DocumentModalComponent,
    DownloadDocumentsModalComponent,
    EntityProfileModalComponent,
    FileUploadComponent,
    FooterComponent,
    FundingSourceModalComponent,
    InvestorStatusModalComponent,
    NavComponent,
    PersonalProfileModalComponent,
    ThanksModalComponent,
    WireInstructionsModalComponent,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: HTTP_INTERCEPTORS, useClass: JWTTokenInterceptor, multi: true },
    {
      provide: APOLLO_OPTIONS,
      useFactory: (httpLink: HttpLink) => {
        return {
          cache: new InMemoryCache(),
          link: httpLink.create({
            uri: environment.graphqlHost,
          }),
        };
      },
      deps: [HttpLink],
    },
    { provide: ErrorHandler, useClass: RollbarErrorHandler },
    { provide: RollbarService, useFactory: rollbarFactory },
    AuthService,
    AutoLogoutService,
    CanActivateViaAuthGuard,
    RedirectHomeIfLoggedIn,
    IsAdminAuthGuard,
    BannerService,
    GQLService,
    HttpService,
    PlaidService,
    StoreService,
    LocalStorageService,
    SessionStorageService,
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
