import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastService } from '../../../modules/toast';
import { AuthService } from '../../../services';

@Component({
  selector: 'app-sign-up',
  templateUrl: 'sign-up.component.html',
  styleUrls: ['./sign-up.component.scss'],
})
export class SignUpComponent {
  // TODO: Add recaptcha v2

  public email = '';
  public firstName = '';
  public lastName = '';
  public phone = '';
  public password1 = '';
  public password2 = '';
  public tosAgreement = false;

  public error = '';
  public showVerifyEmail = false;

  constructor(private router: Router, private toast: ToastService, private auth: AuthService) {}

  public routeToLogin() {
    this.router.navigate(['/login']);
  }

  public signUpDisabled() {
    return (
      !this.email ||
      !this.firstName ||
      !this.lastName ||
      !this.phone ||
      !this.password1 ||
      !this.password2 ||
      !this.tosAgreement
    );
  }

  public signUp() {
    this.error = '';
    this.auth
      .register(this.email, this.firstName, this.lastName, this.phone, this.password1, this.password2)
      .then(() => {
        this.showVerifyEmail = true;
      })
      .catch((errors) => {
        if (errors.length > 0) {
          this.error = errors[0].message;
        }
      });
  }

  public resendActivationEmail() {
    if (!this.email) {
      return;
    }
    this.auth
      .resendActivationEmail(this.email)
      .then(() => {
        this.toast.success(`A verification email has been sent to ${this.email}.`);
      })
      .catch((errors: any) => {
        this.toast.gqlErrors(errors);
      });
  }
}
