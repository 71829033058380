import { Component, OnInit, OnDestroy } from '@angular/core';
import { ToastService } from '../../../modules/toast';
import { GQLService } from '../../../services';
import * as pageQueries from '../../../queries/page-queries';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss'],
})
export class PrivacyPolicyComponent implements OnInit, OnDestroy {
  public privacyPage = null;

  constructor(private toast: ToastService, private gql: GQLService) {}

  public ngOnInit() {
    this.gql
      .query(pageQueries.pageQuery, {
        slug: 'privacy-policy',
      })
      .then((query: any) => {
        this.privacyPage = query.data.page;
      })
      .catch((errs) => {
        this.toast.gqlErrors(errs);
      });
  }

  public ngOnDestroy() {}
}
