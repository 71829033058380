import { Injectable, Inject, InjectionToken, ErrorHandler } from '@angular/core';
import * as Rollbar from 'rollbar';
import { environment } from '../environments/environment';
import { AuthService } from './services';

const rollbarConfig = {
  accessToken: environment.rollbarKey,
  captureUncaught: true,
  captureUnhandledRejections: true,
  payload: {
    environment: environment.rollbar_env,
  },
};

export const RollbarService = new InjectionToken<Rollbar>('rollbar');

@Injectable()
export class RollbarErrorHandler implements ErrorHandler {
  private user = null;

  constructor(@Inject(RollbarService) private rollbar: Rollbar, private auth: AuthService) {
    this.auth.user$.subscribe(
      (user) => {
        if (!user) {
          return;
        }
        this.user = user;
      },
      () => {},
      () => {}
    );
  }

  handleError(err: any): void {
    if (this.user) {
      this.rollbar.configure({
        payload: {
          environment: environment.rollbar_env,
          person: {
            id: this.user.id,
            username: `${this.user.firstName} ${this.user.lastName}`,
            email: this.user.email,
          },
        },
      });
    } else {
      this.rollbar.configure({
        payload: {
          environment: environment.rollbar_env,
          person: {
            id: null,
            username: null,
            email: null,
          },
        },
      });
    }
    if (environment.production) {
      this.rollbar.error(err.originalError || err);
    }
    console.log(err);
  }
}

export function rollbarFactory() {
  return new Rollbar(rollbarConfig);
}
