<div class="ssContainer">
  <div class="backWrapper">
    <a class="buttonText backWrapper__button" [routerLink]="['/sign-up']">
      <i class="buttonText__icon fa fa-arrow-left"></i>
      <span class="backWrapper__text">Back to Sign Up</span>
    </a>
    <div class="backWrapper__header">
      <h1>{{ privacyPage?.title }}</h1>
    </div>
  </div>
  <div class="scrollBox">
    <markdown [data]="privacyPage?.content" ngPreserveWhitespaces></markdown>
  </div>
</div>
