<div class="ssContainer">
  <h1>Deal List</h1>
  <div class="spinnerContainer" *ngIf="!userLoaded || !dealsLoaded">
    <div class="ssSpinner"></div>
  </div>
  <h2 *ngIf="dealsLoaded">Fund</h2>
  <div class="empty" *ngIf="dealsLoaded && fundDeals.length < 1">There are no fund deals currently.</div>
  <div class="tableWrap" *ngIf="dealsLoaded && fundDeals.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr class="tr--noBorder">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="4" class="td--borderLeft td--borderRight">Stages</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <!--TODO: The table headers are sortable. CSS class th--sortAZ shows the down arrow; class th--sortZA shows the up arrow. When a user clicks a table header, remove the th--sortAZ and th--sortZA classes from all other table headers in that table, and add th--sortAZ class to the clicked table header. If a user clicks a table header with class th--sortAZ, remove the class and replace it with th--sortZA.-->
            <th
              class="td--left th--sortAZ"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'deal' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'deal' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('deal')"
            >
              Deal
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'status' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'status' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('status')"
            >
              Status
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'deadline' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'deadline' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('deadline')"
            >
              Deadline
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'allocation' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'allocation' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('allocation')"
            >
              Target Size
            </th>
            <th
              class="td--right td--borderLeft"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'pledge' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'pledge' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('pledge')"
            >
              Pledge
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'legal' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'legal' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('legal')"
            >
              Legal
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'finalize' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'finalize' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('finalize')"
            >
              Finalize
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'payment' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'payment' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('payment')"
            >
              Payment
            </th>
            <th
              class="td--right td--borderRight"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'funded' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'funded' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('funded')"
            >
              Funded
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'full' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'full' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('full')"
            >
              % Full
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': fundSort.field === 'left' && fundSort.direction === 'dsc',
                'th--sortZA': fundSort.field === 'left' && fundSort.direction === 'asc'
              }"
              (click)="fundHeaderClick('left')"
            >
              $ Left
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deal of fundDeals">
            <td>
              <a [routerLink]="['/reports', 'deal', deal.slug]">{{ deal.name }}</a>
            </td>
            <td>
              <span class="capitalize">{{ deal.status }}</span>
            </td>
            <td>{{ moment(deal.deadline).format('MM/DD/YY') }}</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundSize' + deal.id] = !numberTipOpen['fundSize' + deal.id]"
                >{{ deal.allocation | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundSize' + deal.id]"
                (visibleChange)="numberTipOpen['fundSize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.allocation | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a
                class="table__number"
                (click)="numberTipOpen['fundPledge' + deal.id] = !numberTipOpen['fundPledge' + deal.id]"
                >{{ deal.pledgeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundPledge' + deal.id]"
                (visibleChange)="numberTipOpen['fundPledge' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.pledgeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundLegal' + deal.id] = !numberTipOpen['fundLegal' + deal.id]"
                >{{ deal.legalStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundLegal' + deal.id]"
                (visibleChange)="numberTipOpen['fundLegal' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.legalStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundFinalize' + deal.id] = !numberTipOpen['fundFinalize' + deal.id]"
                >{{ deal.finalizeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundFinalize' + deal.id]"
                (visibleChange)="numberTipOpen['fundFinalize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.finalizeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundPayment' + deal.id] = !numberTipOpen['fundPayment' + deal.id]"
                >{{ deal.paymentStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundPayment' + deal.id]"
                (visibleChange)="numberTipOpen['fundPayment' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.paymentStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a
                class="table__number"
                (click)="numberTipOpen['fundFunded' + deal.id] = !numberTipOpen['fundFunded' + deal.id]"
                >{{ deal.fundedStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundFunded' + deal.id]"
                (visibleChange)="numberTipOpen['fundFunded' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.fundedStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ deal.percentageFull }}%</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundLeft' + deal.id] = !numberTipOpen['fundLeft' + deal.id]"
                >{{ deal.amountLeft | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundLeft' + deal.id]"
                (visibleChange)="numberTipOpen['fundLeft' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.amountLeft | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="tr--noBorder">
            <td></td>
            <td></td>
            <th class="td--right">Totals</th>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['fundTotalSize'] = !numberTipOpen['fundTotalSize']">{{
                fundTotals.size | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['fundTotalSize']"
                (visibleChange)="numberTipOpen['fundTotalSize'] = $event"
                ><div class="numberTooltip">{{ fundTotals.size | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a class="table__number" (click)="numberTipOpen['fundTotalPledge'] = !numberTipOpen['fundTotalPledge']">{{
                fundTotals.pledge | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['fundTotalPledge']"
                (visibleChange)="numberTipOpen['fundTotalPledge'] = $event"
                ><div class="numberTooltip">{{ fundTotals.pledge | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['fundTotalLegal'] = !numberTipOpen['fundTotalLegal']">{{
                fundTotals.legal | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['fundTotalLegal']"
                (visibleChange)="numberTipOpen['fundTotalLegal'] = $event"
                ><div class="numberTooltip">{{ fundTotals.legal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundTotalFinalize'] = !numberTipOpen['fundTotalFinalize']"
                >{{ fundTotals.finalize | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundTotalFinalize']"
                (visibleChange)="numberTipOpen['fundTotalFinalize'] = $event"
                ><div class="numberTooltip">{{ fundTotals.finalize | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['fundTotalPayment'] = !numberTipOpen['fundTotalPayment']"
                >{{ fundTotals.payment | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['fundTotalPayment']"
                (visibleChange)="numberTipOpen['fundTotalPayment'] = $event"
                ><div class="numberTooltip">{{ fundTotals.payment | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a class="table__number" (click)="numberTipOpen['fundTotalFunded'] = !numberTipOpen['fundTotalFunded']">{{
                fundTotals.funded | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['fundTotalFunded']"
                (visibleChange)="numberTipOpen['fundTotalFunded'] = $event"
                ><div class="numberTooltip">{{ fundTotals.funded | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ fundTotals.full }}%</td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['fundTotalLeft'] = !numberTipOpen['fundTotalLeft']">{{
                fundTotals.left | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['fundTotalLeft']"
                (visibleChange)="numberTipOpen['fundTotalLeft'] = $event"
                ><div class="numberTooltip">{{ fundTotals.left | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->

  <h2 *ngIf="dealsLoaded">Syndicate</h2>
  <div class="empty" *ngIf="dealsLoaded && syndicateDeals.length < 1">There are no syndicate deals currently.</div>
  <div class="tableWrap" *ngIf="dealsLoaded && syndicateDeals.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr class="tr--noBorder">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="4" class="td--borderLeft td--borderRight">Stages</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'deal' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'deal' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('deal')"
            >
              Deal
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'status' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'status' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('status')"
            >
              Status
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'deadline' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'deadline' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('deadline')"
            >
              Deadline
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'allocation' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'allocation' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('allocation')"
            >
              Allocation
            </th>
            <th
              class="td--right td--borderLeft"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'pledge' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'pledge' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('pledge')"
            >
              Pledge
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'legal' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'legal' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('legal')"
            >
              Legal
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'finalize' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'finalize' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('finalize')"
            >
              Finalize
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'payment' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'payment' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('payment')"
            >
              Payment
            </th>
            <th
              class="td--right td--borderRight"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'funded' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'funded' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('funded')"
            >
              Funded
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'full' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'full' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('full')"
            >
              % Full
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': syndicateSort.field === 'left' && syndicateSort.direction === 'dsc',
                'th--sortZA': syndicateSort.field === 'left' && syndicateSort.direction === 'asc'
              }"
              (click)="syndicateHeaderClick('left')"
            >
              $ Left
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deal of syndicateDeals">
            <td>
              <a [routerLink]="['/reports', 'deal', deal.slug]">{{ deal.name }}</a>
            </td>
            <td>
              <span class="capitalize">{{ deal.status }}</span>
            </td>
            <td>{{ moment(deal.deadline).format('MM/DD/YY') }}</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndSize' + deal.id] = !numberTipOpen['syndSize' + deal.id]"
                >{{ deal.allocation | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndSize' + deal.id]"
                (visibleChange)="numberTipOpen['syndSize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.allocation | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a
                class="table__number"
                (click)="numberTipOpen['syndPledge' + deal.id] = !numberTipOpen['syndPledge' + deal.id]"
                >{{ deal.pledgeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndPledge' + deal.id]"
                (visibleChange)="numberTipOpen['syndPledge' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.pledgeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndLegal' + deal.id] = !numberTipOpen['syndLegal' + deal.id]"
                >{{ deal.legalStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndLegal' + deal.id]"
                (visibleChange)="numberTipOpen['syndLegal' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.legalStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndFinalize' + deal.id] = !numberTipOpen['syndFinalize' + deal.id]"
                >{{ deal.finalizeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndFinalize' + deal.id]"
                (visibleChange)="numberTipOpen['syndFinalize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.finalizeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndPayment' + deal.id] = !numberTipOpen['syndPayment' + deal.id]"
                >{{ deal.paymentStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndPayment' + deal.id]"
                (visibleChange)="numberTipOpen['syndPayment' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.paymentStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a
                class="table__number"
                (click)="numberTipOpen['syndFunded' + deal.id] = !numberTipOpen['syndFunded' + deal.id]"
                >{{ deal.fundedStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndFunded' + deal.id]"
                (visibleChange)="numberTipOpen['syndFunded' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.fundedStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ deal.percentageFull }}%</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndLeft' + deal.id] = !numberTipOpen['syndLeft' + deal.id]"
                >{{ deal.amountLeft | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndLeft' + deal.id]"
                (visibleChange)="numberTipOpen['syndLeft' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.amountLeft | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="tr--noBorder">
            <td></td>
            <td></td>
            <th class="td--right">Totals</th>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['syndTotalSize'] = !numberTipOpen['syndTotalSize']">{{
                syndTotals.size | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['syndTotalSize']"
                (visibleChange)="numberTipOpen['syndTotalSize'] = $event"
                ><div class="numberTooltip">{{ syndTotals.size | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a class="table__number" (click)="numberTipOpen['syndTotalPledge'] = !numberTipOpen['syndTotalPledge']">{{
                syndTotals.pledge | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['syndTotalPledge']"
                (visibleChange)="numberTipOpen['syndTotalPledge'] = $event"
                ><div class="numberTooltip">{{ syndTotals.pledge | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['syndTotalLegal'] = !numberTipOpen['syndTotalLegal']">{{
                syndTotals.legal | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['syndTotalLegal']"
                (visibleChange)="numberTipOpen['syndTotalLegal'] = $event"
                ><div class="numberTooltip">{{ syndTotals.legal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndTotalFinalize'] = !numberTipOpen['syndTotalFinalize']"
                >{{ syndTotals.finalize | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndTotalFinalize']"
                (visibleChange)="numberTipOpen['syndTotalFinalize'] = $event"
                ><div class="numberTooltip">{{ syndTotals.finalize | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['syndTotalPayment'] = !numberTipOpen['syndTotalPayment']"
                >{{ syndTotals.payment | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['syndTotalPayment']"
                (visibleChange)="numberTipOpen['syndTotalPayment'] = $event"
                ><div class="numberTooltip">{{ syndTotals.payment | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a class="table__number" (click)="numberTipOpen['syndTotalFunded'] = !numberTipOpen['syndTotalFunded']">{{
                syndTotals.funded | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['syndTotalFunded']"
                (visibleChange)="numberTipOpen['syndTotalFunded'] = $event"
                ><div class="numberTooltip">{{ syndTotals.funded | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ syndTotals.full }}%</td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['syndTotalLeft'] = !numberTipOpen['syndTotalLeft']">{{
                syndTotals.left | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['syndTotalLeft']"
                (visibleChange)="numberTipOpen['syndTotalLeft'] = $event"
                ><div class="numberTooltip">{{ syndTotals.left | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->

  <h2 *ngIf="dealsLoaded">Sidecar</h2>
  <div class="empty" *ngIf="dealsLoaded && sidecarDeals.length < 1">There are no sidecar deals currently.</div>
  <div class="tableWrap" *ngIf="dealsLoaded && sidecarDeals.length > 0">
    <div class="tableWrap__inner">
      <table>
        <thead>
          <tr class="tr--noBorder">
            <th></th>
            <th></th>
            <th></th>
            <th></th>
            <th colspan="4" class="td--borderLeft td--borderRight">Stages</th>
            <th></th>
            <th></th>
          </tr>
          <tr>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'deal' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'deal' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('deal')"
            >
              Deal
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'status' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'status' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('status')"
            >
              Status
            </th>
            <th
              class="td--left"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'deadline' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'deadline' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('deadline')"
            >
              Deadline
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'allocation' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'allocation' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('allocation')"
            >
              Allocation
            </th>
            <th
              class="td--right td--borderLeft"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'pledge' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'pledge' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('pledge')"
            >
              Pledge
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'legal' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'legal' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('legal')"
            >
              Legal
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'finalize' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'finalize' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('finalize')"
            >
              Finalize
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'payment' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'payment' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('payment')"
            >
              Payment
            </th>
            <th
              class="td--right td--borderRight"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'funded' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'funded' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('funded')"
            >
              Funded
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'full' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'full' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('full')"
            >
              % Full
            </th>
            <th
              class="td--right"
              [ngClass]="{
                'th--sortAZ': sidecarSort.field === 'left' && sidecarSort.direction === 'dsc',
                'th--sortZA': sidecarSort.field === 'left' && sidecarSort.direction === 'asc'
              }"
              (click)="sidecarHeaderClick('left')"
            >
              $ Left
            </th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let deal of sidecarDeals">
            <td>
              <a [routerLink]="['/reports', 'deal', deal.slug]">{{ deal.name }}</a>
            </td>
            <td>
              <span class="capitalize">{{ deal.status }}</span>
            </td>
            <td>{{ moment(deal.deadline).format('MM/DD/YY') }}</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideSize' + deal.id] = !numberTipOpen['sideSize' + deal.id]"
                >{{ deal.allocation | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideSize' + deal.id]"
                (visibleChange)="numberTipOpen['sideSize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.allocation | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a
                class="table__number"
                (click)="numberTipOpen['sidePledge' + deal.id] = !numberTipOpen['sidePledge' + deal.id]"
                >{{ deal.pledgeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sidePledge' + deal.id]"
                (visibleChange)="numberTipOpen['sidePledge' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.pledgeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideLegal' + deal.id] = !numberTipOpen['sideLegal' + deal.id]"
                >{{ deal.legalStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideLegal' + deal.id]"
                (visibleChange)="numberTipOpen['sideLegal' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.legalStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideFinalize' + deal.id] = !numberTipOpen['sideFinalize' + deal.id]"
                >{{ deal.finalizeStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideFinalize' + deal.id]"
                (visibleChange)="numberTipOpen['sideFinalize' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.finalizeStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sidePayment' + deal.id] = !numberTipOpen['sidePayment' + deal.id]"
                >{{ deal.paymentStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sidePayment' + deal.id]"
                (visibleChange)="numberTipOpen['sidePayment' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.paymentStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a
                class="table__number"
                (click)="numberTipOpen['sideFunded' + deal.id] = !numberTipOpen['sideFunded' + deal.id]"
                >{{ deal.fundedStageTotal | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideFunded' + deal.id]"
                (visibleChange)="numberTipOpen['sideFunded' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.fundedStageTotal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ deal.percentageFull }}%</td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideLeft' + deal.id] = !numberTipOpen['sideLeft' + deal.id]"
                >{{ deal.amountLeft | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideLeft' + deal.id]"
                (visibleChange)="numberTipOpen['sideLeft' + deal.id] = $event"
                ><div class="numberTooltip">{{ deal.amountLeft | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="tr--noBorder">
            <td></td>
            <td></td>
            <th class="td--right">Totals</th>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['sideTotalSize'] = !numberTipOpen['sideTotalSize']">{{
                sidecarTotals.size | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['sideTotalSize']"
                (visibleChange)="numberTipOpen['sideTotalSize'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.size | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderLeft">
              <a class="table__number" (click)="numberTipOpen['sideTotalPledge'] = !numberTipOpen['sideTotalPledge']">{{
                sidecarTotals.pledge | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['sideTotalPledge']"
                (visibleChange)="numberTipOpen['sideTotalPledge'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.pledge | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['sideTotalLegal'] = !numberTipOpen['sideTotalLegal']">{{
                sidecarTotals.legal | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['sideTotalLegal']"
                (visibleChange)="numberTipOpen['sideTotalLegal'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.legal | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideTotalFinalize'] = !numberTipOpen['sideTotalFinalize']"
                >{{ sidecarTotals.finalize | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideTotalFinalize']"
                (visibleChange)="numberTipOpen['sideTotalFinalize'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.finalize | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">
              <a
                class="table__number"
                (click)="numberTipOpen['sideTotalPayment'] = !numberTipOpen['sideTotalPayment']"
                >{{ sidecarTotals.payment | formatCurrency }}</a
              >
              <app-popover
                [visible]="numberTipOpen['sideTotalPayment']"
                (visibleChange)="numberTipOpen['sideTotalPayment'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.payment | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right td--borderRight">
              <a class="table__number" (click)="numberTipOpen['sideTotalFunded'] = !numberTipOpen['sideTotalFunded']">{{
                sidecarTotals.funded | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['sideTotalFunded']"
                (visibleChange)="numberTipOpen['sideTotalFunded'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.funded | currency }}</div>
              </app-popover>
            </td>
            <td class="td--right">{{ sidecarTotals.full }}%</td>
            <td class="td--right">
              <a class="table__number" (click)="numberTipOpen['sideTotalLeft'] = !numberTipOpen['sideTotalLeft']">{{
                sidecarTotals.left | formatCurrency
              }}</a>
              <app-popover
                [visible]="numberTipOpen['sideTotalLeft']"
                (visibleChange)="numberTipOpen['sideTotalLeft'] = $event"
                ><div class="numberTooltip">{{ sidecarTotals.left | currency }}</div>
              </app-popover>
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <!--/.tableWrap__inner-->
  </div>
  <!--/.tableWrap-->
</div>
<!--/.ssContainer-->
