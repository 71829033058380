import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastService } from '../../../modules/toast';
import { AuthService, GQLService } from '../../../services';
import * as adminQueries from '../../../queries/admin-queries';

@Component({
  selector: 'app-deal-list',
  templateUrl: './deal-list.component.html',
  styleUrls: ['./deal-list.component.scss'],
})
export class DealListComponent implements OnInit, OnDestroy {
  public moment = moment;

  public user = null;
  public userLoaded = false;
  public fundDeals = [];
  public fundDealsOrig = [];
  public syndicateDeals = [];
  public syndicateDealsOrig = [];
  public sidecarDeals = [];
  public sidecarDealsOrig = [];
  public dealsLoaded = false;
  public numberTipOpen = {};

  public fundSort = {
    direction: 'dsc',
    field: 'deal',
  };

  public syndicateSort = {
    direction: 'dsc',
    field: 'deal',
  };

  public sidecarSort = {
    direction: 'dsc',
    field: 'deal',
  };

  public fundTotals = {
    size: 0,
    pledge: 0,
    legal: 0,
    payment: 0,
    funded: 0,
    full: 0,
    left: 0,
  };
  public syndTotals = {
    size: 0,
    pledge: 0,
    legal: 0,
    payment: 0,
    funded: 0,
    full: 0,
    left: 0,
  };
  public sidecarTotals = {
    size: 0,
    pledge: 0,
    legal: 0,
    payment: 0,
    funded: 0,
    full: 0,
    left: 0,
  };

  private authSub = null;

  constructor(
    private router: Router,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.userLoaded = false;
    this.authSub = this.auth.user$.subscribe((user) => {
      if (!user) {
        return;
      }
      this.user = user;
      if (!this.user.isAdmin) {
        this.router.navigate(['/']);
      }
      this.userLoaded = true;
      this.dealsLoaded = false;
      this.gql
        .query(adminQueries.dealsQuery)
        .then((query: any) => {
          this.fundDeals = query.data.deals.edges
            .filter((obj: any) => {
              return obj.node.dealType === 'fund';
            })
            .map((obj: any) => {
              return obj.node;
            });
          this.fundDealsOrig = this.fundDeals;
          this.sortFund();
          this.syndicateDeals = query.data.deals.edges
            .filter((obj: any) => {
              return obj.node.dealType === 'syndicate';
            })
            .map((obj: any) => {
              return obj.node;
            });
          this.syndicateDealsOrig = this.syndicateDeals;
          this.sortSyndicate();
          this.sidecarDeals = query.data.deals.edges
            .filter((obj: any) => {
              return obj.node.dealType === 'sidecar';
            })
            .map((obj: any) => {
              return obj.node;
            });
          this.sidecarDealsOrig = this.sidecarDeals;
          this.sortSidecar();
          this.fundTotals = this.totals(this.fundDeals);
          this.syndTotals = this.totals(this.syndicateDeals);
          this.sidecarTotals = this.totals(this.sidecarDeals);
        })
        .catch((errs: any) => {
          this.toast.gqlErrors(errs);
        })
        .finally(() => {
          this.dealsLoaded = true;
        });
    });
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public totals(deals) {
    if (!deals || deals.length < 1) {
      return {
        size: 0,
        pledge: 0,
        legal: 0,
        finalize: 0,
        payment: 0,
        funded: 0,
        full: 0,
        left: 0,
      };
    }
    let size = Math.round(deals.reduce((a, b) => a + parseInt(b.allocation, 10), 0));
    let pledge = Math.round(deals.reduce((a, b) => a + parseInt(b.pledgeStageTotal, 10), 0));
    let legal = Math.round(deals.reduce((a, b) => a + parseInt(b.legalStageTotal, 10), 0));
    let finalize = Math.round(deals.reduce((a, b) => a + parseInt(b.finalizeStageTotal, 10), 0));
    let payment = Math.round(deals.reduce((a, b) => a + parseInt(b.paymentStageTotal, 10), 0));
    let funded = Math.round(deals.reduce((a, b) => a + parseInt(b.fundedStageTotal, 10), 0));
    let full = Math.round(deals.reduce((a, b) => a + b.percentageFull, 0) / deals.length);
    let left = Math.round(deals.reduce((a, b) => a + parseInt(b.amountLeft, 10), 0));
    return {
      size,
      pledge,
      legal,
      finalize,
      payment,
      funded,
      full,
      left,
    };
  }

  public fundHeaderClick(field) {
    if (this.fundSort.field === field) {
      this.fundSort.direction = this.fundSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.fundSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortFund();
  }

  public syndicateHeaderClick(field) {
    if (this.syndicateSort.field === field) {
      this.syndicateSort.direction = this.syndicateSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.syndicateSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortSyndicate();
  }

  public sidecarHeaderClick(field) {
    if (this.sidecarSort.field === field) {
      this.sidecarSort.direction = this.sidecarSort.direction === 'asc' ? 'dsc' : 'asc';
    } else {
      this.sidecarSort = {
        direction: 'dsc',
        field: field,
      };
    }
    this.sortSidecar();
  }

  public sortFund() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.name).localeCompare(b.name);
      },
      status: (a: any, b: any) => {
        return ('' + a.status).localeCompare(b.status);
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      },
      allocation: (a: any, b: any) => {
        return b.allocation - a.allocation;
      },
      pledge: (a: any, b: any) => {
        return b.pledgeStageTotal - a.pledgeStageTotal;
      },
      legal: (a: any, b: any) => {
        return b.legalStageTotal - a.legalStageTotal;
      },
      finalize: (a: any, b: any) => {
        return b.finalizeStageTotal - a.finalizeStageTotal;
      },
      payment: (a: any, b: any) => {
        return b.paymentStageTotal - a.paymentStageTotal;
      },
      funded: (a: any, b: any) => {
        return b.fundedStageTotal - a.fundedStageTotal;
      },
      full: (a: any, b: any) => {
        return b.percentageFull - a.percentageFull;
      },
      left: (a: any, b: any) => {
        return b.amountLeft - a.amountLeft;
      },
    };
    this.fundDeals = [...this.fundDealsOrig].sort(functions[this.fundSort.field]);
    if (this.fundSort.direction === 'asc') {
      this.fundDeals = [...this.fundDeals].reverse();
    }
  }

  public sortSyndicate() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.name).localeCompare(b.name);
      },
      status: (a: any, b: any) => {
        return ('' + a.status).localeCompare(b.status);
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      },
      allocation: (a: any, b: any) => {
        return b.allocation - a.allocation;
      },
      pledge: (a: any, b: any) => {
        return b.pledgeStageTotal - a.pledgeStageTotal;
      },
      legal: (a: any, b: any) => {
        return b.legalStageTotal - a.legalStageTotal;
      },
      finalize: (a: any, b: any) => {
        return b.finalizeStageTotal - a.finalizeStageTotal;
      },
      payment: (a: any, b: any) => {
        return b.paymentStageTotal - a.paymentStageTotal;
      },
      funded: (a: any, b: any) => {
        return b.fundedStageTotal - a.fundedStageTotal;
      },
      full: (a: any, b: any) => {
        return b.percentageFull - a.percentageFull;
      },
      left: (a: any, b: any) => {
        return b.amountLeft - a.amountLeft;
      },
    };
    this.syndicateDeals = [...this.syndicateDealsOrig].sort(functions[this.syndicateSort.field]);
    if (this.syndicateSort.direction === 'asc') {
      this.syndicateDeals = [...this.syndicateDeals].reverse();
    }
  }

  public sortSidecar() {
    let functions = {
      deal: (a: any, b: any) => {
        return ('' + a.name).localeCompare(b.name);
      },
      status: (a: any, b: any) => {
        return ('' + a.status).localeCompare(b.status);
      },
      deadline: (a: any, b: any) => {
        return new Date(a.deadline).getTime() - new Date(b.deadline).getTime();
      },
      allocation: (a: any, b: any) => {
        return b.allocation - a.allocation;
      },
      pledge: (a: any, b: any) => {
        return b.pledgeStageTotal - a.pledgeStageTotal;
      },
      legal: (a: any, b: any) => {
        return b.legalStageTotal - a.legalStageTotal;
      },
      finalize: (a: any, b: any) => {
        return b.finalizeStageTotal - a.finalizeStageTotal;
      },
      payment: (a: any, b: any) => {
        return b.paymentStageTotal - a.paymentStageTotal;
      },
      funded: (a: any, b: any) => {
        return b.fundedStageTotal - a.fundedStageTotal;
      },
      full: (a: any, b: any) => {
        return b.percentageFull - a.percentageFull;
      },
      left: (a: any, b: any) => {
        return b.amountLeft - a.amountLeft;
      },
    };
    this.sidecarDeals = [...this.sidecarDealsOrig].sort(functions[this.sidecarSort.field]);
    if (this.sidecarSort.direction === 'asc') {
      this.sidecarDeals = [...this.sidecarDeals].reverse();
    }
  }
}
