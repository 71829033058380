import { Component, OnDestroy, OnInit } from '@angular/core';
import * as moment from 'moment';
import { ModalService } from '../../modules/modals';
import { ToastService } from '../../modules/toast';
import { AuthService, GQLService } from '../../services';
import * as profileQueries from '../../queries/profile-queries';

@Component({
  selector: 'app-personal-profile',
  templateUrl: './personal-profile.modal.html',
  styleUrls: ['./personal-profile.modal.scss'],
})
export class PersonalProfileModalComponent implements OnDestroy, OnInit {
  public data = null;
  public dataDefaults = {};

  public user = null;

  public ssn = '';
  public birthdate = '';
  public address1 = '';
  public address2 = '';
  public city = '';
  public state = '';
  public postalCode = '';

  public clickedSubmit = false;

  private authSub = null;

  constructor(
    private modal: ModalService,
    private toast: ToastService,
    private auth: AuthService,
    private gql: GQLService
  ) {}

  public ngOnInit() {
    this.authSub = this.auth.user$.subscribe((user: any) => {
      if (!user) {
        return;
      }
      this.user = user;
    });
    if (this.data && this.data.profile) {
      let getSSN = this.gql
        .query(profileQueries.investmentProfileSSNQuery, {
          id: this.data.profile.id,
        })
        .then((query: any) => {
          this.ssn = '***-**-' + query.data.investmentProfile.ssnLast4;
        })
        .catch((errs) => {
          this.toast.gqlErrors(errs);
        });
      this.address1 = this.data.profile.address1;
      this.address2 = this.data.profile.address2;
      this.city = this.data.profile.city;
      this.state = this.data.profile.state;
      this.postalCode = this.data.profile.postalCode;
      this.birthdate = moment(this.data.profile.birthdate).format('MM/DD/YYYY');
    }
  }

  public ngOnDestroy() {
    if (this.authSub) {
      this.authSub.unsubscribe();
    }
  }

  public get isEditing() {
    return !!this.data && !!this.data.profile;
  }

  public formDisabled() {
    if (this.clickedSubmit) {
      return true;
    }
    return !this.ssn || !this.address1 || !this.birthdate || !this.state || !this.city || !this.postalCode;
  }

  public create() {
    this.clickedSubmit = true;
    let birthdateReal = moment(this.birthdate, 'MM/DD/YYYY').format('YYYY-MM-DD');
    this.gql
      .mutation(profileQueries.createPersonalProfileMutation, {
        address1: this.address1,
        address2: this.address2,
        birthdate: birthdateReal,
        city: this.city,
        postalCode: this.postalCode,
        ssn: this.ssn,
        state: this.state,
      })
      .then((profileMutation: any) => {
        this.modal.close(profileMutation.data.createPersonalProfile.profile);
        this.clickedSubmit = false;
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
        this.clickedSubmit = false;
      });
  }

  public update() {
    this.clickedSubmit = true;
    this.gql
      .mutation(profileQueries.updatePersonalProfileMutation, {
        address1: this.address1,
        address2: this.address2,
        city: this.city,
        id: this.data.profile.id,
        postalCode: this.postalCode,
        state: this.state,
      })
      .then((profileMutation: any) => {
        this.modal.close(profileMutation.data.updatePersonalProfile.profile);
        this.clickedSubmit = false;
      })
      .catch((errs: any) => {
        this.toast.gqlErrors(errs);
        this.clickedSubmit = false;
      });
  }

  public createOrUpdate() {
    if (this.isEditing) {
      this.update();
    } else {
      this.create();
    }
  }
}
